import { DateTimePicker } from "@mantine/dates";
import { useTranslation } from "react-i18next";

const CustomDatetimePicker = ({ value, label, error, onChange }, props) => {
  const { t } = useTranslation();
  return (
    <DateTimePicker
      value={value}
      locale={"tr"}
      valueFormat="DD/MM/YYYY HH:mm:ss"
      preserveTime
      onChange={(value) => {
        onChange(value);
      }}
      popoverProps={{
        classNames: {
          dropdown:
            "dark:!bg-gray-800 !bg-gray-300/100 !border-none dark:!text-white !text-slate-700",
        },
      }}
      classNames={{
        weekdaysRow:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        yearsList:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        yearsListCell:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        yearsListRow:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        monthCell:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        monthsListCell:
          "dark:!bg-gray-800 !bg-gray-300/70  !border-none dark:!text-white !text-slate-700",
        monthsList:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        monthTbody:
          "dark:!bg-gray-800 !bg-gray-300/70  !border-none dark:!text-white !text-slate-700",
        monthRow:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        monthsListRow:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        section:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",

        input: `w-full dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-black !ring-1 dark:!placeholder-white/30 !placeholder-slate-700 !border-none mt-2 !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
          error && "!ring-red-500 dark:!ring-red-500"
        } `,

        wrapper:
          "w-full dark:!bg-gray-800 !bg-gray-300/100  rounded-md dark:!text-white !text-slate-700 dark:!placeholder-white/30 !placeholder-slate-700 ",
        root: "w-full ",
        label:
          "dark:!text-white !text-slate-700 !text-sm !font-medium !leading-6",
        calendarHeaderControlIcon:
          "dark:!bg-gray-800  hover:!bg-gray-100  dark:hover:!bg-gray-700 !border-none",
        day: "dark:!bg-gray-800 dark:hover:!bg-gray-700 hover:!bg-gray-100  !bg-gray-300/100  !border-none dark:!text-white !text-slate-700 ",
        description:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-none dark:!text-white !text-slate-700",
        month:
          "dark:!bg-gray-800 !bg-gray-300/100  !border-nonedark:!text-white !text-slate-700 ",

        levelsGroup: "dark:!bg-gray-800 !bg-gray-300/100  !ring-none",
        yearsListControl:
          "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 ",
        monthsListControl:
          "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
        weekday:
          "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
        monthThead:
          "dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 ",
        calendarHeaderLevel: "dark:!bg-gray-800 !bg-gray-300/100  ",
        calendarHeader:
          "dark:!bg-gray-800 !bg-gray-300/100   dark:!text-white !text-slate-700  !border-none",
        calendarHeaderControl:
          "dark:hover:!bg-gray-700  hover:!bg-gray-100    dark:!bg-gray-800 !bg-gray-300/100 ",
        timeInput: `dark:!bg-gray-800 !bg-gray-300/100  dark:!text-white !text-slate-700 !ring-1 !border-none mt-2 !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6`,
        submitButton: `dark:!bg-gray-800 !bg-gray-300/100 dark:hover:!bg-gray-700 hover:!bg-gray-100/70 dark:!text-white !text-slate-700 !ring-1 !border-none mt-2 !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6`,
      }}
      label={label}
      placeholder={t("placeholders.select")}
      {...props}
    />
  );
};

export default CustomDatetimePicker;
