import { axiosInstance } from ".";

export const showCaseService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `showcase/template?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`showcase/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("showcase/template", data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(
        `showcase/template/delete/${id}`
      );
      return response?.data || [];
    },
    async productTransaction(id, body) {
      const response = await axiosInstance.put(`showcase/product/${id}`, body);
      return response?.data || [];
    },
    async spiralTransaction(id, rotation, type, index) {
      const response = await axiosInstance.post(
        `showcase/spiral/${type}/${id}`,
        {
          index: index,
          rotation: rotation,
        }
      );
      return response?.data || [];
    },
  };
};
