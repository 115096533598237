const CampaignTypes = {
  SALE_SPOT: "SALE_SPOT",
  SALE_AREA: "SALE_AREA",
  MAIN_SALE_AREA: "MAIN_SALE_AREA",
  USER: "USER",
};

const EndTypes = {
  DATE: "DATE",
  COUNT: "COUNT",
};

const PriceTypes = {
  PERCENT: "PERCENT",
  PRICE: "PRICE",
};

export { CampaignTypes, EndTypes, PriceTypes };
