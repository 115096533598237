import { useTranslation } from "react-i18next";
import { NumberInput, PasswordInput } from "@mantine/core";
const CustomInput = ({ label, register, ...rest }) => {
  const { t } = useTranslation();
  if (rest.type === "password")
    return (
      <div>
        <label
          htmlFor={rest.name}
          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
        >
          {label}
        </label>
        <div className="mt-2">
          <PasswordInput
            {...register(rest.name)}
            id={rest.name}
            name={rest.name}
            classNames={{
              input:
                "dark:!bg-gray-800 !bg-gray-300/100  !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
              wrapper: "!bg-gray-800 rounded-md !text-white",
              dropdown:
                "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
              option: "hover:bg-gray-700",
            }}
            autoComplete={rest.name}
            {...rest}
          />
          {rest.errors[rest.name] && (
            <p className="mt-2 text-sm text-red-600">
              {t(rest.errors[rest.name].message) ||
                rest.errors[rest.name].message}
            </p>
          )}
        </div>
      </div>
    );

  if (rest.type === "number") {
    return (
      <div>
        <label
          htmlFor={rest.name}
          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
        >
          {label}
        </label>
        <div className="mt-2">
          <NumberInput
            classNames={{
              input: `dark:!bg-gray-800  !bg-gray-300/100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full
              ${
                rest.errors[rest.name]?.message &&
                "!ring-red-500 dark:!ring-red-500"
              }
              `,
              wrapper:
                "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
              dropdown:
                "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
              option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
            }}
            autoComplete={rest.name}
            {...rest}
          />
          {rest.errors[rest.name] && (
            <p className="mt-2 text-sm text-red-600">
              {t(rest.errors[rest.name].message) ||
                rest.errors[rest.name].message}
            </p>
          )}
        </div>
      </div>
    );
  }
  return (
    <div>
      <label
        htmlFor={rest.name}
        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
      >
        {label}
      </label>
      <div className="mt-2">
        <input
          id="email"
          name={rest.name}
          type={rest.type}
          {...register(rest.name)}
          autoComplete={rest.name}
          className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
            rest.errors[rest.name]?.message && "!ring-red-500 dark:ring-red-500"
          }`}
          {...rest}
        />
        {rest.errors[rest.name] && (
          <p className="mt-2 text-sm text-red-600">
            {t(rest.errors[rest.name].message) ||
              rest.errors[rest.name].message}
          </p>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
