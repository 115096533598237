import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../../components/Loading/BarLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryCreateAndUpdate } from "../validations";
import { Switch } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import SaveButton from "../../../components/Buttons/SaveButton";
import CustomInput from "../../../components/CustomInput/CustomInput";

const ProductCategoryCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(CategoryCreateAndUpdate),
  });

  const addProductCategory = useMutation({
    mutationFn: (newProductCategory) =>
      services.productCategoryCreate(newProductCategory),
  });
  const updateProductCategory = useMutation({
    mutationFn: (newProductCategory) =>
      services.productCategoryUpdate(id, newProductCategory),
  });

  const onSubmit = async (data) => {
    let newProductCategoryPayload;
    let productCategory;

    try {
      newProductCategoryPayload = {
        ...data,
      };
      if (!id) {
        productCategory = await addProductCategory.mutateAsync(
          newProductCategoryPayload
        );
      } else {
        productCategory = await updateProductCategory.mutateAsync(
          newProductCategoryPayload
        );
      }

      if (!id && productCategory) {
        toast.success(
          productCategory?.data?.message
            ? productCategory?.data?.message
            : t("notifications.success.product-category-create"),
          {
            duration: 3000,
          }
        );

        reset();
        handleCancel();
      } else if (id && productCategory) {
        toast.success(
          productCategory?.data?.message
            ? productCategory?.data?.message
            : t("notifications.success.product-category-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(
        productCategory?.data?.message
          ? productCategory?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/product/category/list");
  };

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });

  const fetchProductCategory = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const productCategory = await services.getProductCategoryById(id);
        Object.keys(productCategory.data).forEach((key) => {
          setValue(key, productCategory.data[key]);
        });
      } catch (e) {
        navigate("/product/category/list");
        toast.error(t("notifications.error.product-category-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    if (id) {
      fetchProductCategory();
    }
  }, [id]);
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id
                    ? t("titles.product-category-edit")
                    : t("titles.product-category-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.product-category-edit")
                    : t("descriptions.product-category-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <CustomInput
                      label={t("product.category-name")}
                      register={register}
                      name="name"
                      errors={errors}
                      placeholder={t("product.category-name")}
                    />
                  </div>
                </div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="lg"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.is_active?.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={
                  id
                    ? updateProductCategory.isLoading
                    : addProductCategory.isLoading
                }
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default ProductCategoryCreate;
