import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useParams } from "react-router-dom";
import services from "../../services";
import ShowcaseTransactions from "../Showcase/ShowcaseTransactions";
import { useTranslation } from "react-i18next";
import { Button } from "@mantine/core";

const SaleSpotShowcaseList = () => {
  const { sale_spot_id } = useParams();
  const { t } = useTranslation();

  const {
    isLoading: isLoading,
    error: error,
    data: data,
    isSuccess: success,
    refetch: refetch,
  } = useQuery({
    queryKey: ["sale_spot_detail", sale_spot_id],
    queryFn: async () =>
      await services.getSaleSpotById(sale_spot_id).then((res) => res.data),
  });

  return (
    <div>
      {!isLoading && (
        <div className="py-1 flex flex-row items-center px-2 rounded-sm text-sm bg-orange-300 -mt-8 mb-4">
          <span className="text-black">
            {t("salespot.address-desc")}: {data.location_description}
          </span>

          <Button
            onClick={() => {
              window.open(
                `http://maps.google.com.tr/maps?q=${data.location.lat},${data.location.lng}`
              );
            }}
            size="xs"
            className="ml-auto"
          >
            {t("actions.go-to-location")}
          </Button>
        </div>
      )}
      {!isLoading &&
        success &&
        data.showcases.map((showcase, index) => {
          return (
            <ShowcaseTransactions key={index} outsourceId={showcase._id} />
          );
        })}
    </div>
  );
};

export default SaleSpotShowcaseList;
