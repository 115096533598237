import { axiosInstance } from ".";

export const reportService = (state) => {
  return {
    async report(start_date, end_date) {
      const paramsMap =
        start_date && end_date
          ? `?start_date=${start_date}&end_date=${end_date}`
          : "";
      const response = await axiosInstance.get(`report/product${paramsMap}`);
      return response?.data || [];
    },
  };
};
