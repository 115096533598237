import { axiosInstance } from ".";

export const saleSpotService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `sale-spot/list?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }${search ? `&is_for_select=${false}` : `&is_for_select=${false}`} `
      );
      return response?.data || [];
    },
    async getSaleSpotsByIds(main_sale_area_id, sale_area_id) {
      const response = await axiosInstance.get(
        `sale-spot/management-data/${main_sale_area_id}/${sale_area_id}`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`sale-spot/detail/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("sale-spot", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.put(`sale-spot/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(`sale-spot/delete/${id}`);
      return response?.data || [];
    },

    async categoryList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `sale-spot/category/list?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },

    async categoryCreate(data) {
      const response = await axiosInstance.post("sale-spot/category", data);
      return response?.data || [];
    },
    async categoryUpdate(id, data) {
      const response = await axiosInstance.put(
        `sale-spot/category/${id}`,
        data
      );
      return response?.data || [];
    },
    async categoryGet(id) {
      const response = await axiosInstance.get(`sale-spot/category/${id}`);
      return response?.data || [];
    },
    async categoryDelete(id) {
      const response = await axiosInstance.get(
        `sale-spot/category/delete/${id}`
      );
      return response?.data || [];
    },
  };
};
