import { Button, Input, Modal, NumberInput } from "@mantine/core";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomSelect from "../CustomSelect/CustomSelect";
import BarLoading from "../Loading/BarLoading";
import CustomDatetimePicker from "../CustomDatetimePicker/CustomDatetimePicker";

const AddStorageStockModal = ({ isOpen, onClose, onSend, title, text, id }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      product_in_storage: null,
    },
  });

  const addStorageStock = useMutation({
    mutationFn: (newStorageStock) =>
      services.addStockToStorage(newStorageStock),
  });

  const {
    isLoading,
    error,
    data: productData,
    isFetching,
  } = useQuery({
    queryKey: ["products"],
    queryFn: async () =>
      await services.storageProductList(id, 1, 400).then((res) => {
        const data = res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        }));
        return data;
      }),
    enabled: isOpen,
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data, retry = false) => {
    let newStorageStockPayload;
    let storageStock;
    try {
      newStorageStockPayload = {
        ...data,
      };
      storageStock = await addStorageStock.mutateAsync(newStorageStockPayload);

      if (storageStock) {
        toast.success(
          storageStock?.data?.message
            ? storageStock?.data?.message
            : t("notifications.success.storage-stock-transaction-added"),
          {
            duration: 3000,
          }
        );
        if (!retry) {
          onSend(data);
          reset();
        }
        reset();
      }
    } catch (e) {
      toast.error(
        e.response?.data?.message
          ? e.response?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const handleRetryAdd = async () => {
    await onSubmit(getValues(), true);
  };

  const expirationDate = useWatch({
    control,
    name: "expiration_date",
    defaultValue: "",
  });

  const inDate = useWatch({
    control,
    name: "in_date",
    defaultValue: "",
  });

  const amount = useWatch({
    control,
    name: "amount",
    defaultValue: 0,
  });

  const product = useWatch({
    control,
    name: "product_in_storage",
    defaultValue: null,
  });
  const type = useWatch({
    control,
    name: "type",
    defaultValue: null,
  });

  const lotNo = useWatch({
    control,
    name: "lot_no",
    defaultValue: "",
  });

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      centered
      classNames={{
        root: "dark:!bg-slate-700 !bg-gray-200",
        header: "dark:!bg-slate-700 !bg-gray-200",
        content: "!rounded-xl dark:!bg-slate-700 !bg-gray-200",
        title: "!text-md !font-semibold ",
        close: "dark:!text-white dark:!bg-gray-800 dark:hover:!bg-gray-800/70",
      }}
      title={title || "Add Storage Product"}
      overlayProps={{
        backgroundOpacity: 0.45,
        blur: 2,
        color: "#1E44FF",
      }}
    >
      <div>
        {isLoading ? (
          <BarLoading />
        ) : (
          <div>
            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
              <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                <div className="sm:col-span-full">
                  <div className="mt-2 mb-2">
                    <label className="mt-2 text-sm" htmlFor="name">
                      {t("labels.product")}
                    </label>
                    <CustomSelect
                      control={control}
                      name="product_in_storage"
                      data={productData}
                      value={product}
                      placeholder={t("placeholders.select")}
                    />

                    {errors.product && (
                      <span className="text-xs text-red-500">
                        {t("form-validations.required")}
                      </span>
                    )}
                  </div>
                  <div className="sm:col-span-3  justify-center items-start flex flex-col">
                    <CustomDatetimePicker
                      value={inDate}
                      locale={"tr"}
                      valueFormat="DD/MM/YYYY HH:mm:ss"
                      onChange={(value) => {
                        setValue("in_date", value);
                      }}
                      label={t("storage.in-date")}
                      placeholder={t("placeholders.select")}
                    />
                    <p className="mt-2 ml-1 text-sm text-red-600">
                      {t(errors.in_date?.message)}
                    </p>
                  </div>
                  <div className="sm:col-span-3  justify-center items-start flex flex-col">
                    <CustomDatetimePicker
                      value={expirationDate}
                      locale={"tr"}
                      valueFormat="DD/MM/YYYY HH:mm:ss"
                      preserveTime
                      onChange={(value) => {
                        setValue("expiration_date", value);
                      }}
                      label={t("storage.expiration-date")}
                      placeholder={t("placeholders.select")}
                    />
                    <p className="mt-2 ml-1 text-sm text-red-600">
                      {t(errors.expration_date?.message)}
                    </p>
                  </div>
                  <div className="sm:col-span-3  justify-center items-start flex flex-col">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.type")}
                    </label>
                    <div className="mt-2 w-full">
                      <CustomSelect
                        control={control}
                        name="type"
                        placeholder={t("placeholders.select")}
                        value={type}
                        data={[
                          { value: "IN", label: t("labels.in") },
                          { value: "OUT", label: t("labels.out") },
                        ]}
                      />
                    </div>
                    <p className="mt-2 ml-1 text-sm text-red-600">
                      {t(errors.type?.message)}
                    </p>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.lot-no")}
                    </label>
                    <div className="mt-2">
                      <Input
                        value={lotNo}
                        onChange={(event) => {
                          setValue("lot_no", event.target.value);
                        }}
                        placeholder={t("storage.lot-no")}
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300/70   !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 dark:!placeholder-white/30 !placeholder-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.lot_no?.message &&
                            "!ring-red-500 dark:!ring-red-500"
                          }}`,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option:
                            "hover:dark:bg-gray-700 hover:bg-gray-200/90/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.lot_no?.message}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.amount")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={amount}
                        onChange={(event) => {
                          setValue("amount", event);
                        }}
                        placeholder={t("storage.amount")}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300/70   !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.amount?.message &&
                            "!ring-red-500 dark:!ring-red-500"
                          }}`,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option:
                            "hover:dark:bg-gray-700 hover:bg-gray-200/90/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.amount?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-end mt-2 gap-2">
                <Button
                  disabled={
                    !amount || !product || !expirationDate || !inDate || !lotNo
                      ? true
                      : false
                  }
                  variant="filled"
                  color="#1E44FF"
                  onClick={() => handleRetryAdd()}
                  type="button"
                  radius={"md"}
                >
                  {t("actions.confirm-and-retry")}
                </Button>
                <Button
                  disabled={
                    !amount || !product || !expirationDate || !inDate || !lotNo
                      ? true
                      : false
                  }
                  variant="filled"
                  color="#1E44FF"
                  type="submit"
                  radius={"md"}
                >
                  {t("actions.confirm")}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddStorageStockModal;
