import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../services";
import { Badge, Pagination } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import Layout from "../../components/Layout/Layout";
import dayjs from "dayjs";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const DeviceActionList = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchBrand,
  } = useQuery({
    queryKey: ["device-logs", currentPage, pageSize, search],
    queryFn: async () =>
      await services
        .getDeviceActionLogs(currentPage, pageSize, searchParams?.get('q') || search)
        .then((res) => res.data),
  });
  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: , pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);
  if (error) return <p>Error :(</p>;

  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data.data.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };
  const handleDelete = async (id) => {
    await services.productBrandDelete(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message
            ? res.message
            : t("notifications.success.product-brand-delete")
        );

        setDeleteModalVisible(false);
        refetchBrand();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };
  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };

  const returnActionMessageKeyAndColor = (actionMessage) => {
    if (actionMessage.includes("WORKING")) {
      return {
        color: "green",
        key: "WORKING",
      };
    } else if(actionMessage.includes('DROP_ERROR')) {
      return {
        color: "red",
        key: "DROP_ERROR",
      };
    } if (actionMessage.includes("DEACTIVE")) {
      return {
        color: "red",
        key: "DEACTIVE",
      };
    } else if (actionMessage.includes("ERROR")) {
      return {
        color: "red",
        key: "ERROR",
      };
    } else {
      const findedError = ['POWER_FAILURE','SOFTWARE_RESET','WATCHDOG_RESET','DROP_ERROR','MOTOR_'].find((d) => {
        if(actionMessage.includes(d)) {
           return d;
          }
      })

      if(findedError === 'POWER_FAILURE' || findedError === 'SOFTWARE_RESET' || findedError === 'WATCHDOG_RESET') {
        return {
            color: "orange",
            key: 'POWER FAILURE',
        }
      }

      if(findedError === 'MOTOR_') {
        return {
            color: "blue",
            key: 'SPIRAL '+ actionMessage.replace('Device transaction changed to ',' ').replace('MOTOR_' ,' ').replace('!','')+' LOCKED',
        }
      }
        return {
            color: "blue",
            key: findedError,
        }
    }

  }

  return (
    <Layout>
      <div className="px-4 sm:px-6 lg:px-8">
        <ConfirmModal
          isOpen={deleteModalVisible}
          onConfirm={() => handleDelete(id)}
          onClose={() => {
            setDeleteModalVisible(false);
            setId(null);
          }}
          title={t("titles.delete-brand")}
          confirmText={t("descriptions.delete-brand")}
        />
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
              {t("titles.device-action-logs")}
            </h1>
            <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
              {t("descriptions.device-action-logs")}
            </p>
          </div>

          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none"></div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                      >
                        {t("device.name")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                      >
                        {t("device.action-key")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                      >
                        {t("device.action-status")}
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                      >
                        {t("device.action-date")}
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                    {isLoading || isFetching ? (
                      <TableSkeleton colSize={4} />
                    ) : (
                      data.data.map((actionLog, index) => (
                        <tr
                          key={`${actionLog.name}-${index.toString()}`}
                          className={`${
                            index % 2 === 0
                              ? "dark:bg-slate-700/80 bg-gray-200"
                              : "dark:bg-slate-600/80 bg-gray-100"
                          } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                        >
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                            {actionLog?.device_id?.name || "BROKEN_OUT_DATA"}
                          </td>

                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                            {actionLog.action_message}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                            {actionLog.action_key === 'DEVICE_TRANSACTION_CHANGE' && <Badge
                                color={returnActionMessageKeyAndColor(actionLog.action_message).color}
                            >
                              {returnActionMessageKeyAndColor(actionLog.action_message).key}
                            </Badge>}
                          </td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                            {dayjs(
                              actionLog.created_at
                            ).format("DD-MM-YYYY HH:mm")}
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
            <div className="flex justify-center w-full items-center">
              <Pagination
                total={data?.totalPageCount}
                siblings={1}
                size={"md"}
                defaultValue={
                  Number(searchParams.get("page"))
                    ? Number(searchParams.get("page"))
                    : 1
                }
                value={currentPage}
                onChange={handlePageChange}
                color="orange"
                classNames={{
                  control:
                    "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
                }}
              />
            </div>
            <div className=" flex justify-end w-[80px]">
              <CustomSelect
                withoutController={true}
                placeholder={t("placeholders.select")}
                onChange={(value) => {
                  setPageSize(value);
                  setSearchParams({ page: 1, pageSize: value || 10 });
                  setCurrentPage(1);
                }}
                data={[5, 10, 20, 30, 50]}
                value={pageSize}
                name="pageSize"
                searchable={false}
                height={"20px"}
                width={20}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default DeviceActionList;
