import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../services";
import { useQuery } from "@tanstack/react-query";
import { Badge, Button, Card, Group, Text } from "@mantine/core";

const SaleSpotCard = ({ item, task_id, ...props }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      shadow="sm"
      padding="md"
      w={"100%"}
      radius="md"
      withBorder
      classNames={{
        root: `${
          props.checked ? "!ring-2  !ring-orange-500 dark:!ring-orange-700" : ""
        }  dark:!bg-gray-800 !bg-gray-300/30 dark:!ring-gray-700  dark:!text-white !w-full !border-none`,
      }}
    >
      <Group justify="space-between" h="100%">
        <div className="h-full flex flex-wrap flex-row gap-3 justify-between">
          <Badge color="orange" radius={"xs"}>
            <Text fw={600} size="xs">
              ID: {item._id}
            </Text>
          </Badge>
          <Badge color="purple">
            <Text fw={500} size="xs">
              VA: {item.showcases?.length || 0}
            </Text>
          </Badge>
        </div>

        <Text size="xs" c="dimmed" fw={600}>
          {item.location_description}
        </Text>
      </Group>
      <div className="flex flex-wrap gap-2 mt-4">
        {props.products.map((product, index) => (
          <Badge key={index} color="blue" size="xs">
            {product.name}:{product.quantity + " "}
            {t("labels.amount")}
          </Badge>
        ))}
      </div>
      <Group className="mt-0 xs:mt-2">
        <Button
          onClick={() => {
            navigate(`${item._id}`);
          }}
          size="xs"
          className="ml-auto"
          color={"blue"}
        >
          {t("actions.transactions-one")}
        </Button>
        <Button
          onClick={() => {
            window.open(
              `http://maps.google.com.tr/maps?q=${item.location.lat},${item.location.lng}`
            );
          }}
          size="xs"
          className="w-full xs:w-full sm:w-full"
          color={"blue"}
        >
          {t("actions.go-to-location")}
        </Button>
      </Group>
    </Card>
  );
};
const DeliveryTaskDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isLoading,
    data,
    refetch: refetchDetail,
    isFetching,
  } = useQuery({
    queryKey: ["task", id],
    enabled: !!id,
    queryFn: async () => {
      return await services.getDeliveryTaskById(id).then((res) => res.data);
    },
  });
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-1 gap-4 flex-col">
        {!isLoading &&
          data?.order.sale_spots &&
          data?.order.sale_spots.map((saleSpots, index) => (
            <SaleSpotCard
              key={saleSpots.id}
              products={saleSpots.required_products}
              item={saleSpots.sale_spot}
              task_id={id}
            />
          ))}
      </div>
    </div>
  );
};

export default DeliveryTaskDetail;
