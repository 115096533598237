import { axiosInstance } from ".";

export const storageService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `storage?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }${search ? `&is_for_select=${true}` : ""}`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`storage/detail/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("storage", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.post(`storage/update/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(`storage/delete/${id}`);
      return response?.data || [];
    },
    async verifyStockTransaction(id) {
      const response = await axiosInstance.get(
        `storage/verify-product-stock-transactions/${id}`
      );
      return response?.data || [];
    },
    async getStorageProductDetail(id) {
      const response = await axiosInstance.get(`storage/product-detail/${id}`);

      return response?.data || [];
    },
    async getProductInStorageStockTransactions(id, page, pageSize, search) {
      const response = await axiosInstance.get(
        `storage/product-stock-transactions/${id}?page=${page}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );

      return response?.data || {};
    },
    async addStockToProduct({
      product,
      in_date,
      expiration_date,
      type,
      lot_no,
      amount,
    }) {
      const response = await axiosInstance.post(
        `storage/product-stock-transaction`,
        {
          product_in_storage: product,
          in_date,
          expiration_date,
          type,
          lot_no,
          amount,
        }
      );

      return response?.data || {};
    },
    async getProductCurrentStock(id) {
      const response = await axiosInstance.get(
        `storage/product-current-stock/${id}`
      );

      return response?.data || {};
    },
    async updateProductToStorage({
      product,
      storage,
      min_stock,
      max_stock,
      is_active,
    }) {
      const response = await axiosInstance.put(`storage/update-product`, {
        product,
        storage,
        min_stock,
        max_stock,
        is_active,
      });

      return response?.data || [];
    },
    async addProductToStorage({
      product,
      storage,
      min_stock,
      max_stock,
      is_active,
    }) {
      const response = await axiosInstance.post(`storage/add-product`, {
        product,
        storage,
        min_stock,
        max_stock,
        is_active,
      });

      return response?.data || [];
    },
    async addStockToStorage(data) {
      const response = await axiosInstance.post(
        `storage/product-stock-transaction`,
        data
      );

      return response?.data || [];
    },
    async storageProducts(id, pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `storage/products/${id}?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async categoryList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `storage/category?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },

    async categoryCreate(data) {
      const response = await axiosInstance.post("storage/category", data);
      return response?.data || [];
    },
    async categoryUpdate(id, data) {
      const response = await axiosInstance.put(`storage/category/${id}`, data);
      return response?.data || [];
    },
    async categoryGet(id) {
      const response = await axiosInstance.get(`storage/category/${id}`);
      return response?.data || [];
    },
    async categoryDelete(id) {
      const response = await axiosInstance.get(`storage/category/delete/${id}`);
      return response?.data || [];
    },
  };
};
