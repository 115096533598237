import { axiosInstance } from ".";

export const authService = (state) => {
  return {
    async login(payload) {
      const response = await axiosInstance.post("auth/login", payload);
      return response?.data || [];
    },
    async me(token) {
      const response = await axiosInstance.get("auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response?.data || [];
    },
  };
};
