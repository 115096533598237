import { axiosInstance } from ".";

export const deliveryService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `delivery?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async assignToUser(data) {
      const response = await axiosInstance.post(
        `delivery/assign-to-user`,
        data
      );
      return response?.data || [];
    },
    async getTasks() {
      const response = await axiosInstance.get(`delivery/task`);
      return response?.data || [];
    },
    async getTaskById(id) {
      const response = await axiosInstance.get(`delivery/task/${id}`);
      return response?.data || [];
    },
    async getTaskSaleSpotById(task_id, id) {
      const response = await axiosInstance.get(
        `delivery/task/${task_id}/${id}`
      );
      return response?.data || [];
    },
    async getTaskShowCaseById(task_id, sale_spot_id, id) {
      const response = await axiosInstance.get(
        `delivery/task/${task_id}/${sale_spot_id}/${id}`
      );
      return response?.data || [];
    },
    async updateTaskSpiralElement(task_id, sale_spot_id, id, data) {
      const response = await axiosInstance.post(
        `delivery/task/${task_id}/${sale_spot_id}/${id}`,
        data
      );
      return response?.data || [];
    },
  };
};
