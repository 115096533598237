import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import { NumberInput, rem } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { Switch } from "@mantine/core";
import SaveButton from "../../components/Buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { ShowCaseCreateValidations } from "./validations";
import CustomInput from "../../components/CustomInput/CustomInput";

const ShowCaseCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(ShowCaseCreateValidations),
  });

  const addShowcase = useMutation({
    mutationFn: (newShowcase) => services.createShowCase(newShowcase),
  });

  const onSubmit = async (data) => {
    let newShowcasePayload;
    try {
      newShowcasePayload = {
        ...data,
      };

      await addShowcase.mutateAsync(newShowcasePayload);

      toast.success(t("notifications.success.showCase-create"), {
        duration: 3000,
      });
      handleCancel();
    } catch (e) {
      toast.error(t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/showcase/list");
  };

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });
  const verticalSpiralCount = useWatch({
    control,
    name: "vertical_spiral_count",
    defaultValue: null,
  });
  const horizontalSpiralCount = useWatch({
    control,
    name: "horizontal_spiral_count",
    defaultValue: null,
  });

  useEffect(() => {
    reset();
  }, []);
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {t("titles.show-case-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {t("descriptions.show-case-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <CustomInput
                      register={register}
                      name="name"
                      placeholder={t("show-case.name")}
                      errors={errors}
                      label={t("show-case.name")}
                    />
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="horizontal_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("show-case.horizontal-slot-count")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={horizontalSpiralCount}
                        placeholder={t("show-case.horizontal-slot-count")}
                        onChange={(event) => {
                          setValue("horizontal_spiral_count", event);
                        }}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white placeholder:!text-gray-500 !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.horizontal_spiral_count?.message
                              ? "!ring-red-500 dark:!ring-red-500"
                              : ""
                          }}`,
                          wrapper: "!bg-gray-800 rounded-md !text-white",
                          dropdown:
                            "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:bg-gray-700",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.horizontal_spiral_count?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("show-case.vertical-slot-count")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={verticalSpiralCount}
                        onChange={(event) => {
                          setValue("vertical_spiral_count", event);
                        }}
                        placeholder={t("show-case.vertical-slot-count")}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white placeholder:!text-gray-500 !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.vertical_spiral_count?.message
                              ? "!ring-red-500 dark:!ring-red-500"
                              : ""
                          }}`,
                          wrapper: "!bg-gray-800 rounded-md !text-white",
                          dropdown:
                            "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:bg-gray-700",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.vertical_spiral_count?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("show-case.total-slot-count")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={verticalSpiralCount * horizontalSpiralCount}
                        disabled
                        onChange={(event) => {
                          setValue("vertical_spiral_count", event);
                        }}
                        placeholder={0}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800 !bg-gray-300 !opacity-100  !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white placeholder:!text-gray-500 !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                            errors.vertical_spiral_count?.message
                              ? "!ring-red-500 dark:!ring-red-500"
                              : ""
                          }}`,
                          wrapper: "!bg-gray-800 rounded-md !text-white",
                          dropdown:
                            "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:bg-gray-700",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.vertical_spiral_count?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="is-active"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event?.target?.checked)
                        }
                        color="teal"
                        size="lg"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.is_active?.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton disabled={addShowcase.isLoading} />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default ShowCaseCreate;
