import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";

import { Button } from "@mantine/core";
import services from "../../services";

import { useState } from "react";

import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import FilterPopover from "../../components/FilterPopover/FilterPopover";
import { useForm, useWatch } from "react-hook-form";
import dayjs from "dayjs";
import { IconFileSpreadsheet } from "@tabler/icons-react";
import { exportToExcel } from "react-json-to-excel";

const Userlist = () => {
  const { t } = useTranslation();
  const [openedPopover, setOpenedPopover] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const start_date = useWatch({
    control,
    name: "start_date",
    defaultValue: dayjs()
      .month(dayjs().month() - 2)
      .startOf("month")
      .toDate(),
  });

  const end_date = useWatch({
    control,
    name: "end_date",
    defaultValue: dayjs().endOf("month").toDate(),
  });
  const onSubmitFilter = () => {
    refetchData();
    setOpenedPopover(false);
  };

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchData,
  } = useQuery({
    queryKey: ["getProductReport"],

    queryFn: async () =>
      await services
        .getReport(
          dayjs(start_date).format("DD-MM-YYYY"),
          dayjs(end_date).format("DD-MM-YYYY")
        )
        .then((res) => res.data),
  });
  const dataMap = data?.data?.map((item) => {
    return {
      [t("product-report.product-service")]: item.product_name,
      [t("product-report.amount")]: item.count,
      [t("product-report.unit-price")]: item.single_price,
      [t("product-report.vat-rate")]: item.kdv,
      [t("product-report.vat-amount")]: item.kdv_amount,
      [t("product-report.product-service-amount")]: item.total_price,
    };
  });

  if (error) return <p>Error :(</p>;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            {t("titles.productreport")}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.product-report")}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-center"></div>
      </div>
      {!isLoading && (
        <div className="mt-8 flex justify-end items-center">
          <div className="flex  items-center gap-2">
            <Button
              type="button"
              size="xs"
              radius={"md"}
              className="gap-2 mt-4"
              color={"gray"}
              onClick={() => exportToExcel(dataMap, "product-report")}
            >
              <div className="flex items-center justify-start gap-2">
                <IconFileSpreadsheet />
                <span>{t("actions.download-excel")}</span>
              </div>
            </Button>
            <FilterPopover
              openedPopover={openedPopover}
              setOpenedPopover={setOpenedPopover}
              onSubmit={handleSubmit((data) =>
                onSubmitFilter(data, getValues("photo"))
              )}
              control={control}
              register={register}
              setValue={setValue}
              start_date={start_date}
              end_date={end_date}
              errors={errors}
            />
          </div>
        </div>
      )}
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 w-[150px] text-slate-700 sm:pl-6"
                    >
                      {t("product-report.product-service")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.amount")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.unit-price")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.vat-rate")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.vat-amount")}
                    </th>{" "}
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("product-report.product-service-amount")}
                    </th>
                    <th
                      scope="col"
                      className="flex py-3.5 pl-3 pr-4 sm:pr-6"
                    ></th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                  {isLoading || isFetching ? (
                    <TableSkeleton colSize={7} />
                  ) : (
                    data.data.map((company, index) => (
                      <tr
                        key={`${company.name}-${index.toString()}`}
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 w-[100px] text-slate-700 sm:pl-4">
                          {company.product_name}
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {company.count}
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {company.single_price}
                        </td>
                        <td className="whitespace-nowrap px-5 py-4 text-sm dark:text-gray-300 text-gray-500">
                          {company.kdv}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {company.kdv_amount}
                        </td>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {company.total_price}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 "></td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
