export const amountFormatter = (amount) => {
  return Number(amount)
    .toLocaleString("tr-TR", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    .toString();
};
export const dateCombineDateObject = (day, month, year) => {
  return new Date(`${year}-${month}-${day}`);
};
