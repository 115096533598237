import i18n from "../i18n/i18n";
import { authService } from "./authService";
import { companyService } from "./companyService";
import { countryService } from "./countryService";
import { userService } from "./userService";
import { roleService } from "./roleService";
import { productService } from "./productService";
import axios from "axios";
import { fileService } from "./fileService";
import { config } from "../config";
import { showCaseService } from "./showCaseService";
import { storageService } from "./storageService";
import { salesAreaService } from "./salesAreaService";
import { saleSpotService } from "./saleSpotService";
import { orderService } from "./orderService";
import { deliveryService } from "./deliveryService";
import { dashboardService } from "./dashboardService";
import { campaignService } from "./campaignService";
import { reportService } from "./reportService";

export const axiosInstance = axios.create({
  baseURL: config.API_URL,
});

axiosInstance.interceptors.request.use((config) => {
  const store = JSON.parse(localStorage.getItem("AuthStore"));
  const lang = i18n.language;

  if (store.access_token) {
    config.headers["Authorization"] = `Bearer ${store["access_token"]}`;
    config.headers["Accept-Language"] = lang;
  }
  return config;
});

export default {
  state: {
    config: {},
    baseUrl: "/api/v1",
  },
  async login(data) {
    return await authService(this.state).login(data);
  },
  async me(token) {
    return await authService(this.state).me(token);
  },
  async getUserById(id) {
    return await userService(this.state).get(id);
  },
  async users(pageNo, pageSize, search) {
    return await userService(this.state).list(pageNo, pageSize, search);
  },
  async getPublicUserList(pageNo, pageSize, search) {
    return await userService(this.state).publicUserList(
      pageNo,
      pageSize,
      search
    );
  },
  async getReport(start_date, end_date) {
    return await reportService(this.state).report(start_date, end_date);
  },

  async defineGiftProduct(data) {
    return await userService(this.state).defineGiftProduct(data);
  },
  async createUser(data) {
    return await userService(this.state).create(data);
  },
  async updateUser(id, data) {
    return await userService(this.state).update(id, data);
  },
  async deleteUser(id) {
    return await userService(this.state).delete(id);
  },
  async compaines(pageNo, pageSize, search) {
    return await companyService(this.state).list(pageNo, pageSize, search);
  },
  async getCompanyById(id) {
    return await companyService(this.state).get(id);
  },
  async createCompany(data) {
    return await companyService(this.state).create(data);
  },
  async updateCompany(id, data) {
    return await companyService(this.state).update(id, data);
  },
  async deleteCompany(id) {
    return await companyService(this.state).delete(id);
  },
  async phoneCodes() {
    return await countryService(this.state).phoneCodes();
  },
  async countries() {
    return await countryService(this.state).list();
  },
  async roles() {
    return await roleService(this.state).list();
  },
  async fileUpload(file) {
    return await fileService(this.state).upload(file);
  },
  async products(pageNo, pageSize, search, is_for_select) {
    return await productService(this.state).list(
      pageNo,
      pageSize,
      search,
      is_for_select
    );
  },
  async getProductById(id) {
    return await productService(this.state).get(id);
  },
  async createProduct(data) {
    return await productService(this.state).create(data);
  },
  async updateProduct(id, data) {
    return await productService(this.state).update(id, data);
  },
  async deleteProduct(id) {
    return await productService(this.state).delete(id);
  },
  async productCategoryList(pageNo, pageSize, search) {
    return await productService(this.state).categoryList(
      pageNo,
      pageSize,
      search
    );
  },
  async productSubCategoryListByCategoryId(id) {
    return await productService(this.state).subCategoryListByCategoryId(id);
  },
  async productCategoryCreate(data) {
    return await productService(this.state).categoryCreate(data);
  },
  async productCategoryUpdate(id, data) {
    return await productService(this.state).categoryUpdate(id, data);
  },
  async deleteProductCategory(id) {
    return await productService(this.state).categoryDelete(id);
  },
  async getProductCategoryById(id) {
    return await productService(this.state).categoryGet(id);
  },
  async productCategoryDelete(id) {
    return await productService(this.state).categoryDelete(id);
  },
  async productSubCategoryList(pageNo, pageSize, search) {
    return await productService(this.state).subCategoryList(
      pageNo,
      pageSize,
      search
    );
  },
  async productSubCategoryCreate(data) {
    return await productService(this.state).subCategoryCreate(data);
  },
  async productSubCategoryUpdate(id, data) {
    return await productService(this.state).subCategoryUpdate(id, data);
  },
  async getProductSubCategoryById(id) {
    return await productService(this.state).subCategoryGet(id);
  },
  async productSubCategoryDelete(id) {
    return await productService(this.state).subCategoryDelete(id);
  },
  async productBrandList(pageNo, pageSize, search) {
    return await productService(this.state).brandList(pageNo, pageSize, search);
  },
  async createProductBrand(data) {
    return await productService(this.state).brandCreate(data);
  },
  async updateProductBrand(id, data) {
    return await productService(this.state).brandUpdate(id, data);
  },
  async getProductBrandById(id) {
    return await productService(this.state).brandGet(id);
  },
  async productBrandDelete(id) {
    return await productService(this.state).brandDelete(id);
  },
  async productCardTypeList(pageNo, pageSize, search) {
    return await productService(this.state).cardTypeList(
      pageNo,
      pageSize,
      search
    );
  },
  async createProductCardType(data) {
    return await productService(this.state).cardTypeCreate(data);
  },
  async updateProductCardType(id, data) {
    return await productService(this.state).cardTypeUpdate(id, data);
  },
  async productCardTypeDelete(id) {
    return await productService(this.state).cardTypeDelete(id);
  },
  async getProductCardTypeById(id) {
    return await productService(this.state).cardTypeGet(id);
  },
  async mainSaleAreaSelectList() {
    return await salesAreaService(this.state).mainSaleAreaSelectList();
  },
  async mainSalesAreaList(pageNo, pageSize, search) {
    return await salesAreaService(this.state).mainSalesAreaList(
      pageNo,
      pageSize,
      search
    );
  },
  async createMainSalesArea(data) {
    return await salesAreaService(this.state).mainSalesAreaCreate(data);
  },
  async updateMainSalesArea(id, data) {
    return await salesAreaService(this.state).mainSalesAreaUpdate(id, data);
  },
  async mainSalesAreaDelete(id) {
    return await salesAreaService(this.state).mainSalesAreaDelete(id);
  },
  async getMainSaleAreaById(id) {
    return await salesAreaService(this.state).mainSalesAreaGet(id);
  },

  async subSalesAreaList(pageNo, pageSize, search) {
    return await salesAreaService(this.state).subSalesAreaList(
      pageNo,
      pageSize,
      search
    );
  },
  async subSalesAreaListByMainId(id) {
    return await salesAreaService(this.state).getSubSalesByMainSalesId(id);
  },
  async subSalesAreaCoordsListByMainId(id) {
    return await salesAreaService(this.state).subSalesAreaListByMainId(id);
  },
  async createSubSalesArea(data) {
    return await salesAreaService(this.state).subSalesAreaCreate(data);
  },
  async updateSubSalesArea(id, data) {
    return await salesAreaService(this.state).subSalesAreaUpdate(id, data);
  },
  async subSalesAreaDelete(id) {
    return await salesAreaService(this.state).subSalesAreaDelete(id);
  },
  async getSubSaleAreaById(id) {
    return await salesAreaService(this.state).subSalesAreaGet(id);
  },

  async showCaseList(pageNo, pageSize, search) {
    return await showCaseService(this.state).list(pageNo, pageSize, search);
  },
  async showCaseProductTransaction(id, body) {
    return await showCaseService(this.state).productTransaction(id, body);
  },
  async createShowCase(data) {
    return await showCaseService(this.state).create(data);
  },
  async updateShowCase(id, data) {
    return await showCaseService(this.state).update(id, data);
  },
  async getShowcaseById(id) {
    return await showCaseService(this.state).get(id);
  },
  async deleteShowCase(id) {
    return await showCaseService(this.state).delete(id);
  },
  async spiralTransaction(id, rotation, type, index) {
    return await showCaseService(this.state).spiralTransaction(
      id,
      rotation,
      type,
      index
    );
  },
  async storageList(pageNo, pageSize, search) {
    return await storageService(this.state).list(pageNo, pageSize, search);
  },
  async createStorage(data) {
    return await storageService(this.state).create(data);
  },
  async updateStorage(id, data) {
    return await storageService(this.state).update(id, data);
  },
  async getStorageById(id) {
    return await storageService(this.state).get(id);
  },
  async deleteStorage(id) {
    return await storageService(this.state).delete(id);
  },
  async storageProductList(id, pageNo, pageSize, search) {
    return await storageService(this.state).storageProducts(
      id,
      pageNo,
      pageSize,
      search
    );
  },
  async getStorageProductDetail(id) {
    return await storageService(this.state).getStorageProductDetail(id);
  },
  async addStockToProduct(data) {
    return await storageService(this.state).addStockToProduct(data);
  },
  async getStorageProductStockTransactions(id, page, pageSize, search) {
    return await storageService(
      this.state
    ).getProductInStorageStockTransactions(id, page, pageSize, search);
  },
  async verifyStockTransaction(id) {
    return await storageService(this.state).verifyStockTransaction(id);
  },
  async getStorageProductCurrentStock(id) {
    return await storageService(this.state).getProductCurrentStock(id);
  },
  async addProductToStorage(data) {
    return await storageService(this.state).addProductToStorage(data);
  },
  async addStockToStorage(data) {
    return await storageService(this.state).addStockToStorage(data);
  },

  async updateProductToStorage(data) {
    return await storageService(this.state).updateProductToStorage(data);
  },
  async storageCategoryList(pageNo, pageSize, search) {
    return await storageService(this.state).categoryList(
      pageNo,
      pageSize,
      search
    );
  },
  async createStorageCategory(data) {
    return await storageService(this.state).categoryCreate(data);
  },
  async updateStorageCategory(id, data) {
    return await storageService(this.state).categoryUpdate(id, data);
  },
  async getStorageCategoryById(id) {
    return await storageService(this.state).categoryGet(id);
  },
  async deleteStorageCategory(id) {
    return await storageService(this.state).categoryDelete(id);
  },
  async saleSpotList(pageNo, pageSize, search) {
    return await saleSpotService(this.state).list(pageNo, pageSize, search);
  },
  async getSaleSpotsByIds(main_sale_area_id, sale_area_id) {
    return await saleSpotService(this.state).getSaleSpotsByIds(
      main_sale_area_id,
      sale_area_id
    );
  },
  async createSaleSpot(data) {
    return await saleSpotService(this.state).create(data);
  },
  async updateSaleSpot(id, data) {
    return await saleSpotService(this.state).update(id, data);
  },
  async getSaleSpotById(id) {
    return await saleSpotService(this.state).get(id);
  },
  async deleteSaleSpot(id) {
    return await saleSpotService(this.state).delete(id);
  },
  async saleSpotCategoryList(pageNo, pageSize, search) {
    return await saleSpotService(this.state).categoryList(
      pageNo,
      pageSize,
      search
    );
  },
  async createSaleSpotCategory(data) {
    return await saleSpotService(this.state).categoryCreate(data);
  },
  async updateSaleSpotCategory(id, data) {
    return await saleSpotService(this.state).categoryUpdate(id, data);
  },
  async getSaleSpotCategoryById(id) {
    return await saleSpotService(this.state).categoryGet(id);
  },
  async deleteSaleSpotCategory(id) {
    return await saleSpotService(this.state).categoryDelete(id);
  },

  async getActionLogs(pageNo, pageSize, data) {
    return await userService(this.state).getActionLogs(pageNo, pageSize, data);
  },
  async getDeviceActionLogs(pageNo, pageSize, key) {
    return await userService(this.state).getDeviceActionLogs(
      pageNo,
      pageSize,
      key
    );
  },
  async getOrderList(pageNo, pageSize, search) {
    return await orderService(this.state).list(pageNo, pageSize, search);
  },
  async createOrder(data) {
    return await orderService(this.state).create(data);
  },
  async updateOrderStatus(id, status) {
    return await orderService(this.state).updateOrderStatus(id, status);
  },
  async getOrderById(id) {
    return await orderService(this.state).get(id);
  },
  async getDeliveryList(pageNo, pageSize, search) {
    return await deliveryService(this.state).list(pageNo, pageSize, search);
  },
  async getDeliveryUserList() {
    return await userService(this.state).deliveryUsers();
  },
  async assignUserToDelivery(data) {
    return await deliveryService(this.state).assignToUser(data);
  },
  async getDeliveryTasks() {
    return await deliveryService(this.state).getTasks();
  },
  async getDeliveryTaskById(id) {
    return await deliveryService(this.state).getTaskById(id);
  },
  async getDeliveryTaskSaleSpotById(task_id, id) {
    return await deliveryService(this.state).getTaskSaleSpotById(task_id, id);
  },
  async getDeliveryTaskShowCaseById(task_id, sale_spot_id, id) {
    return await deliveryService(this.state).getTaskShowCaseById(
      task_id,
      sale_spot_id,
      id
    );
  },
  async updateDeliveryTaskShowCaseStatus(task_id, sale_spot_id, id, data) {
    return await deliveryService(this.state).updateTaskSpiralElement(
      task_id,
      sale_spot_id,
      id,
      data
    );
  },
  async getDashboardData() {
    return await dashboardService(this.state).getDashboardData();
  },
  async getTransactions(pageNo, pageSize, search) {
    return await userService(this.state).getTransactions(
      pageNo,
      pageSize,
      search
    );
  },
  async getTransactionDetail(id) {
    return await userService(this.state).getTransactionDetail(id);
  },

  async getCampaignList(pageNo, pageSize, search) {
    return await campaignService(this.state).list(pageNo, pageSize, search);
  },

  async getCampaignById(id) {
    return await campaignService(this.state).get(id);
  },
  async createCampaign(data) {
    return await campaignService(this.state).create(data);
  },
  async updateCampaign(id, data) {
    return await campaignService(this.state).update(id, data);
  },
  async deleteCampaign(id) {
    return await campaignService(this.state).delete(id);
  },
};
