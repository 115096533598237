import { Badge, Button, NumberInput } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import services from "../../services";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const ProductDetail = ({ product }) => {
  const { t } = useTranslation();

  return (
    <div className="pt-2 flex flex-row flex-wrap gap-2">
      <Badge color="green">
        {t("product.brand")}:{" "}
        <span className="font-bold">{product.brand?.name || ""}</span>
      </Badge>
      <Badge color="cyan">
        {t("product.card-type")}:{" "}
        <span className="font-bold">{product.card_type?.name || ""}</span>
      </Badge>
      <Badge color="orange">
        {t("product.category")}:{" "}
        <span className="font-bold">{product.category?.name || ""}</span>
      </Badge>
      <Badge color="grape">
        {t("product.slot-width")} {product.slot_width}
      </Badge>
      <Badge color="violet">
        {t("product.slot-height")} {product.slot_height}
      </Badge>
    </div>
  );
};

const ShowcaseProduct = ({
  transaction,
  discardTransaction,
  onSubmit,
  horizontal_spiral_count,
}) => {
  const { t } = useTranslation();
  const [newTransaction, setNewTransaction] = useState({
    vertical_index: null,
    horizontal_index: null,
    product: "",
    min_stock: 0,
    max_stock: 0,
    current_stock: 0,
    showcase_type: "TEMPLATE",
    type: "",
  });
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["products", 1, 400],
    queryFn: async () =>
      await services.products(1, 400, "", true).then((res) => res.data),
  });

  useEffect(() => {
    if (
      transaction.vIndex !== newTransaction.vertical_index ||
      transaction.hIndex !== newTransaction.horizontal_index
    ) {
      setNewTransaction({
        vertical_index: transaction.vIndex,
        horizontal_index: transaction.hIndex,
        product: transaction?.product?._id || "",
        capacity: transaction?.product?.capacity || 0,
        showcase_type: "TEMPLATE",
        type: transaction.type,
      });
    }
  }, [transaction]);

  return (
    <div className="h-full flex-col flex gap-y-4 px-24 pt-24 pb-24">
      <div className="flex flex-row gap-2">
        <Badge color="blue" variant="dark" size="lg">
          No{" "}
          {transaction.vIndex * horizontal_spiral_count +
            (transaction.hIndex + 1)}
        </Badge>
      </div>
      <div className="flex flex-row gap-2">
        <Badge color="blue" variant="dark" size="lg">
          {t("show-case.column")} {transaction.hIndex + 1}
        </Badge>
        <Badge color="red" size="lg">
          {t("show-case.row")} {transaction.vIndex + 1}
        </Badge>
      </div>
      <div className="flex flex-col gap-x-2">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
        >
          {t("show-case.product")}
        </label>
        <CustomSelect
          withoutController
          searchable
          limit={10}
          disabled={transaction.type === "UPDATE"}
          value={newTransaction?.product || ""}
          onChange={(event) => {
            setNewTransaction({
              ...newTransaction,
              product: String(event),
            });
          }}
          data={
            data?.data?.map((d) => {
              return {
                label: `${d.name}`,
                value: d._id,
              };
            }) || []
          }
        />

        {!isLoading &&
          data?.data?.find((d) => d._id === newTransaction?.product) && (
            <ProductDetail
              product={data.data.find((d) => d._id === newTransaction?.product)}
            />
          )}
      </div>
      <div className="flex flex-col gap-x-2">
        <label
          htmlFor="email"
          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
        >
          {t("show-case.capacity")}
        </label>
        <NumberInput
          placeholder={t("show-case.capacity")}
          value={newTransaction?.capacity || 0}
          hideControls
          classNames={{
            input: `dark:!bg-gray-800  !bg-gray-100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 !ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full`,
            wrapper:
              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
            dropdown:
              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
            option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
          }}
          onChange={(event) => {
            setNewTransaction({
              ...newTransaction,
              capacity: Number(event),
            });
          }}
        />
      </div>

      <Button
        color="blue"
        onClick={() => {
          onSubmit(newTransaction);
        }}
      >
        {transaction.type === "ADD"
          ? t("show-case.add-product-to-slot-2")
          : t("show-case.update-slot")}
      </Button>
      <Button
        color="red"
        onClick={() => {
          discardTransaction();
        }}
      >
        {t("show-case.discard-transaction")}
      </Button>
    </div>
  );
};

export default ShowcaseProduct;
