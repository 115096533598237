import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { Badge, Pagination, Select } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import toast from "react-hot-toast";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import AddStorageProductModal from "../../components/AddStorageProduct/AddStorageProduct";
import RowSkeleton from "../../components/Skeleton/RowSkeleton";
import EmptyTableState from "../../components/EmptyTableState/EmptyTableState";
import AddStorageStockModal from "../../components/AddStorageStock/AddStorageStock";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const ProductRow = ({ product, index, handleNavigate }) => {
  const { t } = useTranslation();

  const { isLoading: isLoadingProductStock, data: dataProductStock } = useQuery(
    {
      queryKey: ["product-stock", product._id],
      queryFn: async () =>
        await services
          .getStorageProductCurrentStock(product._id)
          .then((res) => res.data),
    }
  );

  return (
    <tr
      key={`${product.name}-${index.toString()}`}
      className={`${
        index % 2 === 0
          ? "dark:bg-slate-700/80 bg-gray-200"
          : "dark:bg-slate-600/80 bg-gray-100"
      } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
    >
      <td className="whitespace-nowrap px-5 py-4 text-sm text-slate-700 dark:text-white">
        {product.name}
      </td>
      <td className="whitespace-nowrap px-5 py-4 text-sm text-slate-700 dark:text-white">
        <Badge>{product.min_stock}</Badge>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        {isLoadingProductStock ? (
          <RowSkeleton />
        ) : (
          <Badge color="green">{dataProductStock?.amount}</Badge>
        )}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        <Badge color="red">{product.max_stock}</Badge>
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        {isLoadingProductStock ? (
          <RowSkeleton />
        ) : (
          dataProductStock?.transaction_count
        )}
      </td>

      <td className="whitespace-nowrap px-3 py-4  text-sm text-gray-500 dark:text-gray-300">
        <Badge color={product.is_active ? "green" : "red"}>
          {product.is_active && !product.is_deleted
            ? t("status.active")
            : t("status.passive")}
        </Badge>
      </td>

      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 dark:text-gray-300">
        <button
          onClick={() => handleNavigate("stock-transactions", product._id)}
          className="text-orange-600 hover:text-orange-900"
        >
          {t("actions.stock-transactions")}
        </button>
      </td>
    </tr>
  );
};

const StorageTransactions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const { id: storageId } = useParams();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [addProductModalVisible, setAddProductModalVisible] = useState(false);
  const [addStockModalVisible, setAddStockModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchStorageProducts,
  } = useQuery({
    queryKey: ["storages", currentPage, pageSize, search, storageId],
    enabled: !!search || (!!pageSize && !!currentPage),
    queryFn: async () =>
      await services
        .storageProductList(storageId, currentPage, pageSize, search)
        .then((res) => res.data),
  });

  const { data: storageData } = useQuery({
    queryKey: ["storage-detail", storageId],
    queryFn: async () =>
      await services.getStorageById(storageId).then((res) => res.data),
  });

  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [data, currentPage]);
  if (error) return <p>Error :(</p>;

  const handleNavigate = (page, id) => {
    switch (page) {
      case "create":
        navigate("/storage/create");
        break;
      case "edit":
        navigate("/storage/edit/" + id);
        break;
      case "stock-transactions": {
        navigate("/storage/product-transactions/" + id);
        break;
      }
      case "delete":
        setId(id);
        setDeleteModalVisible(true);

        break;
      case "transactions":
        setAddProductModalVisible(true);
        break;
      case "stock":
        setAddStockModalVisible(true);
    }
  };

  const handleDelete = async (id) => {
    await services.deleteStorage(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message ? res.message : t("notifications.success.storage-delete")
        );

        setDeleteModalVisible(false);
        refetchStorageProducts();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  const handlePageChange = (page) => {
    if (data.data.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }
    setCurrentPage(page);
    setSearchParams({ page: page, pageSize: pageSize });
  };

  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };

  const handleAddProduct = async (data) => {
    if (data) {
      refetchStorageProducts();
      setAddProductModalVisible(false);
    }
  };
  const handleAddStock = async (data) => {
    if (data) {
      refetchStorageProducts();
      setAddStockModalVisible(false);
    }
  };

  const handleClose = (type) => {
    if (type == "product") {
      setAddProductModalVisible(false);
    } else if (type == "stock") {
      setAddStockModalVisible(false);
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={deleteModalVisible}
        onConfirm={() => handleDelete(id)}
        onClose={() => {
          setDeleteModalVisible(false);
          setId(null);
        }}
        title={t("titles.delete-storage")}
        confirmText={t("descriptions.delete-storage")}
      />
      <AddStorageProductModal
        isOpen={addProductModalVisible}
        onSend={handleAddProduct}
        id={storageId}
        onClose={() => handleClose("product")}
        title={t("titles.storage-add-product")}
      />
      <AddStorageStockModal
        isOpen={addStockModalVisible}
        onSend={handleAddStock}
        id={storageId}
        onClose={() => handleClose("stock")}
        title={t("titles.storage-product-transaction")}
      />

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            {storageData?.name}{" "}
            {t("actions.transactions", {
              name: t("titles.storage"),
            })}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.storage-product-list")}
          </p>
        </div>

        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-cente w-[35%]">
          <div className="block md:hidden xs:w-full">
            <CustomSearch
              textInputClassName="xs:w-full"
              search={search}
              onSearch={handleSearch}
            />
          </div>
          <button
            type="button"
            onClick={() => handleNavigate("stock", storageId)}
            className="block rounded-md bg-orange-600 dark:bg-orange-800 px-3 py-2  text-center text-sm font-semibold text-white shadow-sm hover:bg-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2  focus-visible:outline-orange-600 xs:w-full"
          >
            {t("titles.storage-product-transaction")}
          </button>
          <button
            type="button"
            onClick={() => handleNavigate("transactions", storageId)}
            className="block rounded-md bg-orange-600 dark:bg-orange-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 xs:w-full"
          >
            {t("titles.storage-add-product")}
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {!isLoading && data?.data?.length <= 0 ? (
                <div className="px-12 py-12">
                  <EmptyTableState
                    title={t("empty-text.storage-product-title")}
                    text={t("empty-text.storage-product-desc")}
                    buttonText={t("empty-text.storage-product-button")}
                    onClick={() => handleNavigate("transactions", storageId)}
                  />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                    <tr>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("product.name")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("storage.min-stock")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("storage.current-stock")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("storage.max-stock")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("storage.total-transaction-count")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("labels.status")}
                      </th>

                      <th
                        scope="col"
                        className="flex py-3.5 pl-3 pr-4 sm:pr-6 w-full "
                      >
                        <div className="hidden md:block">
                          <CustomSearch
                            search={search}
                            onSearch={handleSearch}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                    {isLoading || isFetching ? (
                      <TableSkeleton colSize={7} />
                    ) : (
                      data.data.map((product, index) => (
                        <ProductRow
                          key={index}
                          product={product}
                          index={index}
                          handleNavigate={handleNavigate}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {!(!isLoading && data?.data?.length <= 0) && (
          <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
            <div className="flex justify-center w-full items-center">
              <Pagination
                total={data?.totalPageCount}
                siblings={1}
                size={"md"}
                defaultValue={
                  Number(searchParams.get("page"))
                    ? Number(searchParams.get("page"))
                    : 1
                }
                onChange={handlePageChange}
                color="orange"
                classNames={{
                  control:
                    "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
                }}
              />
            </div>
            <div className=" flex justify-end w-[80px]">
              <CustomSelect
                withoutController={true}
                placeholder={t("placeholders.select")}
                onChange={(value) => {
                  setPageSize(value);
                  setSearchParams({ page: 1, pageSize: value });
                  setCurrentPage(1);
                }}
                data={[5, 10, 20, 30, 50]}
                value={pageSize}
                name="pageSize"
                searchable={false}
                height={"20px"}
                width={20}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageTransactions;
