import { Button, Input, Modal, NumberInput } from "@mantine/core";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomSelect from "../CustomSelect/CustomSelect";
import BarLoading from "../Loading/BarLoading";
const AddStorageStockModal = ({ isOpen, onClose, onSend, title, text, id }) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const assignUser = useMutation({
    mutationFn: (assignUser) => services.assignUserToDelivery(assignUser),
  });

  const {
    isLoading,
    error,
    data: productData,
    isFetching,
  } = useQuery({
    queryKey: ["delivery_user_list"],
    queryFn: async () =>
      await services.getDeliveryUserList().then((res) => {
        return res.data;
      }),
    enabled: isOpen == true,
  });

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = async (data) => {
    let assignUserPayload;
    let storageStock;
    onSend(data);
    try {
      assignUserPayload = {
        ...data,
        order_id: id,
      };
      storageStock = await assignUser.mutateAsync(assignUserPayload);

      if (storageStock) {
        toast.success(
          storageStock?.data?.message
            ? storageStock?.data?.message
            : t("notifications.success.assign-user-to-delivery"),
          {
            duration: 3000,
          }
        );

        onSend(data);
        reset();
      }
    } catch (e) {
      toast.error(
        e.response?.data?.message
          ? e.response?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const deliveryUser = useWatch({
    control,
    name: "delivery_user",
    defaultValue: null,
  });

  return (
    <Modal
      opened={isOpen}
      onClose={handleClose}
      centered
      classNames={{
        root: "dark:!bg-slate-700 !bg-gray-200",
        header: "dark:!bg-slate-700 !bg-gray-200",
        content: "!rounded-xl dark:!bg-slate-700 !bg-gray-200",
        title: "!text-md !font-semibold ",
        close: "dark:!text-white dark:!bg-gray-800 dark:hover:!bg-gray-800/70",
      }}
      title={title || "Add Storage Product"}
      overlayProps={{
        backgroundOpacity: 0.45,
        blur: 2,
        color: "#1E44FF",
      }}
    >
      <div>
        {isLoading ? (
          <BarLoading />
        ) : (
          <div>
            <form onSubmit={handleSubmit((data) => onSubmit(data, false))}>
              <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                <div className="sm:col-span-full">
                  <div className="mt-2 mb-2 flex gap-2 flex-col">
                    <label className="text-sm" htmlFor="name">
                      {t("labels.to-be-assigned-user")}
                    </label>
                    <CustomSelect
                      control={control}
                      name="delivery_user"
                      data={productData}
                      value={deliveryUser}
                      placeholder={t("placeholders.select")}
                    />

                    {errors.delivery_user && (
                      <span className="text-xs text-red-500">
                        {t("form-validations.required")}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-end mt-2 gap-2">
                {/* <Button
                  disabled={
                    !amount || !product || !expirationDate || !inDate || !lotNo
                      ? true
                      : false
                  }
                  variant="filled"
                  color="orange"
                  onClick={() => handleRetryAdd()}
                  type="button"
                  radius={"md"}
                >
                  {t("actions.confirm-and-retry")}
                </Button> */}
                <Button
                  disabled={!deliveryUser ? true : false}
                  variant="filled"
                  color="orange"
                  type="submit"
                  radius={"md"}
                >
                  {t("actions.confirm")}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddStorageStockModal;
