import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Badge, Pagination, Select } from "@mantine/core";
import services from "../../services";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const Userlist = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: companyRefetch,
  } = useQuery({
    queryKey: ["companies", currentPage, pageSize, search],
    enabled: !!search || (!!pageSize && !!currentPage),
    queryFn: async () =>
      await services
        .compaines(currentPage, pageSize, search)
        .then((res) => res.data),
  });
  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);

  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data.data.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };

  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };

  const handleNavigate = (page, id) => {
    switch (page) {
      case "create":
        navigate("/company/create");
        break;
      case "edit":
        navigate("/company/edit/" + id);
        break;
      case "delete":
        setId(id);
        setDeleteModalVisible(true);

        break;
      default:
        navigate("/company/list");
    }
  };

  const handleDelete = async (id) => {
    await services.deleteCompany(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message ? res.message : t("notifications.success.company-delete")
        );

        setDeleteModalVisible(false);
        companyRefetch();
      } else {
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  if (error) return <p>Error :(</p>;

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={deleteModalVisible}
        onConfirm={() => handleDelete(id)}
        onClose={() => {
          setDeleteModalVisible(false);
          setId(null);
        }}
        title={t("titles.delete-company")}
        confirmText={t("descriptions.delete-company")}
      />
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
            {t("titles.company")}
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
            {t("descriptions.company-list")}
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-center">
          <div className="block md:hidden xs:w-full">
            <CustomSearch
              textInputClassName="xs:w-full"
              search={search}
              onSearch={handleSearch}
            />
          </div>
          <button
            type="button"
            onClick={() => handleNavigate("create")}
            className="block xs:w-full rounded-md bg-orange-600 dark:bg-orange-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600"
          >
            {t("actions.add-company")}
          </button>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold dark:text-gray-300 text-slate-700 sm:pl-6"
                    >
                      {t("user.name")}
                    </th>
                    <th
                      scope="col"
                      className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-gray-300 text-slate-700"
                    >
                      {t("labels.status")}
                    </th>

                    <th scope="col" className="flex py-3.5 pl-3 pr-4 sm:pr-6">
                      <div className="hidden md:block">
                        {" "}
                        <CustomSearch search={search} onSearch={handleSearch} />
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                  {isLoading || isFetching ? (
                    <TableSkeleton colSize={3} />
                  ) : (
                    data.data.map((company, index) => (
                      <tr
                        key={`${company.name}-${index.toString()}`}
                        className={`${
                          index % 2 === 0
                            ? "dark:bg-slate-700/80 bg-gray-200"
                            : "dark:bg-slate-600/80 bg-gray-100"
                        } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                      >
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium dark:text-gray-300 text-slate-700 sm:pl-6">
                          {company.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          <Badge color={company.is_active ? "green" : "red"}>
                            {company.is_active && !company.is_deleted
                              ? t("status.active")
                              : t("status.passive")}
                          </Badge>
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 ">
                          <button
                            onClick={() => handleNavigate("edit", company._id)}
                            className="text-orange-600 hover:text-orange-900"
                          >
                            {t("actions.edit")}
                            <span className="sr-only">, {company.name}</span>
                          </button>

                          <button
                            onClick={() =>
                              handleNavigate("delete", company._id)
                            }
                            className="text-orange-600 hover:text-orange-900 ml-3"
                          >
                            {t("actions.delete")}
                            <span className="sr-only">, {company.name}</span>
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
          <div className="flex justify-center w-full items-center">
            <Pagination
              total={data?.totalPageCount}
              siblings={1}
              size={"md"}
              defaultValue={
                Number(searchParams.get("page"))
                  ? Number(searchParams.get("page"))
                  : 1
              }
              value={currentPage}
              onChange={handlePageChange}
              color="orange"
              classNames={{
                control:
                  "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
              }}
            />
          </div>
          <div className=" flex justify-end w-[80px]">
            <CustomSelect
              withoutController={true}
              placeholder={t("placeholders.select")}
              onChange={(value) => {
                setPageSize(value);
                setSearchParams({ page: 1, pageSize: value || 10 });
                setCurrentPage(1);
              }}
              data={[5, 10, 20, 30, 50]}
              value={pageSize}
              name="pageSize"
              searchable={false}
              height={"20px"}
              width={20}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Userlist;
