import { axiosInstance } from ".";

export const productService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `product?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }${search ? `&is_for_select=${true}` : ""}`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`product/detail/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("product", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.put(`product/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(`product/delete/${id}`);
      return response?.data || [];
    },
    async brandList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `product/brand?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async brandCreate(data) {
      const response = await axiosInstance.post("product/brand", data);
      return response?.data || [];
    },
    async brandUpdate(id, data) {
      const response = await axiosInstance.put(`product/brand/${id}`, data);
      return response?.data || [];
    },
    async brandGet(id) {
      const response = await axiosInstance.get(`product/brand/${id}`);
      return response?.data || [];
    },
    async brandDelete(id) {
      const response = await axiosInstance.get(`product/brand/delete/${id}`);
      return response?.data || [];
    },
    async cardTypeList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `product/card-type?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async cardTypeCreate(data) {
      const response = await axiosInstance.post("product/card-type", data);
      return response?.data || [];
    },
    async cardTypeUpdate(id, data) {
      const response = await axiosInstance.put(`product/card-type/${id}`, data);
      return response?.data || [];
    },
    async cardTypeDelete(id) {
      const response = await axiosInstance.get(
        `product/card-type/delete/${id}`
      );
      return response?.data || [];
    },
    async cardTypeGet(id) {
      const response = await axiosInstance.get(`product/card-type/${id}`);
      return response?.data || [];
    },

    async categoryList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `product/category?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async subCategoryListByCategoryId(id) {
      const response = await axiosInstance.get(
        `product/category/sub-categories/${id}`
      );
      return response?.data || [];
    },
    async categoryCreate(data) {
      const response = await axiosInstance.post("product/category", data);
      return response?.data || [];
    },
    async categoryUpdate(id, data) {
      const response = await axiosInstance.put(`product/category/${id}`, data);
      return response?.data || [];
    },
    async categoryGet(id) {
      const response = await axiosInstance.get(`product/category/${id}`);
      return response?.data || [];
    },
    async categoryDelete(id) {
      const response = await axiosInstance.get(`product/category/delete/${id}`);
      return response?.data || [];
    },
    async subCategoryList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `product/sub-category?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async subCategoryCreate(data) {
      const response = await axiosInstance.post("product/sub-category", data);
      return response?.data || [];
    },
    async subCategoryUpdate(id, data) {
      const response = await axiosInstance.put(
        `product/sub-category/${id}`,
        data
      );
      return response?.data || [];
    },
    async subCategoryDelete(id) {
      const response = await axiosInstance.get(
        `product/sub-category/delete/${id}`
      );
      return response?.data || [];
    },
    async subCategoryGet(id) {
      const response = await axiosInstance.get(`product/sub-category/${id}`);
      return response?.data || [];
    },
  };
};
