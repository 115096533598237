import { useTranslation } from "react-i18next";
import DashboardStats from "../../components/Stats/DashboardStats";
import ReactECharts from "echarts-for-react";
import { Button } from "@mantine/core";
import { useNavigate } from "react-router";
import services from "../../services";
import { useQuery } from "@tanstack/react-query";
import BarLoading from "../../components/Loading/BarLoading";
import { amountFormatter } from "../../utils/helper";
import { useState } from "react";
import toast from "react-hot-toast";
import dayjs from "dayjs";
const Home = () => {
  const { t } = useTranslation();
  const [stats, setStats] = useState([]);
  const [options, setOptions] = useState({});

  const navigate = useNavigate();
  const handleNavigate = (key) => {
    if (key === "transactions") navigate("/transactions");
  };
  const { isLoading, error, isFetching } = useQuery({
    queryKey: ["dashboard-data"],
    queryFn: async () => await getDashboardData(),
  });

  const getDashboardData = async () => {
    try {
      const { data } = await services.getDashboardData();
      if (error) {
        toast.error(t("notifications.error.message"), {
          duration: 3000,
        });
      }
      const formattedStats = [
        {
          id: 1,
          name: t("dashboard.registered-users"),
          value: data?.total_active_users,
        },
        {
          id: 2,
          name: t("dashboard.total-transaction-count"),
          value: data?.total_transaction_count,
        },
        {
          id: 3,
          name: t("dashboard.total-sales-amount"),
          key: "total_transaction_price",
          value: amountFormatter(data.total_transaction_price) + " TL",
        },
      ];
      setStats(formattedStats || []);
      const formattedDashboardData =
        data?.chart_data?.map((item) => ({
          name: dayjs(item.created_at).format("DD.MM.YYYY"),
          title: dayjs(item.created_at).format("DD.MM.YYYY HH:mm"),
          value: item.price,
        })) || [];
      setOptions({
        xAxis: {
          type: "category",
          data: formattedDashboardData.map((item) => item.name),
        },

        yAxis: {
          type: "value",
          min: 0,
          max:
            Math.max(...formattedDashboardData.map((item) => item.value)) + 10,
          splitLine: {
            lineStyle: {
              // rgba line opacity change for #1E44FF
              color: "rgba(30,68,255,0.2)",
            },
          },
        },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
          formatter: function (params) {
            return (
              formattedDashboardData[params[0].dataIndex].title +
              "<br/>" +
              params[0].marker +
              " " +
              t("labels.amount-mny") +
              ": " +
              amountFormatter(params[0].value) +
              "TL"
            );
          },
        },
        // line color change
        series: [
          {
            data: formattedDashboardData.map((item) => item.value),
            type: "bar",
            showBackground: true,

            backgroundStyle: {
              color: "rgba(0,0,0,0.08)",
            },
            emphasis: {
              itemStyle: {
                borderRadius: [2, 2],
                color: "#1E44FF",
              },
            },

            itemStyle: {
              borderRadius: [2, 2],
              color: "#1E44FF",
            },
          },
        ],
      });

      return data;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="h-[calc(100vh_-_110px)] flex flex-col">
      {isLoading ? (
        <div className="bg-gray-400/10 h-full w-full">
          <BarLoading />
        </div>
      ) : (
        <div className="flex  flex-col h-full justify-between">
          <div>
            <DashboardStats data={stats} />
          </div>
          <div className="  flex  gap-4 flex-col  lg:flex-nowrap   place-items-center  h-full  ">
            <div className="w-full flex justify-end px-8 mt-4">
              <Button
                onClick={() => handleNavigate("transactions")}
                color="#1E44FF"
                variant="transparent"
              >
                {t("actions.go-to-transactions")}
              </Button>
            </div>
            <div className="bg-gray-400/10 py-8 rounded-2xl w-full max-h-full h-full relative block  ">
              <div className=" h-full w-full">
                {isFetching && (
                  <div className="absolute top-0 left-0 w-full h-full bg-gray-400/10">
                    <BarLoading />
                  </div>
                )}
                {!isFetching && (
                  <ReactECharts
                    className="md:!h-full md:w-[98%] mx-auto"
                    option={options}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;
