import { useForm } from "react-hook-form";
import services from "../../services";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginValidation as login } from "./validations/";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { Button } from "@mantine/core";
import Logo from "../../assets/textLogo.svg";
import CustomInput from "../../components/CustomInput/CustomInput";
const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(login),
  });
  const { t } = useTranslation();
  const [disabled, setDisabled] = useState(false);
  const navigate = useNavigate();
  const { auth } = useStore();

  useEffect(() => {
    if (auth.isLogged) {
      navigate("/");
    }
  }, []);

  const onSubmit = async (data) => {
    setDisabled(true);
    try {
      const response = await services.login(data);
      const token = response.data?.access_token;

      const me = await services.me(token);
      toast.success(
        t("notifications.success.login-message", {
          user: me.data?.full_name,
        }),
        {
          duration: 3000,
        }
      );

      auth.login(token, me.data);
      auth.setUserGet(me.data);
      auth.setUserGetLoading(false);
      setDisabled(false);
      navigate("/");
    } catch (error) {
      setDisabled(false);
      toast.error(t("notifications.error.message"), {
        position: "top-right",
        duration: 3000,
      });
    }
  };

  return (
    <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
        <div className="mx-auto  flex items-center justify-center bg-orange w-fit rounded-md px-2">
          <img
            alt="logo"
            src={Logo}
            className="text-2xl w-[120px] h-[32px] font-bold"
          />
        </div>
        <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight dark:text-white text-slate-700">
          {t("auth.login.title")}
        </h2>
      </div>

      <div className="mt-6 sm:mx-auto sm:w-full sm:max-w-sm">
        <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            label={t("auth.login.email")}
            type="email"
            name="email"
            register={register}
            errors={errors}
          />

          <div>
            <CustomInput
              label={t("auth.login.password")}
              type="password"
              name="password"
              register={register}
              errors={errors}
            />
          </div>

          <div>
            <Button
              disabled={disabled}
              color="orange"
              type="submit"
              loading={disabled}
              radius={"md"}
              className="flex !w-full justify-center rounded-md dark:bg-orange-600 !bg-orange px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm dark:hover:bg-orange hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
            >
              {t("auth.login.signin")}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
