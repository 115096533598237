import React, { useState } from "react";
import "react-tooltip/dist/react-tooltip.css";

import { Popover, Text, Button, Grid, Badge } from "@mantine/core";
import { config } from "../../config";
import { useTranslation } from "react-i18next";

const PopoverWrapper = ({
  slot,
  vIndex,
  hIndex,
  anchor_key,
  children,
  deleteProduct,
  updateProduct,
  blockSlot,
  addProduct,
}) => {
  const { t } = useTranslation();
  const customPopover = () => {
    switch (slot.type) {
      case "EMPTY":
        return <EmptyPopOver />;
      case "PRODUCT":
        return <ProductPopOver />;
      case "CLOSED":
        return <BlockedPopOver />;
    }
  };

  const BlockedPopOver = () => {
    return (
      <Popover.Dropdown className="text-black gap-y-4 flex">
        <Text size="md" className="text-black">
          {t("show-case.this-field-is-blocked-by-a-reason")}
        </Text>
        <div className="flex flex-row justify-between gap-x-4">
          <Button onClick={blockSlot} color={"green"}>
            {t("show-case.un-block-slot")}
          </Button>
        </div>
      </Popover.Dropdown>
    );
  };

  const EmptyPopOver = () => {
    return (
      <Popover.Dropdown className="flex text-black items-center flex-col gap-y-4 justify-center">
        <Text size="md">
          {t("show-case.this-field-is-empty-you-can-add-product")}
        </Text>
        <div className="flex flex-row justify-between gap-x-4">
          <Button onClick={addProduct}>{t("show-case.add_product")}</Button>
          <Button onClick={blockSlot} color={"red"}>
            {t("show-case.block-slot")}
          </Button>
        </div>
      </Popover.Dropdown>
    );
  };

  const ProductPopOver = () => {
    return (
      <Popover.Dropdown>
        <div className="text-sm items-center text-black flex gap-y-2 justify-center flex-col">
          {slot.product?.image && (
            <img
              src={config.CDN_URL + slot.product.image}
              className=" bg-red-400 h-[100px] w-[100px]"
            />
          )}
          <span className="font-semibold">{slot.product.name}</span>
          <Grid
            rows
            columns={2}
            className="text-xs items-center justify-content"
          >
            <Grid.Col
              span={2}
              className="flex flex-row justify-center w-full items-center"
            >
              <Badge color="green">
                {t("show-case.current", {
                  count: slot.current_stock,
                })}
              </Badge>

              <Badge color="orange" className="ml-2">
                {t("show-case.capacity")} {slot.capacity}
              </Badge>
            </Grid.Col>
            <Grid.Col span={1}>
              {t("show-case.brand")}: {slot.product?.brand?.name}
            </Grid.Col>
            <Grid.Col span={1}>
              {t("show-case.card-type")}: {slot.product?.card_type?.name}
            </Grid.Col>
            <Grid.Col span={1}>
              {t("show-case.category")}: {slot.product?.category?.name}
            </Grid.Col>
            <Grid.Col span={1}>
              {t("show-case.column")}: {hIndex + 1} {t("show-case.row")}:{" "}
              {vIndex + 1}
            </Grid.Col>
            <Grid.Col span={1}>
              {/* <Button
                className="w-full"
                onClick={updateProduct}
                color="green"
                size="xs"
              >
                {t("show-case.edit_product")}
              </Button> */}
            </Grid.Col>
            <Grid.Col span={1} className="flex justify-flex-end">
              <Button
                className="ml-auto"
                onClick={deleteProduct}
                color="red"
                size="xs"
              >
                {t("show-case.delete_product")}
              </Button>
            </Grid.Col>
          </Grid>
        </div>
      </Popover.Dropdown>
    );
  };

  return (
    <Popover width={300} position="top" withArrow shadow="md">
      <Popover.Target>{children}</Popover.Target>
      {customPopover()}
    </Popover>
  );
};

export default PopoverWrapper;
