import routes from "../router/routes";

const returnRoutes = (roles = []) => {
  const newRoutes = routes
    .map((route) => {
      const subRoutes = route?.subRoutes?.filter((a) => {
        return (
          roles?.includes(a.role) ||
          (roles?.includes("all:access") && a.role !== "delivery:user") ||
          a.role == "public"
        );
      });

      if (
        subRoutes?.length >= 1 ||
        route.role == "public" ||
        roles.includes(route.role) ||
        (roles?.includes("all:access") && route.role !== "delivery:user")
      ) {
        return {
          ...route,
          subRoutes: subRoutes,
        };
      } else {
        return undefined;
      }
    })
    .filter((a) => a && a);

  return newRoutes;
};

export default returnRoutes;
