const DashboardStats = ({ data }) => {
  return (
    <div className="mx-auto max-w-full mb-4 ">
      <dl className="mt-4 grid grid-cols-1 gap-0.5 overflow-hidden rounded-2xl text-center sm:grid-cols-2 lg:grid-cols-3">
        {data.map((stat) => (
          <div key={stat.id} className="flex flex-col bg-gray-400/10 p-8">
            <dt className="text-sm font-semibold leading-6 text-gray-600 dark:text-white/70">
              {stat.name}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-orange">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </div>
  );
};
export default DashboardStats;
