import * as yup from "yup";
const validations = yup.object().shape({
  name: yup.string().required("validationMessages.required"),
  main_sale_area: yup.string().required("validationMessages.required"),
  coordinates: yup.array().required("validationMessages.required"),
  storage: yup.string().required("validationMessages.required"),
  critic_stock: yup.string().required("validationMessages.required"),
});

export default validations;
