import { Button, Modal, NumberInput } from "@mantine/core";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import services from "../../services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomSelect from "../CustomSelect/CustomSelect";
import BarLoading from "../Loading/BarLoading";

const UpdateTaskSpiral = ({
  isOpen,
  onClose,
  onSend,
  title,
  text,
  id,
  item,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const addStorageProduct = useMutation({
    mutationFn: (newStorageProduct) =>
      services.addProductToStorage(newStorageProduct),
  });
  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data) => {
    await onSend(data);

    reset();
  };

  const count = useWatch({
    control,
    name: "count",
  });

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      centered
      classNames={{
        root: "dark:!bg-slate-700",
        header: "dark:!bg-slate-700",
        content: "!rounded-xl dark:!bg-slate-700",
        title: "!text-md !font-semibold ",
        close: "dark:!text-white dark:!bg-gray-800 dark:hover:!bg-gray-800/70",
      }}
      title={title || "Add Storage Product"}
      overlayProps={{
        backgroundOpacity: 0.45,
        blur: 2,
        color: "#1E44FF",
      }}
    >
      <div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
              <div className="sm:col-span-full">
                <div className="sm:col-span-full">
                  <label
                    htmlFor="vertical_spiral_count"
                    className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                  >
                    {t("storage.amount")}
                  </label>
                  <div className="mt-2">
                    <NumberInput
                      value={count}
                      onChange={(value) => setValue("count", value)}
                      placeholder={t("storage.amount")}
                      hideControls
                      classNames={{
                        input: `dark:!bg-gray-800  !bg-gray-100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 !ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full`,
                        wrapper:
                          "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                        dropdown:
                          "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                        option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                      }}
                    />
                    <p className="mt-2 text-sm text-red-600" id="email-error">
                      {errors.count?.message}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex w-full justify-end mt-2 gap-2">
              <Button
                variant="outline"
                color="orange"
                onClick={() => handleClose()}
                radius={"md"}
              >
                {t("actions.cancel")}
              </Button>
              <Button
                disabled={!count ? true : false}
                variant="filled"
                color="orange"
                type="submit"
                radius={"md"}
              >
                {t("actions.confirm")}
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default UpdateTaskSpiral;
