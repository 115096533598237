import {
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  DocumentDuplicateIcon,
} from "@heroicons/react/24/outline";
import { CopyButton, Tooltip, ActionIcon } from "@mantine/core";
import { useTranslation } from "react-i18next";

const Duplicator = ({ text, isTextVisible = true }) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-row items-center gap-x-2">
      <CopyButton value={text} timeout={1000}>
        {({ copied, copy }) => {
          return (
            <Tooltip
              label={copied ? t("utils.copied") : t("utils.copy")}
              withArrow
              position="right"
            >
              <ActionIcon
                color={copied ? "teal" : "gray"}
                variant="subtle"
                onClick={copy}
              >
                {copied ? (
                  <ClipboardDocumentCheckIcon width={20} />
                ) : (
                  <ClipboardDocumentIcon width={20} />
                )}
              </ActionIcon>
            </Tooltip>
          );
        }}
      </CopyButton>
      {isTextVisible ? <span>{text}</span> : null}
    </div>
  );
};

export default Duplicator;
