import { axiosInstance } from ".";

export const salesAreaService = (state) => {
  return {
    async mainSalesAreaList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `/sale-area/main?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async mainSaleAreaSelectList() {
      const response = await axiosInstance.get(`/sale-area/main/select-list`);
      return response?.data || [];
    },
    async subSalesAreaListByMainId(id) {
      const response = await axiosInstance.get(`/sale-area/list/${id}`);
      return response?.data || [];
    },
    async mainSalesAreaCreate(data) {
      const response = await axiosInstance.post("/sale-area/main", data);
      return response?.data || [];
    },
    async mainSalesAreaUpdate(id, data) {
      const response = await axiosInstance.put(`/sale-area/main/${id}`, data);
      return response?.data || [];
    },
    async mainSalesAreaDelete(id) {
      const response = await axiosInstance.get(`/sale-area/main/delete/${id}`);
      return response?.data || [];
    },
    async mainSalesAreaGet(id) {
      const response = await axiosInstance.get(`/sale-area/main/detail/${id}`);
      return response?.data || [];
    },

    async subSalesAreaList(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `/sale-area/list?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async subSalesAreaCreate(data) {
      const response = await axiosInstance.post("/sale-area/", data);
      return response?.data || [];
    },
    async subSalesAreaUpdate(id, data) {
      const response = await axiosInstance.put(`/sale-area/${id}`, data);
      return response?.data || [];
    },
    async subSalesAreaDelete(id) {
      const response = await axiosInstance.get(`/sale-area/delete/${id}`);
      return response?.data || [];
    },
    async subSalesAreaGet(id) {
      const response = await axiosInstance.get(`/sale-area/detail/${id}`);
      return response?.data || [];
    },
    async getSubSalesByMainSalesId(id) {
      const response = await axiosInstance.get(`/sale-area/select-list/${id}`);
      return response?.data || [];
    },
  };
};
