import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import ListBox from "../../components/Listbox/Listbox";
import { useCallback, useEffect, useReducer, useState } from "react";
import { Image, PasswordInput } from "@mantine/core";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import { Group, Text, rem, Input } from "@mantine/core";
import { IconUpload, IconPhoto, IconX } from "@tabler/icons-react";
import { IMAGE_MIME_TYPE, Dropzone } from "@mantine/dropzone";
import { config } from "../../config";
import { CreateAndUpdateValidations } from "./validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { IMaskInput } from "react-imask";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import SaveButton from "../../components/Buttons/SaveButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import CustomDateInput from "../../components/CustomDateInput/CustomDateInput";

const UserCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CreateAndUpdateValidations),
  });
  const roles = useWatch({ control, name: "roles" });
  const gender = useWatch({ control, name: "gender" });
  const phone_number = useWatch({ control, name: "phone_number" });
  const [loading, setLoading] = useState(false);
  const [genders] = useState([
    {
      id: "1",
      key: "male",
      name: t("gender.male"),
    },
    {
      id: "2",
      key: "female",
      name: t("gender.female"),
    },
  ]);
  const reducer = (state, action) => {
    switch (action.type) {
      case "gender":
        return { ...state, gender: action.payload };
      case "company":
        return { ...state, company: action.payload };
      case "code":
        return { ...state, phone_country_code: action.payload };
      case "birth_date":
        return { ...state, birth_date: action.payload };
      case "role":
        return { ...state, role: action.payload };
      case "photo":
        return { ...state, photo: action.payload };
      case "photo_url":
        return { ...state, photo_url: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, {
    gender: genders[0],
    company: null,
    phone_country_code: null,
    birth_date: null,
  });

  const {
    isLoading: companyLoading,
    error: companyError,
    data: companyData,
  } = useQuery({
    queryKey: ["companies"],
    queryFn: () =>
      services.compaines(1, 400).then((res) =>
        res.data.data.map((item) => {
          return {
            ...item,
            id: item._id,
            name: item.name,
          };
        })
      ),
  });
  const {
    isLoading,
    error,
    data: storageData,
    isFetching,
    refetch: refetchStorages,
  } = useQuery({
    cacheTime: 0,
    queryKey: ["storages"],
    enabled: roles?.includes("storage:user"),
    queryFn: async () =>
      await services.storageList(1, 400).then((res) =>
        res.data.data.map((item) => {
          return {
            label: item.name,
            value: item._id,
          };
        })
      ),
  });

  const {
    isLoading: codeLoading,
    error: codeError,
    data: codeData,
  } = useQuery({
    cache: Infinity,
    queryKey: ["phoneCodes"],
    queryFn: () =>
      services.phoneCodes().then((res) => {
        const code = new Map();
        const codes = res.data.filter((item) => {
          if (!code.has(item.value)) {
            code.set(item.value, true);
            return true;
          }
          return false;
        });
        return codes;
      }),
  });

  const {
    isLoading: roleLoading,
    error: roleError,
    data: roleData,
    isSuccess: roleSuccess,
  } = useQuery({
    queryKey: ["roles"],
    queryFn: async () =>
      await services.roles().then((res) => {
        const data = [...res.data];
        return data;
      }),
  });

  const handleCancel = () => {
    navigate("/user");
  };

  const addUser = useMutation({
    mutationFn: (newUser) => services.createUser(newUser),
  });
  const updateUser = useMutation({
    mutationFn: (newUser) => services.updateUser(id, newUser),
  });

  const onSubmit = async (data, photo) => {
    let newUserPayload;
    let user;
    let photoRes;
    const file = data.photo;
    const birth_date = new Date(data.birth_date);
    const bodyFormData = new FormData();
    if (photo?.path) {
      bodyFormData.append("type", file?.type);
      bodyFormData.append("file", file);
    }

    if (!state?.photo_url && photo?.path) {
      photoRes = await services.fileUpload(bodyFormData);

      dispatch({
        type: "photo_url",
        payload: photoRes?.data?.file || "",
      });
    }

    if (birth_date) {
      const birth_day = birth_date.getDate();
      const birth_month =
        birth_date.getMonth() + 1 > 9
          ? birth_date.getMonth() + 1
          : `0${birth_date.getMonth() + 1}`;
      const birth_year = birth_date.getFullYear();
      delete data.birth_date;

      newUserPayload = {
        ...data,
        gender: data.gender,
        birth_day,
        birth_month,
        birth_year,
        photo: (photo?.path ? photoRes?.data?.file : photo) || "",
      };
    }
    try {
      if (id) {
        user = await updateUser.mutateAsync(newUserPayload);
      } else {
        user = await addUser.mutateAsync(newUserPayload).catch((err) => {
          if (err.response?.data?.message) {
            toast.error(err.response?.data?.message, {
              duration: 3000,
            });
          } else {
            toast.error(t("notifications.error.message"), {
              duration: 3000,
            });
          }
        });
      }
      if (user && !id) {
        toast.success(t("notifications.success.user-create"), {
          duration: 3000,
        });

        reset();

        Object.keys(state).forEach((key) => {
          dispatch({
            type: key,
            payload: null,
          });
        });
        handleCancel();
      } else if (user && id) {
        toast.success(t("notifications.success.user-update"), {
          duration: 3000,
        });
        handleCancel();
      }
    } catch (e) {
      toast.error(t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const fetchUser = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const user = await services.getUserById(id);

        Object.keys(user.data).forEach((key) => {
          if (
            key === "birth_day" ||
            key === "birth_month" ||
            key === "birth_year"
          ) {
            return;
          } else if (key === "birthday") {
            const birth = user.data[key];
            const splits = birth.split("-");
            const dateObject = new Date(splits[2], splits[1] - 1, splits[0]);
            dateObject.setDate(dateObject.getDate() + 1);

            setValue("birth_date", dateObject);
            dispatch({
              type: "birth_date",
              payload: dateObject,
            });
          } else if (key === "roles") {
            setValue("roles", user.data[key][0]);
            dispatch({
              type: "role",
              payload: user.data[key],
            });
          } else if (
            key === "role_ids" &&
            user.data["roles"].includes("storage:user")
          ) {
            setValue("storage_id", user?.data[key][0].id);
            dispatch({
              type: "storage_id",
              payload: user?.data[key][0].id,
            });
          } else {
            dispatch({
              type: key,
              payload: user.data[key],
            });
            setValue(key, user.data[key]);
          }
        });
        setLoading(false);
      } catch (e) {
        navigate("/user");
        toast.error(t("notifications.error.user-notfound"), {
          duration: 3000,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    reset();
    Object.keys(state).forEach((key) => {
      dispatch({
        type: key,
        payload: null,
      });
    });

    if (id) {
      fetchUser();
    }
  }, [id]);

  return (
    <>
      {!companyLoading && !loading && !roleLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id ? t("titles.user-edit") : t("titles.user-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.user-edit")
                    : t("descriptions.user-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-6xl">
                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("user.name")}
                      type="text"
                      name="name"
                      register={register}
                      errors={errors}
                      placeholder={t("user.name")}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("user.surname")}
                      type="text"
                      name="last_name"
                      register={register}
                      errors={errors}
                      placeholder={t("user.surname")}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("user.email")}
                      type="email"
                      name="email"
                      register={register}
                      errors={errors}
                      placeholder={t("user.email")}
                    />
                  </div>
                  {!id && (
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("user.password")}
                      </label>
                      <div className="mt-2">
                        <PasswordInput
                          {...register("password")}
                          id="password"
                          name="password"
                          placeholder={t("user.password")}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100 !ring-1 ring-white/5 !border-none !rounded-md  dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.password?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper: "!bg-gray-800 rounded-md !text-white",
                            dropdown:
                              "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option: "hover:bg-gray-700",
                          }}
                          autoComplete="password"
                        />
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="email-error"
                        >
                          {t(errors.password?.message, {
                            min: 8,
                          })}
                        </p>
                      </div>
                    </div>
                  )}

                  <div className="sm:col-span-3 ">
                    {!roleError && !roleLoading && roleData && (
                      <div className="w-[100%] flex flex-col">
                        <label
                          htmlFor="roles"
                          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                        >
                          {t("user.role")}
                        </label>
                        <div className="mt-2">
                          <CustomSelect
                            control={control}
                            placeholder={t("placeholders.select")}
                            name={"roles"}
                            data={roleData}
                            searchable
                            clearable
                            height={"20px"}
                          />
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {t(errors.roles?.message)}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                  {(roles?.includes("storage:user") ||
                    roles?.includes("delivery:user")) && (
                    <div className="sm:col-span-3 w-full">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("titles.storage")}
                      </label>
                      <div className="mt-2">
                        <CustomSelect
                          control={control}
                          placeholder={t("placeholders.select")}
                          data={storageData}
                          name="storage_id"
                          searchable
                          height={"20px"}
                        />
                      </div>
                      <p className="mt-2 ml-1 text-sm text-red-600">
                        {t(errors.storage?.message)}
                      </p>
                    </div>
                  )}

                  <div className="sm:col-span-2">
                    <ListBox
                      data={genders.map((d) => {
                        return {
                          label: d.name,
                          value: d.key,
                        };
                      })}
                      error={errors.gender}
                      selected={{
                        label:
                          genders?.find((d) => d.key === gender)?.name ||
                          gender,
                        value: gender,
                      }}
                      headerLabel={t("titles.gender")}
                      onChange={(value) => {
                        setValue("gender", value.value);
                        dispatch({
                          type: "gender",
                          payload: value.value,
                        });
                      }}
                      showAtt={"label"}
                    />
                  </div>

                  <div className="sm:col-span-3">
                    {!companyLoading && !companyError && (
                      <div className="w-full">
                        <ListBox
                          data={companyData}
                          selected={
                            companyData?.find(
                              (comp) => comp._id === getValues("company_id")
                            ) || state.company
                          }
                          error={errors.company_id}
                          headerLabel={t("user.company")}
                          onChange={(value) => {
                            setValue("company_id", value.id);
                            dispatch({
                              type: "company",
                              payload: value,
                            });
                          }}
                          showAtt={"name"}
                        />
                      </div>
                    )}
                  </div>

                  <div className="sm:col-span-3 flex-row flex items-start  gap-4 flex-nowrap">
                    {!codeLoading && !codeError && (
                      <div className="w-[40%] flex flex-col">
                        <label
                          htmlFor="phone-code"
                          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                        >
                          {t("user.phone-code")}
                        </label>
                        <div className="mt-2">
                          <CustomSelect
                            control={control}
                            placeholder={t("placeholders.select")}
                            data={codeData}
                            name="phone_country_code"
                            searchable
                            height={"20px"}
                          />
                        </div>
                      </div>
                    )}

                    <div className="sm:col-span-3 w-full">
                      <label
                        htmlFor="phone-number"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("user.phone-number")}
                      </label>
                      <div className="mt-2">
                        <Input
                          component={IMaskInput}
                          mask="(000) 000-00-00"
                          type="text"
                          unmask={true}
                          name="phone_number"
                          id="phone_number"
                          placeholder={t("user.phone-number")}
                          autoComplete="phone_number"
                          value={phone_number}
                          onAccept={(e) => {
                            setValue("phone_number", e);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.phone_number?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper: "!bg-gray-800 rounded-md !text-white",
                            dropdown:
                              "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option: "hover:bg-gray-700",
                          }}
                        />
                      </div>
                      <p className="mt-2 ml-1 text-sm text-red-600">
                        {t(errors.phone?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3  justify-center items-start flex flex-col">
                    <CustomDateInput
                      value={state.birth_date}
                      locale={"tr"}
                      valueFormat={"DD/MM/YYYY"}
                      onChange={(value) => {
                        setValue("birth_date", value);
                        dispatch({
                          type: "birth_date",
                          payload: value,
                        });
                      }}
                      errors={errors}
                      label={t("user.birth-date")}
                      placeholder={t("placeholders.select")}
                    />
                    <p className="mt-2 ml-1 text-sm text-red-600">
                      {t(errors.birth_date?.message)}
                    </p>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      Fotoğraf
                    </label>
                    <Dropzone
                      onDrop={(files) => {
                        const fileUrl = URL.createObjectURL(files[0]);
                        dispatch({
                          type: "photo",
                          payload: fileUrl,
                        });
                        setValue("photo", files[0]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={3 * 1024 ** 2}
                      multiple={false}
                      classNames={{
                        root: "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 mt-2",
                      }}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-green-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {state.photo ? (
                            <div className="w-full h-full flex justify-center items-center">
                              <Image
                                radius="md"
                                h={"auto"}
                                className="max-h-[200px] w-full"
                                w="100%"
                                fit="cover"
                                src={
                                  state.photo.includes("http")
                                    ? state.photo
                                    : config.CDN_URL + state.photo
                                }
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <IconUpload
                                style={{
                                  width: rem(52),
                                  height: rem(52),
                                  color: "var(--mantine-color-gray-6)",
                                }}
                                stroke={1.5}
                              />
                              <Text
                                className="text-white"
                                style={{ fontSize: rem(12) }}
                              >
                                {t("placeholders.upload")}
                              </Text>
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton disabled={addUser.isLoading} />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default UserCreate;
