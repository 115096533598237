import { useQuery } from "@tanstack/react-query";
import services from "../../services";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Badge, Pagination, Select } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";

const DeliveryTasks = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );

  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };
  const handleNavigate = (page, id) => {
    switch (page) {
      case "delete":
        setId(id);

        break;
      case "assign-user":
        setId(id);

      case "detail":
        navigate(`/delivery-tasks/detail/${id}`);
    }
  };

  const handlePageChange = (page) => {
    const search = searchParams.get("search");
    if (data.data.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }

    setCurrentPage(page);
    if (search) {
      setSearchParams({ page: page, pageSize: pageSize, search: search });
      return;
    } else {
      setSearchParams({ page: page, pageSize: pageSize });
      return;
    }
  };
  const statusMapping = {
    IN_DELIVERY: {
      color: "orange",
    },
    DELIVERED: {
      color: "green",
    },
  };
  const { isLoading, error, data, isFetching } = useQuery({
    queryKey: ["delivery_tasks"],
    queryFn: async () =>
      await services.getDeliveryTasks().then((res) => {
        return res.data;
      }),
  });

  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: , pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [data, currentPage, pageSize, setSearchParams]);
  if (error) return <p>Error :(</p>;

  return (
    <div>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700">
              {t("titles.delivery-tasks")}
            </h1>
            <p className="mt-2 text-sm dark:text-gray-400 text-gray-500">
              {t("descriptions.delivery-tasks")}
            </p>
          </div>
        </div>
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                    <tr>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        ID
                      </th>

                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("labels.created-user")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("labels.created-date")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      ></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                    {isLoading || isFetching ? (
                      <TableSkeleton colSize={4} />
                    ) : (
                      data.data.map((deliveryTask, index) => (
                        <tr
                          key={`${deliveryTask?.user}-${index.toString()}`}
                          className={`${
                            index % 2 === 0
                              ? "dark:bg-slate-700/80 bg-gray-200"
                              : "dark:bg-slate-600/80 bg-gray-100"
                          } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
                        >
                          <td className="whitespace-nowrap px-5 py-4 text-sm text-slate-700 dark:text-white">
                            {deliveryTask._id.substr(
                              deliveryTask._id.length - 5
                            )}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
                            {deliveryTask.created_by?.full_name || "-"}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4  text-sm text-gray-500 dark:text-gray-300">
                            <Badge
                              color={
                                statusMapping[deliveryTask.status]?.color ||
                                "gray"
                              }
                            >
                              {deliveryTask.created_at_text}
                            </Badge>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 dark:text-gray-300">
                            <button
                              onClick={() =>
                                handleNavigate("detail", deliveryTask._id)
                              }
                              className="text-orange-600 hover:text-orange-900 ml-3"
                            >
                              {t("actions.detail")}
                              <span className="sr-only">
                                , {deliveryTask._id}
                              </span>
                            </button>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
            <div className="flex justify-center w-full items-center">
              <Pagination
                total={data?.totalPageCount}
                siblings={1}
                size={"md"}
                defaultValue={
                  Number(searchParams.get("page"))
                    ? Number(searchParams.get("page"))
                    : 1
                }
                value={currentPage}
                onChange={handlePageChange}
                color="orange"
                classNames={{
                  control:
                    "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
                }}
              />
            </div>
            <div className=" flex justify-end w-[80px]">
              <Select
                classNames={{
                  input: `dark:!bg-gray-800  !bg-gray-300/100 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 !ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full`,
                  wrapper:
                    "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                  dropdown:
                    "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                  option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                }}
                placeholder={t("placeholders.select")}
                onChange={(value) => {
                  setPageSize(value);
                  setSearchParams({ page: 1, pageSize: value });
                  setCurrentPage(1);
                }}
                data={[5, 10, 20, 30, 50]}
                value={pageSize}
                name="pageSize"
                searchable={false}
                height={"20px"}
                width={20}
              ></Select>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryTasks;
