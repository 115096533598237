import { axiosInstance } from ".";

export const campaignService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `campaign/all?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }${search ? `&is_for_select=${true}` : ""}`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`campaign/detail/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("campaign", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.put(`campaign/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.delete(`campaign/${id}`);
      return response?.data || [];
    },
  };
};
