import { Button, Modal } from "@mantine/core";
import { useTranslation } from "react-i18next";
import {config} from '../../config';

const ShowcaseDetailModal = ({ isOpen, setIsOpen, data }) => {
    const { t } = useTranslation();
    return (
        <Modal
            opened={isOpen}
            onClose={() => setIsOpen(false)}
            centered
            classNames={{
                root: "dark:!bg-slate-700 !bg-gray-200",
                    header: "dark:!bg-slate-700 !bg-gray-200",
                    content: "!rounded-xl dark:!bg-slate-700 !bg-gray-200",
                    title: "!text-md !font-semibold ",
                    close: "dark:!text-white dark:!bg-gray-800 dark:hover:!bg-gray-800/70",
            }}
            title={t("actions.confirm")}
            overlayProps={{
                backgroundOpacity: 0.45,
                    blur: 2,
                    color: "#1E44FF",
            }}
        >
            <ul role="list" className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-2">

        {data?.map((product) => (
            <li key={product.name} className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow">
            <div className="flex w-full items-center justify-between space-x-6 p-6">
                <div className="flex flex-col">
                    <div className="flex items-center space-x-3">
                        <h3 className="text-sm font-medium text-gray-900">{product.name}</h3>

                    </div>

                </div>
                <img className="h-16 w-16 flex-shrink-0 rounded-full" src={config.CDN_URL + product?.image} alt="" />
            </div>
            <div>
                <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="flex w-0 flex-1 flex-col">
                        <a
                            className="relative inline-flex  flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-0 pt-2 pb-0 text-sm font-semibold text-gray-900"
                        >

                            Stok
                        </a>
                        <a
                            className="relative inline-flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-0 pt-0 pb-2 text-sm font-semibold text-gray-900"
                        >

                            {product.current}
                        </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1 flex-col">
                        <a
                            className="relative inline-flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-0 pt-2 text-sm font-semibold text-gray-900"
                        >

                            Eksik
                        </a>
                        <a
                            className="relative inline-flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-0 pt-0 pb-2 text-sm font-semibold text-gray-900"
                        >

                            {product.required}
                        </a>
                    </div>
                    <div className="-ml-px flex w-0 flex-1 flex-col">
                        <a
                            className="relative inline-flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-0 pt-2 pb-0 text-sm font-semibold text-gray-900"
                        >

                            Kapasite
                        </a>
                        <a
                            className="relative inline-flex flex-1 items-center justify-center gap-x-3 rounded-br-lg border border-transparent py-0 pt-0 pb-2 text-sm font-semibold text-gray-900"
                        >

                            {product.max}
                        </a>
                    </div>
                </div>
            </div>
            </li>
        ))}
            </ul>
        </Modal>
    );
};

export default ShowcaseDetailModal;
