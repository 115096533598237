import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import SaveButton from "../../components/Buttons/SaveButton";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryCreateAndUpdate } from "../Storage/validations";
import CustomInput from "../../components/CustomInput/CustomInput";

const CompanyCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(CategoryCreateAndUpdate),
  });

  const addCompany = useMutation({
    mutationFn: (newCompany) => services.createCompany(newCompany),
  });
  const updateCompany = useMutation({
    mutationFn: (newCompany) => services.updateCompany(id, newCompany),
  });

  const onSubmit = async (data) => {
    let newCompanyPayload;
    let company;

    try {
      newCompanyPayload = {
        ...data,
      };
      if (!id) {
        company = await addCompany.mutateAsync(newCompanyPayload);
      } else {
        company = await updateCompany.mutateAsync(newCompanyPayload);
      }

      if (!id && company) {
        toast.success(t("notifications.success.company-create"), {
          duration: 3000,
        });

        handleCancel();
      } else if (id && company) {
        toast.success(t("notifications.success.company-update"), {
          duration: 3000,
        });
        handleCancel();
      }
    } catch (e) {
      toast.error(t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/company/list");
  };

  const fetchCompany = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const company = await services.getCompanyById(id);
        Object.keys(company.data).forEach((key) => {
          setValue(key, company.data[key]);
        });
      } catch (e) {
        navigate("/company");
        toast.error(t("notifications.error.company-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();

    if (id) {
      fetchCompany();
    }
  }, [id]);
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id ? t("titles.company-edit") : t("titles.company-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.company-edit")
                    : t("descriptions.company-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <CustomInput
                      label={t("user.company-name")}
                      type="text"
                      name="name"
                      register={register}
                      errors={errors}
                      placeholder={t("user.name")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateCompany.isLoading : addCompany.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default CompanyCreate;
