import { Alert, Button } from "@mantine/core";
import { useTranslation } from "react-i18next";
import { GiftDefineValidation } from "./validations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { closeModal } from "@mantine/modals";
import services from "../../services";
import Loading from "../Loading/Loading";
import toast from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import CustomMultiSelect from "../CustomSelect/CustomMultiSelect";
import CustomSelect from "../CustomSelect/CustomSelect";
import { IconInfoCircle } from "@tabler/icons-react";
const OrderFinish = ({ innerProps, id }) => {
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    defaultValues: {
      products: [],
    },
    resolver: yupResolver(GiftDefineValidation),
  });

  const product = useWatch({
    control,
    name: "product",
    defaultValue: null,
  });
  const type = useWatch({
    control,
    name: "type",
    defaultValue: null,
  });

  const icon = <IconInfoCircle />;
  const {
    isLoading: productsIsLoading,
    error: productsError,
    data: productsData,
    isFetching: productsFetching,
    refetch: refetchProducts,
  } = useQuery({
    queryKey: ["getProducts"],
    queryFn: async () =>
      await services.products(1, 999999, undefined, true).then((res) => {
        return res.data.data.map((item) => {
          return {
            value: item._id,
            label: item.brand.name + " " + item.name,
          };
        });
      }),
  });

  const onSubmit = async (data) => {
    const payload = {
      product: data.product,
      user_id: innerProps.user_id,
      type: data.type,
    };

    try {
      const response = await services.defineGiftProduct(payload);

      if (response.success) {
        toast.success(
          response?.data?.message || t("notifications.success.message")
        );
        closeModal(id);
      }
    } catch (error) {
      toast.error(
        error?.response?.data?.message || t("notifications.error.message")
      );
    }
  };

  return (
    <div>
      {productsIsLoading ? (
        <Loading />
      ) : (
        <div>
          <Alert
            className=" !bg-orange-200/50 !text-orange-800 dark:!bg-orange-800 dark:!text-orange-100"
            title={t("titles.info")}
            icon={icon}
          >
            <span className="dark:text-white/80">
              {t("descriptions.gift-product-definition", {
                user: innerProps.name,
              })}
            </span>
          </Alert>
          <form
            className="flex flex-col  mt-2"
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photos"))
            )}
          >
            <div className="sm:col-span-3 m-4">
              <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                {t("labels.gift-products")}
              </span>
              <div className="mt-2">
                <CustomSelect
                  control={control}
                  placeholder={t("placeholders.select")}
                  name="product"
                  data={productsData}
                  searchable
                  clearable
                  height={"20px"}
                  multiple
                  error={errors.product?.message}
                  defaultValue={product}
                  value={product}
                />
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {t(errors.product?.message)}
                </p>
              </div>
            </div>
            <div className="sm:col-span-3 m-4">
              <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                {t("labels.product-type")}
              </span>
              <div className="mt-2">
                <CustomSelect
                  control={control}
                  placeholder={t("placeholders.select")}
                  name="type"
                  data={[
                    {
                      value: "HOT",
                      label: t("productTypes.hot"),
                    },
                    {
                      value: "NORMAL",
                      label: t("productTypes.normal"),
                    },
                  ]}
                  searchable
                  clearable
                  height={"20px"}
                  multiple
                  error={errors.type?.message}
                  defaultValue={type}
                  value={type}
                />
                <p className="mt-2 text-sm text-red-600" id="email-error">
                  {t(errors.type?.message)}
                </p>
              </div>
            </div>

            <Button
              type="submit"
              size="xs"
              variant="light"
              radius={"md"}
              className="!bg-orange-500 !text-white !hover:bg-orange-600"
            >
              {t("actions.define")}
            </Button>
          </form>
        </div>
      )}
    </div>
  );
};

export default OrderFinish;
