import { axiosInstance } from ".";

export const dashboardService = (state) => {
  return {
    async getDashboardData() {
      const response = await axiosInstance.get(`/dashboard`);
      return response?.data || [];
    },
  };
};
