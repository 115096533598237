import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../../components/Loading/BarLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, CloseButton, Input, Switch, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import SaveButton from "../../../components/Buttons/SaveButton";
import { CategoryCreateAndUpdate } from "../../Product/validations";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Polygon,
} from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import CustomInput from "../../../components/CustomInput/CustomInput";
let defaultLocation = { lat: 41.015137, lng: 28.97953 };
const {
  DrawingManager,
} = require("react-google-maps/lib/components/drawing/DrawingManager");
const {
  StandaloneSearchBox,
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");
const mapStyles = require("../../../static/mapStyles.json");
const refs = {};
const PolygonDrawer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAdo5zJb7qFJWhEzPoUI3HhHWEJfnCICpg",
    loadingElement: <div className="w-full" style={{ height: `100%` }} />,
    containerElement: (
      <div
        className="w-full"
        style={{ height: `400px`, position: "relative" }}
      />
    ),
    mapElement: <div className="w-full" style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      this.setState({
        places: [],
        onSearchBoxMounted: (ref) => {
          refs.searchBox = ref;
        },
        onPlacesChanged: (setNewPosition, setSearchText) => {
          const places = refs.searchBox.getPlaces();
          const { lat, lng } = places[0]?.geometry?.location;
          setNewPosition({ lat: lat(), lng: lng() });
          setSearchText(places[0]?.formatted_address);

          this.setState({
            places,
          });
        },
      });
    },
  }),
  withScriptjs,
  withGoogleMap
)(
  ({
    value,
    drawingManagerRef,
    isActive = true,
    coordinates,
    isUpdate,
    refs,
    ...props
  }) => {
    const { t } = useTranslation();
    let polygonRef = useRef(null);
    const [searchText, setSearchText] = useState("");
    const [newPosition, setNewPosition] = useState(null);

    return (
      <GoogleMap
        defaultOptions={mapStyles}
        defaultZoom={15}
        defaultCenter={
          coordinates?.length > 0
            ? coordinates[parseInt(coordinates?.length - 1)]
            : defaultLocation
        }
        center={newPosition ? newPosition : defaultLocation}
      >
        <div
          data-standalone-searchbox=""
          className="w-[50%] absolute -top-11 left-0"
        >
          <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={() =>
              props.onPlacesChanged(setNewPosition, setSearchText)
            }
            style={{ width: "10%" }}
          >
            <Input
              size="sm"
              type="text"
              placeholder={t("placeholders.search-location")}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
              value={searchText}
              classNames={{
                input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white placeholder:!text-gray-500 !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 `,
                wrapper: "!bg-gray-800 rounded-md !text-white ",

                option: "hover:bg-gray-700",
              }}
              rightSectionPointerEvents="auto"
              rightSection={
                searchText?.length > 0 && (
                  <CloseButton
                    classNames={{
                      root: "dark:hover:!bg-gray-700  hover:!bg-gray-100    dark:!bg-gray-800 !bg-gray-300/100 dark:!text-gray-300 !text-slate-700",
                    }}
                    onClick={() => {
                      setSearchText("");
                    }}
                  />
                )
              }
            />
          </StandaloneSearchBox>
        </div>
        <DrawingManager
          ref={drawingManagerRef}
          defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          onPolygonComplete={(polygon) => {
            props.setCoordinates(polygon);
          }}
          onMapOverlayComplete={() => console.log("OVERLAY INTILIZED")}
          options={{
            drawingControl: isActive,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            defaultLocation: coordinates,
            polygonOptions: {
              fillColor: "rgba(0,0,0,0.4)",
              editable: true,
            },

            drawingMode: isActive
              ? window.google.maps.drawing.OverlayType.POLYGON
              : null,
          }}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },

            polygonOptions: {
              fillColor: "rgba(0,0,0,0.4)",
              editable: true,
            },
          }}
        />
        {isUpdate && coordinates && coordinates?.length > 0 && (
          <Polygon
            ref={polygonRef}
            key={0}
            onMouseUp={(event) => {
              if (polygonRef.current) {
                const coordinates = polygonRef.current
                  .getPath()
                  .getArray()
                  .map((latLng) => ({ lat: latLng.lat(), lng: latLng.lng() }));

                props.setCoordinatesRaw(coordinates);
              }
            }}
            options={{
              fillColor: "rgba(0,0,0,0.4)",
              editable: true,
            }}
            paths={coordinates}
          />
        )}
      </GoogleMap>
    );
  }
);

const MainSalesAreaCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  let polygonRef = useRef(null);
  let drawingManagerRef = useRef(null);
  const [isActiveDraw, setIsActiveDraw] = useState(true);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(CategoryCreateAndUpdate),
    defaultValues: {
      name: "",
      coordinates: [],
      parent_id: null,
      is_active: true,
    },
  });

  const addMainSalesArea = useMutation({
    mutationFn: (newMainSalesArea) =>
      services.createMainSalesArea(newMainSalesArea),
  });
  const updateMainSalesArea = useMutation({
    mutationFn: (newMainSalesArea) =>
      services.updateMainSalesArea(id, newMainSalesArea),
  });

  const coordinates = useWatch({
    control: control,
    name: "coordinates",
  });

  const onSubmit = async (data) => {
    let newMainSalesArea;
    let mainSalesArea;

    try {
      newMainSalesArea = {
        ...data,
      };
      if (coordinates?.length < 3) {
        toast.error(t("notifications.error.main-sales-area-coordinates"), {
          duration: 3000,
        });
        return;
      }
      if (!id) {
        mainSalesArea = await addMainSalesArea.mutateAsync(newMainSalesArea);
      } else {
        mainSalesArea = await updateMainSalesArea.mutateAsync(newMainSalesArea);
      }

      if (!id && mainSalesArea) {
        toast.success(
          mainSalesArea?.data?.message
            ? mainSalesArea?.data?.message
            : t("notifications.success.main-sales-area-create"),
          {
            duration: 3000,
          }
        );

        reset();
        handleCancel();
      } else if (id && mainSalesArea) {
        toast.success(
          mainSalesArea?.data?.message
            ? mainSalesArea?.data?.message
            : t("notifications.success.main-sales-area-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(
        mainSalesArea?.data?.message
          ? mainSalesArea?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/sale-area/main-sales-area/list");
  };
  const fetchMainSaleArea = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const mainSaleArea = await services.getMainSaleAreaById(id);
        Object.keys(mainSaleArea.data).forEach((key) => {
          if (key === "coordinates" && mainSaleArea?.data[key]?.length > 0) {
            setIsActiveDraw(false);
          }
          setValue(key, mainSaleArea.data[key]);
        });
      } catch (e) {
        navigate("/sale-area/main-sales-area/list");
        toast.error(t("notifications.error.main-sale-area-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    reset();

    if (id) {
      fetchMainSaleArea();
    }
  }, [id]);

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });

  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id
                    ? t("titles.main-sales-area-edit")
                    : t("titles.main-sales-area-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.main-sales-area-edit")
                    : t("descriptions.main-sales-area-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <CustomInput
                      label={t("sales.main-sales-area-name")}
                      register={register}
                      name="name"
                      errors={errors}
                      placeholder={t("sales.main-sales-area-name")}
                    />
                  </div>
                  <div className="sm:col-span-full flex flex-row justify-between">
                    <div>
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("labels.is-active")}
                      </label>
                      <div className="mt-2">
                        <Switch
                          checked={isActive}
                          onChange={(event) =>
                            setValue("is_active", event.target.checked)
                          }
                          color="teal"
                          size="lg"
                          thumbIcon={
                            isActive ? (
                              <IconCheck
                                style={{ width: rem(12), height: rem(12) }}
                                color="green"
                                stroke={3}
                              />
                            ) : (
                              <IconX
                                style={{ width: rem(12), height: rem(12) }}
                                color="red"
                                stroke={3}
                              />
                            )
                          }
                        />
                      </div>
                    </div>
                    {!id && (
                      <div className="">
                        <Button
                          onClick={() => {
                            polygonRef?.current?.setMap(null);
                            setIsActiveDraw(true);
                          }}
                        >
                          Haritayı Sıfırla
                        </Button>
                      </div>
                    )}
                  </div>
                  <div className="sm:col-span-full mt-5">
                    <PolygonDrawer
                      drawingManagerRef={drawingManagerRef}
                      value={coordinates}
                      coordinates={coordinates}
                      isUpdate={id}
                      isActive={isActiveDraw}
                      setCoordinatesRaw={(coords) => {
                        setValue("coordinates", coords);
                      }}
                      setCoordinates={(polygon) => {
                        const coords = polygon
                          .getPath()
                          .getArray()
                          .map((coord) => {
                            return { lat: coord.lat(), lng: coord.lng() };
                          });
                        polygonRef.current = polygon;
                        polygon.setOptions({
                          drawingControl: false,
                        });
                        setIsActiveDraw(false);
                        setValue("coordinates", coords);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={
                  id
                    ? updateMainSalesArea.isLoading
                    : addMainSalesArea.isLoading
                }
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default MainSalesAreaCreate;
