import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import BarLoading from "../../components/Loading/BarLoading";
import { Badge, Button } from "@mantine/core";
import { config } from "../../config";
import { useTranslation } from "react-i18next";

const StorageOrderDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    isLoading,
    data,
    refetch: refetchDetail,
  } = useQuery({
    queryKey: ["orders", id],
    enabled: !!id,
    queryFn: async () => {
      return await services.getOrderById(id).then((res) => res.data);
    },
  });

  return (
    <div className="flex flex-col h-screen max-h-full">
      <div>
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:text-3xl sm:truncate">
              {t("titles.storage-order-detail")}
            </h2>
          </div>
          <div className="mt-4 flex md:mt-0 md:ml-4">
            <button
              type="button"
              onClick={() => navigate("/storage/orders")}
              className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-gray-500 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
            >
              {t("actions.back")}
            </button>
          </div>
        </div>
        {isLoading ? (
          <BarLoading />
        ) : (
          <div className="mt-4">
            <div className="bg-white dark:bg-gray-800 overflow-hidden shadow rounded-lg lg:divide-x lg:divide-y-0 divide-y  dark:divide-gray-100/30 divide-gray-100 grid grid-cols-1 lg:grid-cols-2">
              <div className="px-4 py-5 sm:px-6 flex flex-col gap-2">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white">
                    {t("storage.order-information")}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
                    {t("storage.order-information-desc")}
                  </p>
                </div>

                <div className="mt-6">
                  <dl className="grid grid-cols-1 gap-x-2 gap-y-3 md:grid-cols-1">
                    <div className="sm:col-span-1">
                      <div className="flex items-center justify-between">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          {t("storage.order-id")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                          {id}
                        </dd>
                      </div>
                    </div>
                    <div className="sm:col-span-1">
                      <div className="flex items-center justify-between">
                        <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                          {t("navigationItems.storage")}
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                          {data.storage.name}
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>

                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-4">
                    {t("titles.salespots")}
                  </h3>
                  <div className="mt-2">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                      <div className=" gap-2 flex flex-col w-full">
                        {data.sale_spots.map((saleSpot) => (
                          <div className="flex items-center justify-between">
                            <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                              {saleSpot.sale_spot._id} -{" "}
                              {saleSpot.sale_spot.location_description}
                            </dt>
                            <dd className="mt-2 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                              <Button
                                size="xs"
                                color="#1E44FF"
                                onClick={() =>
                                  window.open(
                                    `http://maps.google.com.tr/maps?q=${saleSpot.sale_spot.location.lat},${saleSpot.sale_spot.location.lng}`
                                  )
                                }
                              >
                                {t("actions.go-to-location")}
                              </Button>
                            </dd>
                          </div>
                        ))}
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-white mt-4">
                  {t("titles.product")}
                </h3>
                <div className="mt-2">
                  <ul className="divide-y divide-gray-100">
                    {data.totalRequiredProducts.map((totalProduct) => (
                      <li
                        key={totalProduct.id}
                        className="relative flex  flex-col xl:flex-row justify-between  md:gap-x-6 gap-x-2 gap-y-2 py-5"
                      >
                        <div className="flex min-w-0 gap-x-4">
                          <img
                            className="h-12 w-12 flex-none rounded-full bg-gray-50"
                            src={
                              totalProduct?.product?.image
                                ? config.CDN_URL + totalProduct.product.image
                                : "https://via.placeholder.com/300"
                            }
                            alt=""
                          />
                          <div className="min-w-0 flex-auto">
                            <p className="text-sm font-semibold leading-6 text-gray-900 dark:text-white">
                              {totalProduct.product.name}
                            </p>
                            <p className="mt-1 flex text-xs leading-5 text-gray-500">
                              {totalProduct.product.description}
                            </p>
                          </div>
                        </div>
                        <div className="flex shrink-0 items-center gap-x-4">
                          <div className="hidden sm:flex sm:flex-col sm:items-end">
                            <div className="mt-1 flex items-center gap-x-1.5">
                              <Badge
                                color="orange"
                                variant="light"
                                className="text-xs"
                              >
                                {t("labels.order-amount")}:
                                {totalProduct.quantity}
                              </Badge>
                              <Badge
                                color="orange"
                                variant="light"
                                className="text-xs"
                              >
                                {t("labels.in-the-warehouse-quantity")}:
                                {totalProduct.storage_active_quantity}
                              </Badge>
                            </div>
                          </div>
                        </div>
                      </li>
                      // <div className="flex items-center justify-between">
                      //   <div key={totalProduct.id} className="group relative">
                      //     <div className="w-full h-full flex justify-center items-center">
                      //       <Image
                      //         radius="md"
                      //         h={"auto"}
                      //         className="max-h-[200px] w-full"
                      //         w="100%"
                      //         fit="cover"

                      //       />
                      //     </div>
                      //     <div className="mt-1 text-sm font-medium text-gray-900 flex items-center justify-center">
                      //       <Badge color="blue" variant="light">
                      //         SİPARİŞ MİKTARI : {totalProduct.quantity}
                      //       </Badge>{" "}
                      //       -{" "}
                      //       <Badge color="blue" variant="light">
                      //         DEPODA BULUNAN :
                      //         {totalProduct.storage_active_quantity}
                      //       </Badge>
                      //     </div>

                      //     <h3 className="mt-4 text-sm text-gray-700">
                      //       <span className="absolute inset-0" />
                      //       {totalProduct.product.name}
                      //     </h3>
                      //     <p className="mt-1 text-sm text-gray-500">
                      //       {totalProduct.product.description}
                      //     </p>
                      //   </div>
                      /* <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                            {totalProduct.product._id} -{" "}
                            {totalProduct.product.name}
                          </dt>
                          <dd className="mt-2 text-sm text-gray-900 dark:text-white sm:mt-0 sm:col-span-2">
                            {totalProduct.quantity}-
                            {totalProduct.storage_active_quantity}
                          </dd> */
                      // </div>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageOrderDetail;
