import React from "react";
import PopoverWrapper from "./PopoverWrapper";
import { Grid, SimpleGrid } from "@mantine/core";
import { useTranslation } from "react-i18next";

const SlotItem = ({
  vIndex,
  hIndex,
  slot,
  setTransaction,
  total_horizontal_count,
  transaction,
}) => {
  const { t } = useTranslation();
  const handleSlotComponent = (type) => {
    if (
      transaction.is_selected &&
      transaction.hIndex === hIndex &&
      transaction.vIndex === vIndex &&
      transaction.type !== "BLOCK"
    ) {
      return <SelectedElement />;
    }

    switch (type) {
      case "EMPTY":
        return <EmptyComponent />;
      case "PRODUCT":
        return <ProductComponent />;
      case "CLOSED":
        return <BlockedComponent />;
    }
  };

  const SelectedElement = () => {
    return (
      <div>
        <div className="bg-blue-400 rounded-md text-center lg:flex md:hidden xs:hidden text-sm transition-all h-[80px] flex items-center justify-center">
          {t("actions.selected").toUpperCase()} -{" "}
          {vIndex * total_horizontal_count + hIndex + 1}
        </div>
        <div className="bg-blue-400 hidden lg:hidden md:flex xs:flex text-sm transition-all h-[80px] items-center justify-center">
          {vIndex * total_horizontal_count + hIndex + 1}
        </div>
      </div>
    );
  };

  const setTransactionHandler = (type) => {
    setTransaction({
      is_selected: true,
      type: type, // ['UPDATE','REMOVE','ADD']
      product: slot.product || "",
      slot: slot,
      hIndex: hIndex,
      vIndex: vIndex,
    });
  };

  const ProductComponent = () => {
    return (
      <PopoverWrapper
        slot={slot}
        vIndex={vIndex}
        hIndex={hIndex}
        deleteProduct={() => setTransactionHandler("REMOVE")}
        updateProduct={() => setTransactionHandler("UPDATE")}
      >
        <div
          id={`${vIndex}-${hIndex}`}
          className="bg-green-300 text-black border-[5px] rounded-md border-green-400 text-sm hover:bg-green-400 transition-all min-h-[80px] h-[80px] cursor-pointer flex items-center justify-center"
        >
          <div className="flex lg:flex md:hidden xs:hidden">
            <SimpleGrid spacing={0} cols={2} className="h-full">
              <div className="flex items-center border-r-[3px] border-b-[3px] border-green-600 p-1  justify-center">
                {vIndex * total_horizontal_count + (hIndex + 1)}
              </div>
              <div className="flex items-center p-1 border-b-[3px] border-green-600 justify-center">
                {slot.current_stock}
              </div>
            </SimpleGrid>
          </div>
          <div
            id={`${vIndex}-${hIndex}`}
            className="hidden lg:hidden md:flex xs:flex"
          >
            {vIndex * total_horizontal_count + hIndex + 1}
          </div>
        </div>
      </PopoverWrapper>
    );
  };

  const EmptyComponent = () => {
    return (
      <PopoverWrapper
        slot={slot}
        blockSlot={() => setTransactionHandler("BLOCK")}
        addProduct={() => setTransactionHandler("ADD")}
      >
        <div>
          <div
            id={`${vIndex}-${hIndex}`}
            className="bg-red-400 rounded-md lg:flex md:hidden xs:hidden text-sm hover:bg-red-500 transition-all cursor-pointer h-[80px] flex items-center justify-center"
          >
            {t("actions.empty-key").toUpperCase()} -{" "}
            {vIndex * total_horizontal_count + (hIndex + 1)}
          </div>
          <div
            id={`${vIndex}-${hIndex}`}
            className="bg-red-400 hidden lg:hidden md:flex xs:flex text-sm hover:bg-red-500 transition-all cursor-pointer h-[80px] flex items-center justify-center"
          >
            {vIndex * total_horizontal_count + hIndex + 1}
          </div>
        </div>
      </PopoverWrapper>
    );
  };

  const BlockedComponent = () => {
    return (
      <PopoverWrapper
        slot={slot}
        blockSlot={() => setTransactionHandler("BLOCK")}
      >
        <div className="cursor-pointer">
          <div className="bg-gray-400 rounded-md lg:flex md:hidden xs:hidden text-sm transition-all h-[80px] flex items-center justify-center">
            K - {vIndex * total_horizontal_count + (hIndex + 1)}
          </div>
          <div className="bg-gray-400 hidden lg:hidden md:flex xs:flex text-sm transition-all h-[80px] items-center justify-center">
            {vIndex * total_horizontal_count + hIndex + 1}
          </div>
        </div>
      </PopoverWrapper>
    );
  };

  return handleSlotComponent(slot.type);
};

export default SlotItem;
