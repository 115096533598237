import { useMutation } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../../components/Loading/BarLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import { CategoryCreateAndUpdate } from "../validations";
import { Switch } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";
import { rem } from "@mantine/core";
import SaveButton from "../../../components/Buttons/SaveButton";

const StorageCategoryCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(CategoryCreateAndUpdate),
  });

  const addStorageCategory = useMutation({
    mutationFn: (newStorageCategory) =>
      services.createStorageCategory(newStorageCategory),
  });
  const updateStorageCategory = useMutation({
    mutationFn: (newStorageCategory) =>
      services.updateStorageCategory(id, newStorageCategory),
  });

  const onSubmit = async (data) => {
    let newStorageCategoryPayload;
    let storageCategory;

    try {
      newStorageCategoryPayload = {
        ...data,
      };
      if (!id) {
        storageCategory = await addStorageCategory.mutateAsync(
          newStorageCategoryPayload
        );
      } else {
        storageCategory = await updateStorageCategory.mutateAsync(
          newStorageCategoryPayload
        );
      }

      if (!id && storageCategory) {
        toast.success(
          storageCategory?.data?.message
            ? storageCategory?.data?.message
            : t("notifications.success.storage-category-create"),
          {
            duration: 3000,
          }
        );

        reset();
        handleCancel();
      } else if (id && storageCategory) {
        toast.success(
          storageCategory?.data?.message
            ? storageCategory?.data?.message
            : t("notifications.success.storage-category-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(
        storageCategory?.data?.message
          ? storageCategory?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/storage/category/list");
  };

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });

  const fetchStorageCategory = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const storageCategory = await services.getStorageCategoryById(id);
        Object.keys(storageCategory.data).forEach((key) => {
          setValue(key, storageCategory.data[key]);
        });
      } catch (e) {
        navigate("/storage/category/list");
        toast.error(t("notifications.error.storage-category-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    if (id) {
      fetchStorageCategory();
    }
  }, [id]);
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id
                    ? t("titles.storage-category-edit")
                    : t("titles.storage-category-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.storage-category-edit")
                    : t("descriptions.storage-category-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.category-name")}
                    </label>
                    <div className="mt-2">
                      <input
                        {...register("name")}
                        type="text"
                        autoComplete="given-name"
                        placeholder={t("storage.category-name")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm sm:leading-6
                        ${
                          errors.name?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }`}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.name?.message)}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="lg"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.is_active?.message)}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={
                  id
                    ? updateStorageCategory.isLoading
                    : addStorageCategory.isLoading
                }
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default StorageCategoryCreate;
