import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../../components/Loading/BarLoading";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Switch, rem } from "@mantine/core";
import { IconCheck } from "@tabler/icons-react";
import { IconX } from "@tabler/icons-react";
import SaveButton from "../../../components/Buttons/SaveButton";
import { subSalesAreaValidations } from "../validations";
import CustomSelect from "../../../components/CustomSelect/CustomSelect";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Polygon,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { DrawingManager } from "react-google-maps/lib/components/drawing/DrawingManager";
import CustomInput from "../../../components/CustomInput/CustomInput";
const defaultLocation = { lat: 41.015137, lng: 28.97953 };
const mapStyles = require("../../../static/mapStyles.json");

const PolygonDrawer = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyAdo5zJb7qFJWhEzPoUI3HhHWEJfnCICpg",
    loadingElement: <div className="w-full" style={{ height: `100%` }} />,
    containerElement: <div className="w-full" style={{ height: `400px` }} />,
    mapElement: <div className="w-full" style={{ height: `100%` }} />,
  }),

  withScriptjs,
  withGoogleMap
)(
  ({
    value,
    drawingManagerRef,
    isActive = true,
    coordinates,
    isUpdate,
    center,
    ...props
  }) => {
    let polygonRef = useRef(null);
    let polygonRef2 = useRef(null);

    return (
      <GoogleMap defaultOptions={mapStyles} defaultZoom={14} center={center}>
        <DrawingManager
          ref={drawingManagerRef}
          defaultDrawingMode={window.google.maps.drawing.OverlayType.POLYGON}
          onPolygonComplete={(polygon) => {
            props.setCoordinates(polygon);
          }}
          onMapOverlayComplete={() => console.log("OVERLAY INTILIZED")}
          options={{
            drawingControl: isActive,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },
            defaultLocation: coordinates,
            polygonOptions: {
              fillColor: "rgba(217, 119, 6, 0.7)",
              editable: true,
            },
            drawingMode: isActive
              ? window.google.maps.drawing.OverlayType.POLYGON
              : null,
          }}
          defaultOptions={{
            drawingControl: true,
            drawingControlOptions: {
              position: window.google.maps.ControlPosition.TOP_CENTER,
              drawingModes: [window.google.maps.drawing.OverlayType.POLYGON],
            },

            polygonOptions: {
              fillColor: "rgba(217, 119, 6, 0.7)",
              editable: true,
            },
          }}
        />
        {coordinates &&
          coordinates?.length > 0 &&
          coordinates.map((coord, i) => {
            if (coord.name === "MAIN_AREA") {
              return (
                <Polygon
                  ref={polygonRef}
                  key={i}
                  options={{
                    fillColor:
                      coord.name === "MAIN_AREA"
                        ? "rgba(0,0,0,0.4)"
                        : "rgba(217, 119, 6, 0.7)",
                    editable: coord.name === "MAIN_AREA" ? false : true,
                  }}
                  paths={coord.coordinates}
                />
              );
            } else {
              return (
                <Polygon
                  ref={polygonRef2}
                  key={i}
                  onMouseUp={(event) => {
                    if (polygonRef2.current) {
                      const coordinatesNew = polygonRef2.current
                        .getPath()
                        .getArray()
                        .map((latLng) => ({
                          lat: latLng.lat(),
                          lng: latLng.lng(),
                        }));

                      props.setCoordinatesRaw(coordinatesNew);
                    }
                  }}
                  options={{
                    fillColor: "rgba(217, 119, 6, 0.7)",
                    editable: true,
                  }}
                  paths={coord.coordinates}
                />
              );
            }
          })}
      </GoogleMap>
    );
  }
);

const SubSalesAreaCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [allCoordinates, setAllCoordinates] = useState([]);
  const [isActiveDraw, setIsActiveDraw] = useState(true);
  let polygonRef = useRef(null);
  const [center, setCenter] = useState(defaultLocation);

  const {
    isLoading: mainSalesAreasLoading,
    error: mainSalesAreasError,
    data: mainSalesAreasData,
    isSuccess: mainSalesAreasSuccess,
    refetch: mainSalesAreasRefetch,
  } = useQuery({
    queryKey: ["mainSalesAreas"],
    queryFn: async () =>
      await services.mainSalesAreaList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
          coordinates: item.coordinates,
        })),
      ]),
  });

  const {
    isLoading: storagesLoading,
    error: storagesError,
    data: storagesData,
    isSuccess: storagesSuccess,
    refetch: storagesRefetch,
  } = useQuery({
    queryKey: ["storages"],
    queryFn: async () =>
      await services.storageList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(subSalesAreaValidations),
    defaultValues: {
      name: "",
      main_sale_area: "",
      country: null,
      state: "",
      city: "",
      storage: "",
      critic_stock: "",
      is_active: true,
      coordinates: [],
    },
  });

  const addSubSalesArea = useMutation({
    mutationFn: (newSubSalesArea) =>
      services.createSubSalesArea(newSubSalesArea),
  });
  const updateProductBrand = useMutation({
    mutationFn: (newSubSalesArea) =>
      services.updateSubSalesArea(id, newSubSalesArea),
  });

  const onSubmit = async (data) => {
    let newSubSalesAreaPayload;
    let subSalesArea;

    try {
      newSubSalesAreaPayload = {
        ...data,
      };
      if (coordinates?.length < 3) {
        toast.error(t("notifications.error.sub-sales-area-coordinates"), {
          duration: 3000,
        });
        return;
      }
      if (!id) {
        subSalesArea = await addSubSalesArea.mutateAsync(
          newSubSalesAreaPayload
        );
      } else {
        subSalesArea = await updateProductBrand.mutateAsync(
          newSubSalesAreaPayload
        );
      }

      if (!id && subSalesArea) {
        toast.success(
          subSalesArea?.data?.message
            ? subSalesArea?.data?.message
            : t("notifications.success.sub-sales-area-create"),
          {
            duration: 3000,
          }
        );

        reset();
        handleCancel();
      } else if (id && subSalesArea) {
        toast.success(
          subSalesArea?.data?.message
            ? subSalesArea?.data?.message
            : t("notifications.success.sub-sales-area-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(
        subSalesArea?.data?.message
          ? subSalesArea?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const handleCancel = () => {
    reset();
    navigate("/sale-area/sub-sales-area/list");
  };

  const fetchSubSaleArea = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const subSalesArea = await services.getSubSaleAreaById(id);
        Object.keys(subSalesArea.data).forEach((key) => {
          if (key === "coordinates" && subSalesArea?.data[key]?.length > 0) {
            setIsActiveDraw(false);
          }
          setValue(key, subSalesArea.data[key]);
        });
      } catch (e) {
        navigate("/sale-area/sub-sales-area/list");
        toast.error(t("notifications.error.sub-sale-area-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    reset();

    if (id) {
      fetchSubSaleArea();
    }
  }, [id]);

  const mainSaleArea = useWatch({
    control,
    name: "main_sale_area",
  });

  const coordinates = useWatch({
    control,
    name: "coordinates",
  });

  const getCenterOfAPolygon = (coordinates) => {
    if (coordinates) {
      var bounds = new window.google.maps.LatLngBounds();

      const newCoordinates = coordinates?.map((coord) => {
        return new window.google.maps.LatLng(
          parseFloat(coord.lat),
          parseFloat(coord.lng)
        );
      });

      for (let i = 0; i < newCoordinates.length; i++) {
        bounds.extend(newCoordinates[i]);
      }

      setCenter({
        lat: bounds.getCenter().lat(),
        lng: bounds.getCenter().lng(),
      });
    }
  };

  useEffect(() => {
    if (mainSaleArea && !mainSalesAreasLoading) {
      const mainAreaCoordinates = mainSalesAreasData?.find(
        (a) => a.value === mainSaleArea
      )?.coordinates;

      if (mainAreaCoordinates) {
        const allAreas = [
          {
            name: "MAIN_AREA",
            coordinates: mainAreaCoordinates,
          },
        ];
        if (coordinates.length > 0) {
          allAreas.push({
            name: "SUB_AREA",
            coordinates: coordinates,
          });
        }

        setAllCoordinates(allAreas);

        setTimeout(() => {
          getCenterOfAPolygon(mainAreaCoordinates);
        }, 400);
      } else {
        setAllCoordinates([]);
      }
    }
  }, [mainSaleArea, mainSalesAreasLoading]);

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });
  return (
    <>
      {!loading && !mainSalesAreasLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form onSubmit={handleSubmit((data) => onSubmit(data))}>
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                  {id
                    ? t("titles.sub-sales-area-edit")
                    : t("titles.sub-sales-area-create")}
                </h2>
                <p className="mt-1 text-sm leading-6 text-gray-400">
                  {id
                    ? t("descriptions.sub-sales-area-edit")
                    : t("descriptions.sub-sales-area-create")}
                </p>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-3">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("sales.main-sales-area")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="main_sale_area"
                        data={mainSalesAreasData}
                        limit={10}
                        error={errors.main_sale_area?.message}
                        searchable
                        clearable
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.main_sale_area?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("sales.sub-sales-area-name")}
                      register={register}
                      name="name"
                      errors={errors}
                      placeholder={t("sales.sub-sales-area-name")}
                    />
                  </div>

                  <div className="sm:col-span-full">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("sales.storage")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="storage"
                        data={storagesData}
                        limit={10}
                        searchable
                        clearable
                        error={errors.storage?.message}
                        height={"20px"}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.storage?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-full">
                    <CustomInput
                      label={t("sales.critic_stock")}
                      register={register}
                      name="critic_stock"
                      errors={errors}
                      placeholder={t("sales.critic_stock")}
                    />
                  </div>
                  <div className="sm:col-span-full flex flex-row justify-between">
                    <div>
                      <label
                        htmlFor="is_active"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("labels.is-active")}
                      </label>
                      <div className="mt-2">
                        <Switch
                          checked={isActive}
                          onChange={(event) =>
                            setValue("is_active", event.target.checked)
                          }
                          color="teal"
                          size="lg"
                          thumbIcon={
                            isActive ? (
                              <IconCheck
                                style={{ width: rem(12), height: rem(12) }}
                                color="green"
                                stroke={3}
                              />
                            ) : (
                              <IconX
                                style={{ width: rem(12), height: rem(12) }}
                                color="red"
                                stroke={3}
                              />
                            )
                          }
                        />
                      </div>
                    </div>
                    {!id && (
                      <div className="">
                        <Button
                          onClick={() => {
                            polygonRef?.current?.setMap(null);
                            setIsActiveDraw(true);
                          }}
                        >
                          Haritayı Sıfırla
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {allCoordinates.length > 0 && (
              <PolygonDrawer
                coordinates={allCoordinates}
                isActive={isActiveDraw}
                setCoordinatesRaw={(coords) => {
                  setValue("coordinates", coords);
                }}
                center={center}
                setCoordinates={(polygon) => {
                  const coords = polygon
                    .getPath()
                    .getArray()
                    .map((coord) => {
                      return { lat: coord.lat(), lng: coord.lng() };
                    });
                  polygonRef.current = polygon;
                  polygon.setOptions({
                    drawingControl: false,
                  });
                  setIsActiveDraw(false);
                  setValue("coordinates", coords);
                }}
              />
            )}
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={
                  id ? updateProductBrand.isLoading : addSubSalesArea.isLoading
                }
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default SubSalesAreaCreate;
