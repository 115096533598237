import React from "react";
import { Grid } from "@mantine/core";
import SlotItem from "./SlotItem";
import { MinusCircleIcon } from "@heroicons/react/24/outline";

const SlotList = ({
  refetch,
  matrix,
  horizontal_spiral_count,
  vertical_spiral_count,
  transaction,
  onDelete,
  setTransaction,
}) => {
  return (
    <div>
      <Grid grow gutter={4} columns={horizontal_spiral_count + 1}>
        {[...new Array(horizontal_spiral_count + 1)].map(
          (slot, horizontal_index) => {
            return (
              <Grid.Col
                key={horizontal_index}
                span={1}
                className="mb-1 flex items-center justify-center"
              >
                {horizontal_index !== 0 && (
                  <div
                    onClick={() => {
                      onDelete({
                        rotation: "VERTICAL",
                        type: "REMOVE",
                        index: horizontal_index - 1,
                      });
                    }}
                    className="max-w-2 bg-red-400 rounded-full cursor-pointer hover:bg-red-800"
                  >
                    <MinusCircleIcon width={20} color="white" height={20} />
                  </div>
                )}
              </Grid.Col>
            );
          }
        )}
      </Grid>
      {matrix.map((row, vertical_index) => {
        return (
          <div key={vertical_index}>
            <Grid grow gutter={4} columns={horizontal_spiral_count + 1}>
              {[{ type: "MINUS" }, ...row].map((slot, horizontal_index) => {
                if (slot.type === "MINUS") {
                  return (
                    <Grid.Col
                      key={horizontal_index}
                      span={1}
                      className="flex justify-end items-center"
                    >
                      <div
                        onClick={() => {
                          onDelete({
                            rotation: "HORIZONTAL",
                            type: "REMOVE",
                            index: vertical_index,
                          });
                        }}
                        className="bg-red-400 w-5 h-5 rounded-full cursor-pointer hover:bg-red-800"
                      >
                        <MinusCircleIcon width={20} color="white" height={20} />
                      </div>
                    </Grid.Col>
                  );
                } else {
                  return (
                    <Grid.Col span={1} key={horizontal_index} className="mb-1">
                      <SlotItem
                        setTransaction={setTransaction}
                        total_horizontal_count={horizontal_spiral_count}
                        transaction={transaction}
                        vIndex={vertical_index}
                        hIndex={horizontal_index - 1}
                        slot={slot}
                      />
                    </Grid.Col>
                  );
                }
              })}
            </Grid>
          </div>
        );
      })}
    </div>
  );
};

export default SlotList;
