import { axiosInstance } from ".";

export const orderService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `order?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }${search ? `&is_for_select=${true}` : ""}`
      );
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("order", data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(`order/delete/${id}`);
      return response?.data || [];
    },
    async updateOrderStatus(id, status) {
      const response = await axiosInstance.get(
        `order/status/${id}?status=${status}`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`order/${id}`);
      return response?.data || [];
    },
  };
};
