import { Button, Modal, NumberInput } from "@mantine/core";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import services from "../../services";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useWatch } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import CustomSelect from "../CustomSelect/CustomSelect";
import BarLoading from "../Loading/BarLoading";

const AddStorageProductModal = ({
  isOpen,
  onClose,
  onSend,
  title,
  text,
  id,
}) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,
    formState: { errors },
  } = useForm({});

  const addStorageProduct = useMutation({
    mutationFn: (newStorageProduct) =>
      services.addProductToStorage(newStorageProduct),
  });

  const {
    isLoading,
    error,
    data: productData,
    isFetching,
  } = useQuery({
    queryKey: ["products"],
    queryFn: async () =>
      await services.products(1, 400).then((res) => {
        const data = res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        }));
        return data;
      }),
    enabled: isOpen,
  });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit = async (data) => {
    let newStorageProductPayload;
    let storageProduct;

    try {
      newStorageProductPayload = {
        ...data,
        storage: id,
      };
      storageProduct = await addStorageProduct.mutateAsync(
        newStorageProductPayload
      );

      if (storageProduct) {
        toast.success(
          storageProduct?.data?.message
            ? storageProduct?.data?.message
            : t("notifications.success.storage-product-added"),
          {
            duration: 3000,
          }
        );
        onSend(data);

        reset();
      }
    } catch (e) {
      toast.error(
        storageProduct?.data?.message
          ? storageProduct?.data?.message
          : t("notifications.error.message"),
        {
          duration: 3000,
        }
      );
    }
  };

  const maxStock = useWatch({
    control,
    name: "max_stock",
    defaultValue: 0,
  });

  const minStock = useWatch({
    control,
    name: "min_stock",
    defaultValue: 0,
  });

  const product = useWatch({
    control,
    name: "product",
    defaultValue: null,
  });

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      centered
      classNames={{
        root: "dark:!bg-slate-700 !bg-gray-200",
        header: "dark:!bg-slate-700 !bg-gray-200",
        content: "!rounded-xl dark:!bg-slate-700 !bg-gray-200",
        title: "!text-md !font-semibold ",
        close: "dark:!text-white dark:!bg-gray-800 dark:hover:!bg-gray-800/70",
      }}
      title={title || "Add Storage Product"}
      overlayProps={{
        backgroundOpacity: 0.45,
        blur: 2,
        color: "#1E44FF",
      }}
    >
      <div>
        {isLoading ? (
          <BarLoading />
        ) : (
          <div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                <div className="sm:col-span-full">
                  <div className="mt-2 mb-2">
                    <label className="mt-2 text-sm" htmlFor="name">
                      {t("labels.product")}
                    </label>
                    <CustomSelect
                      control={control}
                      name="product"
                      data={productData}
                    />

                    {errors.product && (
                      <span className="text-xs text-red-500">
                        {t("form-validations.required")}
                      </span>
                    )}
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="horizontal_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.min-stock")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={minStock}
                        placeholder={t("storage.min-stock")}
                        onChange={(event) => {
                          setValue("min_stock", event);
                        }}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800  !bg-gray-300/70 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 !ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full`,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.minStock?.message}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="vertical_spiral_count"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.max-stock")}
                    </label>
                    <div className="mt-2">
                      <NumberInput
                        value={maxStock}
                        onChange={(event) => {
                          setValue("max_stock", event);
                        }}
                        placeholder={t("storage.max-stock")}
                        hideControls
                        classNames={{
                          input: `dark:!bg-gray-800  !bg-gray-300/70 dark:!text-white !text-slate-700 !ring-1 !border-none !rounded-md ring-inset dark:!placeholder-white/30 !placeholder-slate-700 !ring-white/5 ! focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 !w-full`,
                          wrapper:
                            "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                          dropdown:
                            "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                          option: "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                        }}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {errors.max_stock?.message}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex w-full justify-end mt-2 gap-2">
                <Button
                  variant="outline"
                  color="#1E44FF"
                  onClick={() => handleClose()}
                  radius={"md"}
                >
                  {t("actions.cancel")}
                </Button>
                <Button
                  disabled={!minStock && !maxStock && !product ? true : false}
                  variant="filled"
                  color="#1E44FF"
                  type="submit"
                  radius={"md"}
                >
                  {t("actions.confirm")}
                </Button>
              </div>
            </form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default AddStorageProductModal;
