import * as yup from "yup";
const validations = yup.object().shape({
  email: yup
    .string()
    .email("validationMessages.email")
    .required("validationMessages.required"),
  password: yup
    .string()
    .min(8, "validationMessages.password")
    .max(20, "validationMessages.max-length"),
  name: yup.string().required("validationMessages.required"),
  last_name: yup.string().required("validationMessages.required"),
  phone_number: yup.string().required("validationMessages.required"),
  phone_country_code: yup.string().required("validationMessages.required"),
  company_id: yup.string().required("validationMessages.required"),
  roles: yup.string().required("validationMessages.required"),
  gender: yup.string().required("validationMessages.required"),
  birth_date: yup.string().required("validationMessages.required"),
});

export default validations;
