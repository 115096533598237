import { Button, Popover, Tooltip } from "@mantine/core";
import { IconFilter } from "@tabler/icons-react";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import CustomDateInput from "../../components/CustomDateInput/CustomDateInput";
import dayjs from "dayjs";

const FilterPopover = ({
  onSubmit,

  openedPopover,
  setOpenedPopover,
  errors,
  setValue,

  start_date,
  end_date,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex justify-end gap-2 mt-4">
      <Popover
        width={300}
        trapFocus
        position="bottom"
        opened={openedPopover}
        onChange={setOpenedPopover}
        withArrow
        shadow="md"
      >
        <Popover.Target>
          <Tooltip label={t("actions.filter")} position="top">
            <Button
              type="button"
              size="xs"
              radius={"md"}
              color={"gray"}
              onClick={() => setOpenedPopover((o) => !o)}
            >
              <div className="flex items-center justify-start gap-2">
                <IconFilter />
                <span>{t("actions.filter")}</span>
              </div>
            </Button>
          </Tooltip>
        </Popover.Target>
        <Popover.Dropdown className="p-4  dark:bg-slate-800 bg-white rounded-lg shadow-lg ">
          <form onSubmit={onSubmit}>
            <div className="flex flex-col gap-4">
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <CustomDateInput
                    value={start_date}
                    locale={"tr"}
                    popoverProps={{ withinPortal: false }}
                    valueFormat={"DD/MM/YYYY"}
                    onChange={(value) => {
                      setValue("start_date", dayjs(value).toDate());
                    }}
                    errors={errors}
                    label={t("labels.start-date")}
                    placeholder={t("placeholders.select")}
                  />
                </div>
              </div>
              <div className="sm:col-span-3">
                <div className="mt-2">
                  <CustomDateInput
                    value={end_date}
                    locale={"tr"}
                    popoverProps={{ withinPortal: false }}
                    valueFormat={"DD/MM/YYYY"}
                    onChange={(value) => {
                      setValue("end_date", dayjs(value).toDate());
                    }}
                    errors={errors}
                    label={t("labels.end-date")}
                    placeholder={t("placeholders.select")}
                  />
                </div>
              </div>

              <Button
                type="submit"
                classNames={{
                  root: "!bg-orange-500 hover:!bg-orange-600 dark:!bg-orange-500 dark:hover:!bg-orange-600 !w-full",
                }}
              >
                {t("actions.save")}
              </Button>
            </div>
          </form>
        </Popover.Dropdown>
      </Popover>
    </div>
  );
};

export default FilterPopover;
