import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Input, Tooltip, rem } from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons-react";

import { Switch } from "@mantine/core";

import SaveButton from "../../components/Buttons/SaveButton";
import MapPicker from "react-google-map-picker";
import { StorageCreateValidations } from "./validations";
import {
  setKey,
  setLanguage,
  setRegion,
  setLocationType,
  fromLatLng,
} from "react-geocode";
import { IMaskInput } from "react-imask";
import { yupResolver } from "@hookform/resolvers/yup";
import { config } from "../../config";
import CustomInput from "../../components/CustomInput/CustomInput";
const DefaultZoom = 10;
const DefaultLocation = { lat: 41.015137, lng: 28.97953 };
setKey(config.MAP_API_KEY);
setLanguage("tr");
setRegion("tr_TR");
setLocationType("ROOFTOP");
const StorageCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [mapLoading, setMapLoading] = useState(true);

  const [location, setLocation] = useState(null);
  const [zoom, setZoom] = useState(DefaultZoom);

  const handleChangeLocation = (lat, lng) => {
    setLocation({ lat: lat, lng: lng });
    setValue("latitude", lat);
    setValue("longitude", lng);
  };

  useEffect(() => {
    setTimeout(() => {
      setMapLoading(false);
    }, 500);
  }, []);

  const handleChangeZoom = (newZoom) => {
    setZoom(newZoom);
  };

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(StorageCreateValidations),
    defaultValues: {
      is_active: true,
      category: "",
      address: "",
      description: "",
    },
  });

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });
  const city = useWatch({
    control,
    name: "city",
    defaultValue: "",
  });
  const country = useWatch({
    control,
    name: "country",
    defaultValue: "",
  });
  const state = useWatch({
    control,
    name: "state",
    defaultValue: "",
  });
  const address = useWatch({
    control,
    name: "address",
    defaultValue: "",
  });
  const phone_number = useWatch({
    control,
    name: "contact_phone_number",
    defaultValue: "",
  });
  const longitude = useWatch({
    control,
    name: "longitude",
    defaultValue: DefaultLocation.lng,
  });
  const latitude = useWatch({
    control,
    name: "latitude",
    defaultValue: DefaultLocation.lat,
  });

  const postal_code = useWatch({
    control,
    name: "post_code",
    defaultValue: "",
  });

  const addStorage = useMutation({
    mutationFn: (newStorage) => services.createStorage(newStorage),
  });
  const updateStorage = useMutation({
    mutationFn: (newStorage) => services.updateStorage(id, newStorage),
  });

  const { isLoading: categoryLoading, data: categoryData } = useQuery({
    queryKey: ["categories"],
    queryFn: async () =>
      await services.storageCategoryList(1, 100).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
  });
  const {
    isLoading: codeLoading,
    error: codeError,
    data: codeData,
  } = useQuery({
    cache: Infinity,
    queryKey: ["phoneCodes"],
    queryFn: () =>
      services.phoneCodes().then((res) => {
        const code = new Map();
        const codes = res.data.filter((item) => {
          if (!code.has(item.value)) {
            code.set(item.value, true);
            return true;
          }
          return false;
        });
        return codes;
      }),
  });

  const handleCancel = () => {
    reset();
    navigate("/storage/list");
  };

  const onSubmit = async (data) => {
    let newStoragePayload;
    let storage;

    try {
      newStoragePayload = {
        ...data,
        latitude: location?.lat.toString(),
        longitude: location?.lng.toString(),
      };

      if (!id) {
        storage = await addStorage.mutateAsync(newStoragePayload);
      } else {
        storage = await updateStorage.mutateAsync(newStoragePayload);
      }

      if (!id && storage) {
        toast.success(
          storage?.data?.message
            ? storage?.data?.message
            : t("notifications.success.storage-create"),
          {
            duration: 3000,
          }
        );

        handleCancel();
      } else if (id && storage) {
        toast.success(
          storage?.data?.message
            ? storage?.data?.message
            : t("notifications.success.storage-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(e.response.data.message || t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const fetchStorage = useCallback(async () => {
    if (id) {
      setLoading(true);

      try {
        const storage = await services.getStorageById(id);
        Object.keys(storage.data).forEach((key) => {
          if (key === "latitude" || key === "longitude") {
            setLocation({
              lat: parseFloat(storage.data.latitude),
              lng: parseFloat(storage.data.longitude),
            });
            setValue(key, storage.data[key]);
          }
          setValue(key, storage.data[key]);
          setLoading(false);
        });
      } catch (e) {
        navigate("/storage");
        if (id !== undefined) {
          toast.error(t("notifications.error.storage-notfound"), {
            duration: 3000,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getLocation = async (location) => {
    fromLatLng(location.lat, location.lng).then(
      (response) => {
        const address = response.results[0].formatted_address;

        let state, country, city, postal_code;

        for (
          let i = 0;
          i < response.results[0].address_components.length;
          i++
        ) {
          for (
            let j = 0;
            j < response.results[0].address_components[i].types.length;
            j++
          ) {
            switch (response.results[0].address_components[i].types[j]) {
              case "administrative_area_level_1":
                city = response.results[0].address_components[i].long_name;
                break;
              case "administrative_area_level_2":
                state = response.results[0].address_components[i].long_name;
                break;
              case "country":
                country = response.results[0].address_components[i].long_name;
                break;
              case "postal_code":
                postal_code =
                  response.results[0].address_components[i].long_name;
            }
          }
        }

        if (response.status === "ZERO_RESULTS") {
          toast.error(t("notifications.error.map-notfound"), {
            duration: 3000,
          });
        } else if (response.status === "OK") {
          setValue("country", country);

          setValue("state", state);
          setValue("city", city);
          setValue("post_code", postal_code);
          setValue("address", address);
          setZoom(15);
        }
      },
      (error) => {
        if (error) {
          toast.error(t("notifications.error.map-notfound"), {
            duration: 3000,
          });
        }
      }
    );
  };

  useEffect(() => {
    if (id) {
      fetchStorage();
    }
  }, [id]);

  useEffect(() => {
    getLocation({
      lat: parseFloat(latitude || location?.lat || DefaultLocation.lat),
      lng: parseFloat(longitude || location?.lng || DefaultLocation.lng),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <>
      {!categoryLoading && !loading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                      {id
                        ? t("titles.storage-edit")
                        : t("titles.storage-create")}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      {id
                        ? t("descriptions.storage-edit")
                        : t("descriptions.storage-create")}
                    </p>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-xs font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="md"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-3">
                    <CustomInput
                      register={register}
                      errors={errors}
                      name="name"
                      placeholder={t("storage.name")}
                      label={t("storage.name")}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <CustomInput
                      register={register}
                      errors={errors}
                      name="code"
                      placeholder={t("storage.code")}
                      label={t("storage.code")}
                    />
                  </div>

                  <div className="sm:col-span-3 flex-row flex items-start  gap-4 flex-nowrap">
                    {!codeLoading && !codeError && (
                      <div className="w-[40%] flex flex-col">
                        <label
                          htmlFor="phone-code"
                          className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                        >
                          {t("user.phone-code")}
                        </label>
                        <div className="mt-2">
                          <CustomSelect
                            control={control}
                            placeholder={t("placeholders.select")}
                            data={codeData}
                            name="contact_phone_country_code"
                            searchable
                            height={"20px"}
                          />
                        </div>
                      </div>
                    )}

                    <div className="sm:col-span-3 w-full">
                      <label
                        htmlFor="contact_phone_number"
                        className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                      >
                        {t("storage.contact-phone-number")}
                      </label>
                      <div className="mt-2">
                        <Input
                          component={IMaskInput}
                          mask="(000) 000-00-00"
                          type="text"
                          unmask={true}
                          name="contact_phone_number"
                          id="contact_phone_number"
                          placeholder={t("storage.contact-phone-number")}
                          autoComplete="contact_phone_number"
                          value={phone_number}
                          onAccept={(e) => {
                            setValue("contact_phone_number", e);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.contact_phone_number?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper: "!bg-gray-800 rounded-md !text-white",
                            dropdown:
                              "!bg-white/5 !bg-gray-300/100   !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option: "hover:bg-gray-700",
                          }}
                        />
                      </div>
                      <p className="mt-2 ml-1 text-sm text-red-600">
                        {t(errors.contact_phone_number?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("storage.category")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="category"
                        data={categoryData}
                        searchable
                        clearable
                        height={"20px"}
                        error={errors.category?.message}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.category?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="storage-country"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.country")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          {...register("country")}
                          type="text"
                          disabled={true}
                          autoComplete="storage-country"
                          placeholder={t("storage.country")}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.country?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.country?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="storage-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.state")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          {...register("state")}
                          type="text"
                          autoComplete="storage-state"
                          disabled={true}
                          placeholder={t("storage.state")}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.state?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.state?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="storage-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.city")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          {...register("city")}
                          type="text"
                          disabled={true}
                          autoComplete="storage-city"
                          placeholder={t("storage.city")}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.city?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.city?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="storage-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.postal-code")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          {...register("post_code")}
                          type="text"
                          disabled={true}
                          autoComplete="storage-post-code"
                          placeholder={t("storage.postal-code")}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.post_code?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.post_code?.message)}
                      </p>
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("storage.long")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          disabled={true}
                          value={longitude || location?.lng}
                          placeholder={t("storage.long")}
                          onChange={(event) => {
                            setValue("longitude", event.target.value);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.longitude?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.longitude?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("storage.lat")}
                    </label>
                    <div className="mt-2">
                      <Tooltip label={t("descriptions.changeMap")}>
                        <Input
                          disabled={true}
                          value={latitude || location?.lat}
                          placeholder={t("storage.lat")}
                          onChange={(event) => {
                            setValue("latitude", event.target.value);
                          }}
                          classNames={{
                            input: `dark:!bg-gray-800 !bg-gray-300/100   !ring-1 !border-none !rounded-md ring-inset ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                              errors.latitude?.message &&
                              "!ring-red-500 dark:!ring-red-500"
                            }}`,
                            wrapper:
                              "dark:!bg-gray-800 !bg-gray-300/100 rounded-md dark:!text-white !text-slate-700",
                            dropdown:
                              "dark:!bg-gray-800 !bg-gray-300/100 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                            option:
                              "hover:dark:bg-gray-700 hover:bg-gray-200/50",
                          }}
                        />
                      </Tooltip>
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.longitude?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.address")}
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("address")}
                        type="text"
                        value={address}
                        name="address"
                        onChange={(event) => {
                          setValue("address", event.target.value);
                        }}
                        autoComplete="address"
                        placeholder={t("storage.address")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm text-sm sm:leading-6 
                        ${
                          errors.address?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
  
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.address?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.address-explanation")}
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("address_explanation")}
                        type="text"
                        autoComplete="address-explanation"
                        placeholder={t("storage.address-explanation")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm text-sm sm:leading-6
                        ${
                          errors.address_explanation?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.address_explanation?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("storage.map")}
                    </label>
                    <div className="mt-2">
                      {mapLoading ? (
                        <BarLoading />
                      ) : (
                        <MapPicker
                          defaultLocation={location || DefaultLocation}
                          zoom={zoom}
                          mapTypeId="roadmap"
                          style={{ height: "700px" }}
                          onChangeLocation={handleChangeLocation}
                          onChangeZoom={handleChangeZoom}
                          apiKey={config.MAP_API_KEY}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateStorage.isLoading : addStorage.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default StorageCreate;
