import Header from "./Header";

const Layout = ({ children }) => {
  const isHomePath = window.location.pathname === "/";
  return (
    <>
      <div className="flex flex-col h-screen">
        <Header />
        <main
          className={`py-8 lg:pl-72 flex flex-1 flex-col  max-w-full ${
            !isHomePath
              ? "overflow-y-scroll"
              : "md:overflow-y-hidden overflow-y-scroll"
          } overflow-x-hidden   h-[100dvh] max-h-full`}
        >
          <div className="px-4 sm:px-6 lg:px-8">{children}</div>
        </main>
      </div>
    </>
  );
};
export default Layout;
