import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ListBox = ({
  selected,
  onChange,
  data,
  headerLabel,
  showAtt,
  name,
  error,
}) => {
  const { t } = useTranslation();
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <Listbox.Label className="block text-sm  font-medium dark:text-white text-slate-700 leading-6">
            {headerLabel}
          </Listbox.Label>
          <div className="mt-2 relative">
            <Listbox.Button
              className={`relative w-full h-[35px] dark:bg-gray-800 bg-gray-300/70 border-0 rounded-md shadow-sm   py-2 px-2 text-left cursor-default ring-inset ring-1 ring-white/10 focus:outline-none focus:ring-1 focus:ring-orange-400 focus:border-orange-400 !text-base md:!text-sm ${
                error && "!ring-red-500 dark:!ring-red-500"
              } `}
            >
              <span className="block truncate dark:text-white text-slate-700">
                {selected?.[showAtt]}
              </span>
              <span
                className={classNames(
                  "absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none",
                  open ? "rotate-180 pl-2" : "rotate-0"
                )}
              >
                <ChevronDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-2 w-full dark:bg-gray-800 bg-gray-300/90  shadow-lg max-h-60 rounded-xl  text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none !text-base md:!text-sm">
                {data?.map((item, index) => (
                  <Listbox.Option
                    key={`${item.value}-${index.toString()}}`}
                    className={({ active, selected }) =>
                      classNames(
                        active
                          ? "dark:text-white text-slate-700 bg-orange-600 dark:bg-orange-800 "
                          : "text-gray-900",

                        "cursor-default select-none relative py-2 pl-4 pr-4 "
                      )
                    }
                    value={item}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected && !active
                              ? "font-bold !text-orange-600 dark:!text-orange-950/110"
                              : "font-normal ",
                            active
                              ? "!text-white !font-bold  "
                              : "dark:text-white text-slate-700",
                            "block truncate "
                          )}
                        >
                          {item?.[name || showAtt]}
                        </span>
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
          {error && (
            <p className="mt-2 text-sm text-red-600" id="email-error">
              {t(error?.message)}
            </p>
          )}
        </>
      )}
    </Listbox>
  );
};

export default ListBox;
