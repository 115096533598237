import * as yup from "yup";
const validations = yup.object().shape({
  name: yup.string().required("validationMessages.required"),
  code: yup.string().required("validationMessages.required"),
  address: yup.string().required("validationMessages.required"),
  contact_phone_number: yup.string().required("validationMessages.required"),
  category: yup.string().required("validationMessages.required"),
  city: yup.string().required("validationMessages.required"),
  state: yup.string().required("validationMessages.required"),
  country: yup.string().required("validationMessages.required"),
  post_code: yup.string().required("validationMessages.required"),
  longitude: yup.string().required("validationMessages.required"),
  latitude: yup.string().required("validationMessages.required"),
});

export default validations;
