import { useParams } from "react-router";
import services from "../../services";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { ShowcaseSkeleton } from "../../components/Skeleton";
import SlotList from "../../components/Showcase/SlotList";
import { Badge, Button } from "@mantine/core";
import { useState } from "react";
import ShowcaseProduct from "./ShowcaseProduct";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import { Tooltip } from "@mantine/core";
import ShowcaseDetailModal from "../../components/ShowcaseDetailModal/ShowcaseDetailModal";

const ShowcaseTransactions = ({ outsourceId }) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [isOpen,setIsOpen] = useState(false)
  const [confirmModal, setConfirmModal] = useState({
    isOpen: false,
    title: "",
    confirmText: "",
  });
  const [spiralStatus, setSpiralStatus] = useState({
    type: "", // ['ADD','REMOVE']
    rotation: "", // ['HORIZONTAL','VERTICAL']
    index: 0,
  });
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [sendBody, setSendBody] = useState(undefined);
  const [transaction, setTransaction] = useState({
    is_selected: false,
    type: "", // ['UPDATE','REMOVE','ADD']
    product: null,
    slot: null,
    hIndex: null,
    vIndex: null,
  });
  const [newUpperTransaction, setUpperNewTransaction] = useState({
    min_stock: 0,
    max_stock: 0,
    product: "",
  });
  const {
    isLoading: showcaseLoading,
    error: showcaseError,
    data: showcaseData,
    isSuccess: showcaseSuccess,
    refetch: showcaseRefetch,
  } = useQuery({
    queryKey: ["showcase", outsourceId || id],
    queryFn: async () =>
      await services.getShowcaseById(outsourceId || id).then((res) => res.data),
  });

  const onConfirm = async () => {
    setIsConfirmModalOpen(false);

    if (spiralStatus.type) {
      spiralTransaction();
    } else if (sendBody !== undefined) {
      services
        .showCaseProductTransaction(
          outsourceId || id,
          transaction.type === "REMOVE" || transaction.type === "BLOCK"
            ? {
                vertical_index: String(transaction.vIndex),
                horizontal_index: String(transaction.hIndex),
                showcase_type: outsourceId ? "SHOWCASE" : "TEMPLATE",
                type: transaction.type,
                product: transaction.product?._id,
                current_stock: "0",
                capacity: "0",
              }
            : {
                ...sendBody,
                showcase_type: outsourceId ? "SHOWCASE" : "TEMPLATE",
                vertical_index: String(sendBody.vertical_index),
                horizontal_index: String(sendBody.horizontal_index),
                current_stock: "0",
                capacity: String(sendBody.capacity),
              }
        )
        .then(({ data }) => {
          toast.success(data.message);
          setSendBody(undefined);
          discardTransaction(true);
          showcaseRefetch();
        })
        .catch((err) => {
          console.log(err);
          setSendBody(undefined);
          toast.error(err.response.data.message, {});
        });
    }
  };

  const onSpiralTransaction = async (transaction) => {
    if (transaction.type === "ADD") {
      setSpiralStatus(transaction);
      setConfirmModal({
        title: t(`show-case.${transaction.rotation.toLowerCase()}-add-spiral`),
        confirmText: t(
          `show-case.${transaction.rotation.toLowerCase()}-add-spiral-confirm`
        ),
      });
      setIsConfirmModalOpen(true);
    } else if (transaction.type === "REMOVE") {
      setSpiralStatus(transaction);
      setConfirmModal({
        title: t(
          `show-case.${transaction.rotation.toLowerCase()}-remove-spiral`
        ),
        confirmText: t(
          `show-case.${transaction.rotation.toLowerCase()}-remove-spiral-confirm`
        ),
      });
      setIsConfirmModalOpen(true);
    }
  };

  const spiralTransaction = async () => {
    services
      .spiralTransaction(
        outsourceId || id,
        spiralStatus.rotation,
        spiralStatus.type,
        spiralStatus.index
      )
      .then(({ data }) => {
        setSpiralStatus({
          type: "", // ['ADD','REMOVE']
          rotation: "", // ['HORIZONTAL','VERTICAL']
          index: 0,
        });
        toast.success(data.message);
        showcaseRefetch();
      })
      .catch((err) => {
        toast.error(err.response.data.message, {});
      });
  };

  const onSubmit = (trn) => {
    if (transaction.type === "ADD") {
      setConfirmModal({
        title: t("show-case.add-product"),
        confirmText: t("show-case.add-product-confirm"),
      });
      setIsConfirmModalOpen(true);
      setSendBody(trn);
    } else if (transaction.type === "UPDATE") {
      setConfirmModal({
        title: t("show-case.update-product"),
        confirmText: t("show-case.update-product-confirm"),
      });
      setIsConfirmModalOpen(true);
      setSendBody(trn);
    } else if (trn.type === "BLOCK" || transaction.type === "BLOCK") {
      setConfirmModal({
        title:
          trn.slot.type === "CLOSED"
            ? t("show-case.un-block-slot")
            : t("show-case.block-slot"),
        confirmText:
          trn.slot.type === "CLOSED"
            ? t("show-case.un-block-slot-explanation")
            : t("show-case.block-slot-explanation"),
      });
      setIsConfirmModalOpen(true);
      setSendBody(trn);
    } else if (trn?.type === "REMOVE" || transaction.type === "REMOVE") {
      setConfirmModal({
        title: t("show-case.remove-product"),
        confirmText: t("show-case.remove-product-confirm"),
      });
      setIsConfirmModalOpen(true);
      setSendBody(trn);
    }
  };

  const setTransactionHandler = (newT) => {
    if (
      !transaction.is_selected ||
      (transaction.hIndex === newT.hIndex && transaction.vIndex === newT.vIndex)
    ) {
      setTransaction({
        ...newT,
      });

      if (newT.type === "REMOVE") {
        onSubmit(newT);
      }

      if (newT.type === "BLOCK") {
        onSubmit(newT);
      }
    } else {
      toast.error(t("show-case.slot-select-error"), {
        duration: 3000,
      });
    }
  };

  const discardTransaction = (withoutNotification) => {
    setTransaction({
      is_selected: false,
      type: "", // ['UPDATE','REMOVE','ADD']
      product: null,
      slot: null,
      hIndex: null,
      vIndex: null,
    });

    if (!withoutNotification) {
      toast.success(t("show-case.transaction-discarded"), {
        duration: 3000,
      });
    }
  };

  if (showcaseLoading) return <ShowcaseSkeleton />;

  return (
      <div>
        <div className="flex lg:flex-row md:flex-col xs:flex-col gap-4 items-center">
          <h1>
            {t("actions.transactions", {
              name: showcaseData?.name,
            })}
          </h1>
          <Tooltip
              label={t("show-case.empty-slot-explanation")}
              withArrow
              style={{
                color: "black",
              }}
              color="white"
          >
            <Badge color="blue">
              {t("show-case.empty_slots", {
                count: showcaseData?.empty_slot_count,
              })}
            </Badge>
          </Tooltip>

          <Tooltip
              label={t("show-case.blocked-slot-explanation")}
              withArrow
              style={{
                color: "black",
              }}
              color="white"
          >
            <Badge color="red">
              {t("show-case.blocked_slots", {
                count: showcaseData?.blocked_slot_count,
              })}
            </Badge>
          </Tooltip>
          <Tooltip
              label={t("show-case.product-slot-explanation")}
              withArrow
              style={{
                color: "black",
              }}
              color="white"
          >
            <Badge color="lime">
              {t("show-case.product_slots", {
                count: showcaseData?.product_slot_count,
              })}
            </Badge>
          </Tooltip>
          <Tooltip
              label={t("show-case.total-slot-explanation")}
              withArrow
              style={{
                color: "black",
              }}
              color="white"
          >
            <Badge color="teal">
              {t("show-case.total_slots", {
                count: showcaseData?.total_slot_count,
              })}
            </Badge>
          </Tooltip>

          <Button
              onClick={() => {
                onSpiralTransaction({
                  type: "ADD",
                  rotation: "HORIZONTAL",
                  index: 0,
                });
              }}
          >
            {t("show-case.horizontal-add-spiral")}
          </Button>
          <Button
              onClick={() => {
                onSpiralTransaction({
                  type: "ADD",
                  rotation: "VERTICAL",
                  index: 0,
                });
              }}
          >
            {t("show-case.vertical-add-spiral")}
          </Button>
          <Button
              onClick={() => {
                setIsOpen(true)
              }}
          >
            {'Ürün Stok Detayları'}
          </Button>
        </div>

        <div className="grid grid-cols-12 w-full pt-3">
          <div className="md:col-span-8 sm:col-span-12 xs:col-span-12 pr-2 lg:pr-2 xs:pr-0 md:pr-0 w-full h-full">
            <SlotList
                refetch={showcaseRefetch}
                matrix={showcaseData.matrix}
                setTransaction={setTransactionHandler}
                onDelete={onSpiralTransaction}
                transaction={transaction}
                horizontal_spiral_count={showcaseData.horizontal_spiral_count}
                vertical_spiral_count={showcaseData.vertical_spiral_count}
            />
          </div>
          <div
              className="md:col-span-4 border-gray-400 min-h-[400px] border-[1px] rounded-sm sm:col-span-12 xs:col-span-12 w-full h-full">
            {transaction.is_selected &&
            transaction.type !== "REMOVE" &&
            transaction.type !== "BLOCK" ? (
                <ShowcaseProduct
                    transaction={transaction}
                    horizontal_spiral_count={showcaseData.horizontal_spiral_count}
                    onSubmit={onSubmit}
                    discardTransaction={discardTransaction}
                />
            ) : (
                <div className="px-12 min-h-[400px] flex-col text-center flex h-full items-center justify-center">
                  <InformationCircleIcon
                      width={64}
                      className="mb-6 text-gray-500"
                  />

                  {t("show-case.if-you-want-to-add-product-please-select-slot")}
                </div>
            )}
          </div>
        </div>

        <ShowcaseDetailModal isOpen={isOpen} setIsOpen={setIsOpen} data={showcaseData?.product_requirements} />

        <ConfirmModal
            isOpen={isConfirmModalOpen}
            onClose={() => {
              setIsConfirmModalOpen(false);
              setTimeout(() => {
                setConfirmModal({
                  title: "",
                  confirmText: "",
                });

                if (transaction.type === "REMOVE" || transaction.type === "BLOCK") {
                  discardTransaction();
                }

                setSpiralStatus({
                  type: "", // ['ADD','REMOVE']
                  rotation: "", // ['HORIZONTAL','VERTICAL']
                  index: 0,
                });
              }, 200);
            }}
            onConfirm={onConfirm}
            title={confirmModal.title}
            confirmText={confirmModal.confirmText}
        />
      </div>
  );
};
export default ShowcaseTransactions;
