import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import services from "../../services";
import { Accordion, Badge, Button, Indicator } from "@mantine/core";
import BarLoading from "../../components/Loading/BarLoading";
import { useState } from "react";
import UpdateTaskSpiral from "../../components/UpdateTaskSpiral/UpdateTaskSpiral";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import toast from "react-hot-toast";

const SpiralItem = ({ item, onClick, setNewItem }) => {
  const { t } = useTranslation();

  if (item.type === "EMPTY")
    return (
      <div className="w-full dark:!bg-slate-700/70 dark:text-white bg-gray-300 items-center flex flex-row gap-x-1 border-[1px] border-gray-400 py-2 px-2 rounded-md">
        <Badge size="md" color="red">
          {item.order_no}
        </Badge>
        <span className="font-semibold text-xs">
          {t("show-case.this-field-is-empty-you-need-to-add-product")}
        </span>
      </div>
    );
  if (item.type === "CLOSED")
    return (
      <div className="w-full dark:!bg-slate-700/70 dark:text-white bg-gray-300 items-center flex flex-row gap-x-1 border-[1px] border-gray-400 py-2 px-2 rounded-md">
        <Badge size="md" color="red">
          {item.order_no}
        </Badge>
        <span className="font-semibold text-xs">
          {t("show-case.this-field-is-empty-you-need-to-add-product")}
        </span>
      </div>
    );
  return (
    <div className="flex gap-y-1 overflow-hidden w-full flex-col border-[1px] border-gray-400 rounded-md px-2 py-2">
      <div className="flex items-center overflow-hidden font-semibold flex-row gap-x-1">
        <Badge size="md" color="red">
          {item.order_no}
        </Badge>
        {item.type === "PRODUCT"
          ? item.product.name
          : item.type === "BLOCKED"
          ? "BLOKE"
          : "BOŞ"}
      </div>
      <div className="flex flex-row overflow-hidden">
        <div className="bg-red-400 text-white text-sm w-full text-center">
          {t("show-case.active-stock")}
          <span className="font-bold">{" " + item.current_stock}</span>
        </div>
        <div className="bg-red-500 text-white text-sm w-full text-center">
          {t("show-case.capacity")}
          <span className="font-bold">{" " + item.capacity}</span>
        </div>
      </div>
      {!item.is_approved_or_updated && (
        <div className="flex flex-row w-full gap-x-1">
          <Button
            onClick={() => onClick("UPDATE", item.id)}
            className="!w-full"
          >
            Güncelle
          </Button>
          <Button
            onClick={() => onClick("APPROVE", item.id)}
            className="!w-full"
            color="orange"
          >
            {t("actions.confirm")}
          </Button>
        </div>
      )}
      {item.is_approved_or_updated && (
        <div className="flex flex-row overflow-hidden w-full gap-x-1">
          <Button
            onClick={() => onClick("FILL", item.id)}
            className="!w-full"
            color="green"
          >
            {t("actions.fill")}
          </Button>
          <Button
            onClick={() => onClick("UNLOAD", item.id)}
            className="!w-full"
            color="red"
          >
            {t("actions.unload")}
          </Button>
          <Button onClick={() => onClick("ADD", item.id)} className="!w-full">
            {t("actions.add")}
          </Button>
          <Button
            onClick={() => onClick("REMOVE", item.id)}
            className="!w-full"
            color="red"
          >
            {t("actions.remove")}
          </Button>
        </div>
      )}
    </div>
  );
};

const DeliveryTaskSaleSpotDetail = (props) => {
  const { id, task_id } = useParams();
  const [activeId, setActiveId] = useState(null);
  const [activeIdShowCase, setActiveIdShowCase] = useState(null);
  const navigate = useNavigate();
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [type, setType] = useState(null);
  const [showcaseData, setShowcaseData] = useState([]);
  const { t } = useTranslation();

  const [updateModalVisible, setUpdateModalVisible] = useState(false);

  const {
    isLoading,
    data,
    refetch: refetchDetail,
    isFetching,
  } = useQuery({
    queryKey: ["task", id],
    enabled: !!id && !!task_id,
    queryFn: async () => {
      return await services
        .getDeliveryTaskSaleSpotById(task_id, id)
        .then((res) => res.data);
    },
  });

  const {
    isLoading: isLoadingShowCase,
    data: dataShowCase,
    refetch: refetchDetailShowCase,
    isFetching: isFetchingShowCase,
  } = useQuery({
    queryKey: ["task_show_case", activeId],
    enabled: !!id && !!task_id && !!activeId,
    queryFn: async () => {
      return await services
        .getDeliveryTaskShowCaseById(task_id, id, activeId)
        .then((res) => {
          setShowcaseData(res.data);
          return res.data;
        });
    },
  });

  const handleOpenAccordion = (id) => {
    setActiveId(id);
  };
  const handleConfirm = async () => {
    const item = dataShowCase?.showcase_spiral_list?.find(
      (d) => d.id === activeIdShowCase
    );

    const itemIndex = showcaseData.showcase_spiral_list.findIndex(
      (d) => d.id === activeIdShowCase
    );

    services
      .updateDeliveryTaskShowCaseStatus(task_id, id, activeId, {
        vertical_index: item.vertical_index,
        horizontal_index: item.horizontal_index,
        status: type,
      })
      .then((res) => {
        if (res.success) {
          setUpdateModalVisible(false);
          setConfirmModalVisible(false);
          setActiveIdShowCase(null);
          setType(null);

          if (res?.data?.message) {
            toast.success(res?.data?.message);
          }
          if (res.data?.data) {
            itemSetter(itemIndex, res.data.data);
          }
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message || t("notifications.error.message")
        );
      });
  };

  const itemSetter = (index, newItem) => {
    if (newItem) {
      const newShowCaseData = [...showcaseData.showcase_spiral_list];
      const oldData = newShowCaseData[index];
      delete newItem.product;
      newShowCaseData[index] = { ...oldData, ...newItem };
      setShowcaseData({ showcase_spiral_list: newShowCaseData });
    }
  };

  const handleClick = (type, id) => {
    setType(type);
    if (type == "FILL" || type == "UNLOAD" || type == "APPROVE") {
      setActiveIdShowCase(id);
      setConfirmModalVisible(true);
      return;
    } else {
      setActiveIdShowCase(id);
      setUpdateModalVisible(true);
    }
  };

  const handleSend = async (data) => {
    const item = dataShowCase?.showcase_spiral_list?.find(
      (d) => d.id === activeIdShowCase
    );

    const itemIndex = showcaseData.showcase_spiral_list.findIndex(
      (d) => d.id === activeIdShowCase
    );

    services
      .updateDeliveryTaskShowCaseStatus(task_id, id, activeId, {
        vertical_index: item.vertical_index,
        horizontal_index: item.horizontal_index,
        status: type,
        count: Number(data.count),
      })
      .then((res) => {
        if (res.success) {
          setUpdateModalVisible(false);
          setConfirmModalVisible(false);
          setActiveIdShowCase(null);
          setType(null);
          if (res?.data?.message) {
            toast.success(res?.data?.message);
          }
          if (res.data?.data) {
            itemSetter(itemIndex, res.data.data);
          }
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.message || t("notifications.error.message")
        );
      });
  };

  if (isLoading) return <BarLoading />;

  const items = data.showcases?.map((item) => (
    <Accordion.Item
      classNames={{
        item: "!border-transparent",
      }}
      key={item._id}
      value={item._id}
    >
      <Accordion.Control
        classNames={{
          control:
            "!bg-gray-200 dark:!bg-slate-800/70 hover:!dark:!bg-slate-700/70 dark:text-white bg-gray-300 dark:hover:!bg-slate-800 dark:!text-white text-gray-700  !shadow-none font-semibold ring-1 rounded-md ",
        }}
        onClick={() => handleOpenAccordion(item._id)}
      >
        {item._id} {item.name}
      </Accordion.Control>
      <Accordion.Panel
        classNames={{
          content: "!p-0 !pt-2",
        }}
        className="overflow-hidden"
      >
        {isLoadingShowCase ? (
          <BarLoading />
        ) : (
          <div className="flex flex-col gap-y-2">
            {showcaseData.showcase_spiral_list?.map((d, i) => {
              return (
                <SpiralItem
                  key={i}
                  setNewItem={itemSetter}
                  onClick={handleClick}
                  item={d}
                />
              );
            })}
          </div>
        )}
      </Accordion.Panel>
    </Accordion.Item>
  ));
  return (
    <div>
      <ConfirmModal
        isOpen={confirmModalVisible}
        onConfirm={() => handleConfirm()}
        onClose={() => {
          setConfirmModalVisible(false);
        }}
        title={
          type == "FILL"
            ? t("actions.fill")
            : type == "UNLOAD"
            ? t("actions.unload")
            : t("actions.confirm")
        }
        confirmText={
          type === "FILL" || type === "UNLOAD" || type === "APPROVE"
            ? t(`descriptions.delivery-task-${String(type).toLowerCase()}`)
            : t("actions.confirm")
        }
      />

      <UpdateTaskSpiral
        isOpen={updateModalVisible}
        title={
          type == "UPDATE"
            ? t("actions.update")
            : type == "ADD"
            ? t("actions.add")
            : type == "REMOVE"
            ? t("actions.remove")
            : ""
        }
        item={dataShowCase?.showcase_spiral_list?.find(
          (d) => d.id === activeIdShowCase
        )}
        onSend={(data) => handleSend(data)}
        onClose={() => setUpdateModalVisible(false)}
      />
      <div className="flex flex-1 gap-4 flex-col">
        <Accordion>{items}</Accordion>
      </div>
    </div>
  );
};

export default DeliveryTaskSaleSpotDetail;
