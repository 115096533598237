import { Outlet } from "react-router";
import Layout from "../../components/Layout/Layout";

const Campaign = () => {
  return (
    <div className="h-screen min-h-full max-h-full dark:bg-gray-900 bg-gray-200 overflow-auto ">
      <Layout>
        <Outlet />
      </Layout>
    </div>
  );
};

export default Campaign;
