import { axiosInstance } from ".";

export const companyService = (state) => {
  return {
    async list(pageNo, pageSize, search) {
      const response = await axiosInstance.get(
        `company?page=${pageNo}&pageSize=${pageSize}${
          search ? `&search_key_word=${search}` : ""
        }`
      );
      return response?.data || [];
    },
    async get(id) {
      const response = await axiosInstance.get(`company/${id}`);
      return response?.data || [];
    },
    async create(data) {
      const response = await axiosInstance.post("company", data);
      return response?.data || [];
    },
    async update(id, data) {
      const response = await axiosInstance.put(`company/${id}`, data);
      return response?.data || [];
    },
    async delete(id) {
      const response = await axiosInstance.get(`company/delete/${id}`);
      return response?.data || [];
    },
  };
};
