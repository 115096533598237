import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import services from "../../services";
import { Badge, Pagination } from "@mantine/core";
import { useEffect, useLayoutEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import CustomSearch from "../../components/CustomSearch/CustomSearch";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import toast from "react-hot-toast";
import TableSkeleton from "../../components/Skeleton/TableSkeleton";
import dayjs from "dayjs";
import EmptyTableState from "../../components/EmptyTableState/EmptyTableState";
import CustomSelect from "../../components/CustomSelect/CustomSelect";

const ProductRow = ({ transaction, index, handleNavigate }) => {
  const { t } = useTranslation();

  return (
    <tr
      key={`${transaction.name}-${index.toString()}`}
      className={`${
        index % 2 === 0
          ? "dark:bg-slate-700/80 bg-gray-200"
          : "dark:bg-slate-600/80 bg-gray-100"
      } hover:bg-orange-200/90 dark:hover:bg-orange-600/20`}
    >
      <td className="whitespace-nowrap px-5 py-4 text-sm text-slate-700 dark:text-white">
        {dayjs(transaction.in_date).format("DD-MM-YYYY")}
      </td>
      <td className="whitespace-nowrap px-5 py-4 text-sm text-slate-700 dark:text-white">
        {dayjs(transaction.expiration_date).format("DD-MM-YYYY")}
      </td>

      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        {transaction.lot_no}
      </td>
      <td className="whitespace-nowrap font-semibold px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        {transaction.amount}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 dark:text-gray-300">
        <Badge color={transaction.type === "IN" ? "green" : "red"}>
          {transaction.type}
        </Badge>
      </td>

      <td className="whitespace-nowrap px-3 py-4  text-sm text-gray-500 dark:text-gray-300">
        <Badge color={transaction.is_verified ? "blue" : "red"}>
          {transaction.is_verified
            ? t("status.verified")
            : t("status.unverified")}
        </Badge>
      </td>

      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium w-[150px] sm:pr-6 dark:text-gray-300">
        {!transaction.is_verified && (
          <button
            onClick={() => handleNavigate("verify", transaction._id)}
            className="text-orange-600 hover:text-orange-900"
          >
            {t("stock.verify")}
          </button>
        )}
      </td>
    </tr>
  );
};

const StorageProductTransactions = () => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [id, setId] = useState(null);
  const { id: productId } = useParams();
  const [verifyModal, setVerifyModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    Number(searchParams.get("page")) ? Number(searchParams.get("page")) : 1
  );
  const [search, setSearch] = useState(
    searchParams.get("search") ? searchParams.get("search") : ""
  );
  const [pageSize, setPageSize] = useState(
    searchParams.get("pageSize") ? searchParams.get("pageSize") : 10
  );

  const {
    isLoading,
    error,
    data,
    isFetching,
    refetch: refetchStorageProductTransactions,
  } = useQuery({
    queryKey: [
      "storage-in-product-transactions",
      currentPage,
      pageSize,
      search,
      productId,
    ],
    enabled: !!search || (!!pageSize && !!currentPage),
    queryFn: async () =>
      await services
        .getStorageProductStockTransactions(
          productId,
          currentPage,
          pageSize,
          search
        )
        .then((res) => res.data),
  });

  const { data: productData } = useQuery({
    queryKey: ["product-in-storage-detail", productId],
    queryFn: async () =>
      await services.getStorageProductDetail(productId).then((res) => res.data),
  });

  useLayoutEffect(() => {
    const search = searchParams.get("search");
    if (search) {
      setSearchParams({ page: 1, pageSize: pageSize, search: search });
    } else {
      // setSearchParams({ page: 1, pageSize: pageSize });
    }
  }, [currentPage, pageSize, setSearchParams, search, searchParams]);

  useEffect(() => {
    if (data?.data?.length === 0 && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }, [data, currentPage]);
  if (error) return <p>Error :(</p>;

  const handleNavigate = (page, id) => {
    switch (page) {
      case "verify":
        setId(id);
        setVerifyModal(true);
        break;
    }
  };

  const verifyStockTransaction = async (id) => {
    await services.verifyStockTransaction(id).then((res) => {
      if (res.success) {
        toast.success(
          res?.message ? res.message : t("notifications.success.message")
        );
        setVerifyModal(false);
        setId(null);
        refetchStorageProductTransactions();
      } else {
        setVerifyModal(false);
        setId(null);
        toast.error(
          res?.message ? res.message : t("notifications.error.message")
        );
      }
    });
  };

  const handlePageChange = (page) => {
    if (data.transactions.length === 0 && page > 1) {
      setCurrentPage(page - 1);
    }
    setCurrentPage(page);
    setSearchParams({ page: page, pageSize: pageSize });
  };

  const handleSearch = (value) => {
    setSearch(value);
    setSearchParams({ page: 1, pageSize: pageSize, search: value });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmModal
        isOpen={verifyModal}
        onConfirm={() => verifyStockTransaction(id)}
        onClose={() => {
          setVerifyModal(false);
          setId(null);
        }}
        title={t("titles.verify-transaction")}
        confirmText={t("descriptions.are-you-sure-to-verify-this-transaction")}
      />

      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 dark:text-white text-slate-700 items-center flex gap-x-6">
            <span>
              {t("titles.storage-product-stock-transactions", {
                product_name: productData?.product?.name,
                storage_name: productData?.storage?.name,
              })}
            </span>
          </h1>
          <p className="mt-2 text-sm dark:text-gray-400 text-gray-500 flex items-center gap-x-4">
            {t("descriptions.storage-product-transaction-list", {
              name: productData?.product?.name,
            })}
            <Badge>
              {t("storage.current-stock")} {data?.activeStockAmount}
            </Badge>
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none flex flex-col sm:flex-row xs:flex-col-reverse gap-2 xs:justify-between justify-start items-center">
          <div className="block md:hidden xs:w-full">
            <CustomSearch
              textInputClassName="xs:w-full"
              search={search}
              onSearch={handleSearch}
            />
          </div>
          {/* <button
            type="button"
            onClick={() => handleNavigate("transactions", productId)}
            className="block rounded-md bg-orange-600 dark:bg-orange-800 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-orange focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-600 xs:w-full"
          >
            {t("actions.storage-add-product-transaction")}
          </button> */}
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              {!isLoading && data?.transactions?.length <= 0 ? (
                <div className="px-12 py-12">
                  <EmptyTableState
                    title={t("empty-text.add-stock-transaction-title")}
                    text={t("empty-text.add-stock-transaction-desc")}
                    buttonText={t("empty-text.add-stock-transaction-button")}
                    onClick={() => handleNavigate("transactions", productId)}
                  />
                </div>
              ) : (
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="dark:bg-gray-800 bg-gray-300 text-slate-700">
                    <tr>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.in_date")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.expiration_date")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.lot_no")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.amount")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.type")}
                      </th>
                      <th
                        scope="col"
                        className="px-3.5 py-3.5 text-left text-sm font-semibold dark:text-white text-slate-700"
                      >
                        {t("stock.verified")}
                      </th>

                      <th
                        scope="col"
                        className="flex py-3.5 pl-3 pr-4 sm:pr-6 w-full "
                      >
                        <div className="hidden md:block">
                          <CustomSearch
                            search={search}
                            onSearch={handleSearch}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:bg-gray-800 bg-gray-200 text-slate-700">
                    {isLoading || isFetching ? (
                      <TableSkeleton colSize={7} />
                    ) : (
                      data.transactions.map((transaction, index) => (
                        <ProductRow
                          key={index}
                          transaction={transaction}
                          index={index}
                          handleNavigate={handleNavigate}
                        />
                      ))
                    )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>

        {!(!isLoading && data.transactions.length <= 0) && (
          <div className="flex flex-col gap-5  overflow-x-auto w-full  sm:gap-1  sm:flex-row items-center  my-5">
            <div className="flex justify-center w-full items-center">
              <Pagination
                total={data?.totalPageCount}
                siblings={1}
                size={"md"}
                defaultValue={
                  Number(searchParams.get("page"))
                    ? Number(searchParams.get("page"))
                    : 1
                }
                onChange={handlePageChange}
                color="orange"
                classNames={{
                  control:
                    "flex items-center justify-center gap-x-2 dark:disabled:!bg-gray-700/50  dark:[&[data-active=true]]:!bg-orange-700/50 dark:[&[data-active=true]]:!border-orange-700  dark:[&[data-active=true]]:!border-orange-700 [&[data-active=true]]:!bg-orange-600 dark:bg-orange-800/90 [&[data-active=true]]:!border-orange-300/90 !bg-gray-200/90 !border   dark:!bg-slate-800 dark:!text-white  :disabled:!bg-gray-100/50 !rounded-lg ",
                }}
              />
            </div>
            <div className=" flex justify-end w-[80px]">
              <CustomSelect
                withoutController={true}
                placeholder={t("placeholders.select")}
                onChange={(value) => {
                  setPageSize(value);
                  setSearchParams({ page: 1, pageSize: value });
                  setCurrentPage(1);
                }}
                data={[5, 10, 20, 30, 50]}
                value={pageSize}
                name="pageSize"
                searchable={false}
                height={"20px"}
                width={20}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default StorageProductTransactions;
