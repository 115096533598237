import { useTranslation } from "react-i18next";
import Layout from "../../components/Layout/Layout";
import { useStore } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { Avatar, Button } from "@mantine/core";
import { config } from "../../config";

const Profile = () => {
  const { auth } = useStore();
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Layout>
      <div className="flex flex-col h-screen max-h-full">
        <div className="flex flex-col md:flex-row md:items-center md:justify-between">
          <div className="flex-1 min-w-0">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 dark:text-white sm:text-3xl sm:truncate">
              {t("actions.profile")}
            </h2>
          </div>
        </div>
        <div className="mt-4">
          <div className="bg-white dark:bg-gray-700 overflow-hidden shadow rounded-lg lg:divide-x lg:divide-y-0 divide-y  dark:divide-gray-100/30 divide-gray-100 grid grid-cols-1 lg:grid-cols-2">
            <div className="px-2 py-5 sm:p-4">
              <div className="flex flex-col">
                <div className="mt-5">
                  <Avatar
                    size="xl"
                    src={config.CDN_URL + auth.user?.photo}
                    alt={auth?.user?.name}
                  />
                </div>
                <div className="mt-5">
                  <dl className="grid grid-cols-1 gap-5 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 dark:text-white">
                        {t("user.name")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                        {auth?.user?.name} {auth?.user?.last_name}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 dark:text-white">
                        {t("user.email")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                        {auth?.user?.email}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500 dark:text-white">
                        {t("user.phone-number")}
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-white">
                        {auth?.user?.phone_number}
                      </dd>
                    </div>
                  </dl>
                </div>
                <div className="mt-5">
                  <Button
                    color="orange"
                    radius={"md"}
                    type="submit"
                    onClick={() => {
                      navigate(`/user/edit/${auth?.user?._id}`);
                    }}
                    className="rounded-md dark:bg-orange-700 !bg-orange px-3 py-2 text-sm font-semibold text-white shadow-sm dark:hover:bg-orange-600 hover:bg-orange-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-orange-500"
                  >
                    {t("actions.update")}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Profile;
