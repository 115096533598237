import { axiosInstance } from ".";

export const roleService = (state) => {
  return {
    async list() {
      const response = await axiosInstance.get("role");
      return response?.data || [];
    },
  };
};
