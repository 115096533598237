import { useMutation, useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import services from "../../services";
import { useForm, useWatch } from "react-hook-form";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import BarLoading from "../../components/Loading/BarLoading";
import CustomMultiSelect from "../../components/CustomSelect/CustomMultiSelect";
import CustomSelect from "../../components/CustomSelect/CustomSelect";
import { Group, Image, NumberInput, Radio, Text, rem } from "@mantine/core";
import { config } from "../../config";
import { IconCheck, IconUpload, IconX } from "@tabler/icons-react";
import { Dropzone, IMAGE_MIME_TYPE } from "@mantine/dropzone";
import { IconPhoto } from "@tabler/icons-react";
import { Switch } from "@mantine/core";
import { yupResolver } from "@hookform/resolvers/yup";
import { CampaignCreateValidations } from "./validations";
import SaveButton from "../../components/Buttons/SaveButton";
import CustomInput from "../../components/CustomInput/CustomInput";
import { CampaignTypes, EndTypes, PriceTypes } from "./staticData";
import CustomDatetimePicker from "../../components/CustomDatetimePicker/CustomDatetimePicker";

const CampaignCreate = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [priceProductList, setPriceProductList] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    getValues,
    control,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(CampaignCreateValidations),
    defaultValues: {
      is_active: true,
      description: "",
      type: "",
      end_count: null,

      photo: null,
    },
  });

  const [photoVal, setPhotoVal] = useState(null);

  const photo = useWatch({
    control,
    name: "photo",
    defaultValue: null,
  });
  const products = useWatch({
    control,
    name: "products",
    defaultValue: [],
  });

  const price = useWatch({
    control,
    name: "price",
  });

  const isActive = useWatch({
    control,
    name: "is_active",
    defaultValue: true,
  });

  const endType = useWatch({
    control,
    name: "end_type",
  });

  const endDate = useWatch({
    control,
    name: "end_date",
    defaultValue: new Date(),
  });
  const endCount = useWatch({
    control,
    name: "end_count",
  });

  const campaignTypes = Object.entries(CampaignTypes).map(([key, value]) => ({
    value: value,
    label: t(`campaign.types.${key.toLocaleLowerCase()}`),
  }));
  const endTypes = Object.entries(EndTypes).map(([key, value]) => ({
    value: value,
    label: t(`campaign.end-types.${key.toLocaleLowerCase()}`),
  }));

  const campaignType = useWatch({
    control,
    name: "type",
  });
  const type_ids = useWatch({
    control,
    name: "type_ids",
  });

  const addCampaign = useMutation({
    mutationFn: (newCampaign) => services.createCampaign(newCampaign),
  });
  const updateCampaign = useMutation({
    mutationFn: (newCampaign) => services.updateCampaign(id, newCampaign),
  });

  const {
    isLoading,
    error,
    data: productData,
    isFetching,
  } = useQuery({
    queryKey: ["products"],
    queryFn: async () =>
      await services.products(1, 400).then((res) => {
        const data = res.data.data.map((item) => {
          // no white space name condition //

          if (item.name.includes(" ")) {
            item.name = item.name.replace(/\s/g, "-");
          }
          return {
            value: item._id,
            label: item.brand.name + " " + item.name,
            price: item.price,
          };
        });
        return data;
      }),
  });

  const { data: saleSpotData } = useQuery({
    queryKey: ["saleSpots"],
    queryFn: async () =>
      await services.saleSpotList(1, 100).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
        })),
      ]),
    enabled: campaignType === "SALE_SPOT",
  });

  const { isLoading: saleAreaLoading, data: saleAreaData } = useQuery({
    queryKey: ["saleAreas"],
    queryFn: async () =>
      await services.mainSalesAreaList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
          coordinates: item.coordinates,
        })),
      ]),
    enabled: campaignType === "MAIN_SALE_AREA",
  });

  const { isLoading: subSaleAreaLoading, data: subSalesAreaData } = useQuery({
    queryKey: ["saleAreas"],
    queryFn: async () =>
      await services.subSalesAreaList(1, 400).then((res) => [
        ...res.data.data.map((item) => ({
          value: item._id,
          label: item.name,
          coordinates: item.coordinates,
        })),
      ]),
    enabled: campaignType === "SALE_AREA",
  });

  const {
    isLoading: userLoading,
    error: usersError,
    data: userData,
    isFetching: usersFetching,
  } = useQuery({
    queryKey: ["users"],
    enabled: campaignType === "USER",
    queryFn: async () => {
      return await services.getPublicUserList(1, 1000).then((res) =>
        res.data.data.map((item) => ({
          value: item._id,
          label: item.name + " " + item.last_name,
        }))
      );
    },
  });

  const handleCancel = () => {
    reset();
    navigate("/campaign/list");
  };

  const onSubmit = async (data) => {
    let newCampaignPayload;
    let campaign;
    const formData = new FormData();

    try {
      if (!priceProductList.length) {
        toast.error(t("notifications.error.price-required"), {
          duration: 3000,
        });
        return;
      }

      newCampaignPayload = {
        ...data,
        products: priceProductList.map((item) => {
          return item;
        }),
        price: priceProductList.reduce(
          (acc, item) => acc + Number(item.price),
          0
        ),
      };

      if (
        photoVal instanceof File ||
        (data.photo instanceof File && Object.values(errors).length === 0)
      ) {
        const photoValue = photoVal || data.photo;
        formData.append("type", photoValue.type);
        formData.append("file", photoValue);
        const photo = await services.fileUpload(formData);
        delete newCampaignPayload.photo;
        newCampaignPayload = {
          ...newCampaignPayload,
          image: photo?.data.file,
        };
      }

      if (!id) {
        campaign = await addCampaign.mutateAsync(newCampaignPayload);
      } else {
        newCampaignPayload = {
          ...newCampaignPayload,
          products: newCampaignPayload.products.map((item) => {
            if (item.price_type == "PERCENT") {
              item.price = Number(
                productData.find((product) => product.value == item.id).price -
                  (productData.find((product) => product.value == item.id)
                    .price *
                    Number(getValues(`price_${item.id}`))) /
                    100
              );
            }
            return item;
          }),
        };
        campaign = await updateCampaign.mutateAsync(newCampaignPayload);
      }

      if (!id && campaign) {
        toast.success(
          campaign?.data?.message
            ? campaign?.data?.message
            : t("notifications.success.campaign-create"),
          {
            duration: 3000,
          }
        );

        handleCancel();
      } else if (id && campaign) {
        toast.success(
          campaign?.data?.message
            ? campaign?.data?.message
            : t("notifications.success.campaign-update"),
          {
            duration: 3000,
          }
        );
        handleCancel();
      }
    } catch (e) {
      toast.error(e.response.data.message || t("notifications.error.message"), {
        duration: 3000,
      });
    }
  };

  const campaignTypeData = (type) => {
    switch (type) {
      case "SALE_SPOT":
        return saleSpotData;
      case "MAIN_SALE_AREA":
        return saleAreaData;
      case "SALE_AREA":
        return subSalesAreaData;
      case "USER":
        return userData;
      default:
        return [];
    }
  };

  const fetchCampaign = useCallback(async () => {
    if (id) {
      setLoading(true);
      try {
        const campaign = await services.getCampaignById(id);
        Object.keys(campaign.data).forEach((key) => {
          if (key === "image") {
            setValue("photo", campaign.data[key]);
          }

          if (key === "products_price") {
            setValue("products", campaign.data["products"]);
            setPriceProductList(
              campaign.data[key].map((item) => {
                setValue(`price_type_${item.id}`, item.price_type);
                if (item.price_type == "PERCENT") {
                  const product = productData.find(
                    (product) => product.value == item.id
                  );
                  if (product) {
                    const percentage = Math.round(
                      ((product.price - item.price) / product.price) * 100
                    );
                    setValue(`price_${item.id}`, percentage);
                  }
                } else {
                  setValue(`price_${item.id}`, item.price);
                }

                return {
                  id: item.id,
                  price: item.price,
                  price_type: item.price_type,
                };
              })
            );
          }
          if (key === "end_date") {
            setValue(key, new Date(campaign.data[key]));
          } else {
            setValue(key, campaign.data[key]);
          }
        });
      } catch (e) {
        navigate("/campaign");
        toast.error(t("notifications.error.campaign-notfound"), {
          duration: 3000,
        });
      } finally {
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    reset();
    if (id) {
      fetchCampaign();
    }
  }, [id]);
  return (
    <>
      {!loading && !isLoading ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <form
            onSubmit={handleSubmit((data) =>
              onSubmit(data, getValues("photo"))
            )}
          >
            <div className="space-y-12">
              <div className="border-b border-white/10 pb-12">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-base font-semibold leading-7 dark:text-white text-slate-700">
                      {id
                        ? t("titles.campaign-edit")
                        : t("titles.campaign-create")}
                    </h2>
                    <p className="mt-1 text-sm leading-6 text-gray-400">
                      {id
                        ? t("descriptions.campaign-edit")
                        : t("descriptions.campaign-create")}
                    </p>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-xs font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("labels.is-active")}
                    </label>
                    <div className="mt-2">
                      <Switch
                        checked={isActive}
                        onChange={(event) =>
                          setValue("is_active", event.target.checked)
                        }
                        color="teal"
                        size="md"
                        thumbIcon={
                          isActive ? (
                            <IconCheck
                              style={{ width: rem(12), height: rem(12) }}
                              color="green"
                              stroke={3}
                            />
                          ) : (
                            <IconX
                              style={{ width: rem(12), height: rem(12) }}
                              color="red"
                              stroke={3}
                            />
                          )
                        }
                      />
                    </div>
                  </div>
                </div>

                <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6 max-w-full">
                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("campaign.name")}
                      type="text"
                      name="name"
                      register={register}
                      errors={errors}
                      placeholder={t("campaign.name")}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <CustomInput
                      label={t("campaign.style")}
                      type="text"
                      name="style"
                      register={register}
                      errors={errors}
                      placeholder={t("campaign.style")}
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <span className="block text-sm font-medium leading-6 dark:text-white text-slate-700">
                      {t("campaign.type")}
                    </span>
                    <div className="mt-2">
                      <CustomSelect
                        control={control}
                        placeholder={t("placeholders.select")}
                        name="type"
                        data={campaignTypes}
                        onChange={(value) => {
                          setValue("type", value);
                          setValue("type_ids", []);
                        }}
                        error={errors?.type?.message ? true : false}
                        searchable
                        clearable
                        height={"20px"}
                      />
                      {errors.type && (
                        <p
                          className="mt-2 text-sm text-red-600"
                          id="type-error"
                        >
                          {t(errors.type?.message)}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <div className="mt-2 mb-2">
                      <label className="mt-2 text-sm" htmlFor="name">
                        {t("labels.product")}
                      </label>
                      <CustomMultiSelect
                        control={control}
                        searchable={true}
                        name="products"
                        value={products}
                        data={productData}
                        onChange={(value) => {
                          setValue("products", value);
                          const newPriceProductList = priceProductList.filter(
                            (item) => value.includes(item.id)
                          );
                          setPriceProductList(newPriceProductList);
                          value.forEach((item) => {
                            if (
                              !priceProductList.find(
                                (product) => product.id === item
                              )
                            ) {
                              setValue(`price_${item}`, undefined);
                              setValue(`price_type_${item}`, undefined);
                            }
                          });
                        }}
                        multiple={true}
                        error={errors.products?.message ? true : false}
                      />

                      {errors.products && (
                        <span className="text-xs text-red-500">
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="products-error"
                          >
                            {t(errors.products?.message)}
                          </p>
                        </span>
                      )}
                    </div>
                  </div>
                  {campaignType && (
                    <div className="sm:col-span-3">
                      <div className="mt-2 mb-2">
                        {!campaignTypeData(campaignType) && <BarLoading />}
                        {campaignTypeData(campaignType) && (
                          <>
                            <label className="mt-2 text-sm" htmlFor="type_ids">
                              {`${
                                campaignTypes.find(
                                  (item) => item.value === campaignType
                                )?.label
                              }`}
                            </label>

                            <CustomMultiSelect
                              control={control}
                              name="type_ids"
                              value={type_ids}
                              data={campaignTypeData(campaignType)}
                              multiple={true}
                              searchable
                              error={errors.type_ids?.message ? true : false}
                            />
                          </>
                        )}

                        {errors.type_ids && (
                          <span className="text-xs text-red-500">
                            <p
                              className="mt-2 text-sm text-red-600"
                              id="typeids-error"
                            >
                              {t(errors.type_ids?.message)}
                            </p>
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {products?.length > 0 && (
                    <div className="sm:col-span-3">
                      <div className="w-full  justify-center items-start flex flex-col">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium  leading-6 dark:text-white text-slate-700"
                        >
                          {t("campaign.price")}
                        </label>

                        {products.map((item) => {
                          const product = productData?.find(
                            (product) => product.value === item
                          );
                          return (
                            <div
                              key={item.value}
                              className="flex mt-2 justify-between items-center w-full gap-2"
                            >
                              <Text
                                size="xs"
                                className="text-slate-700 text-xs w-1/2"
                              >{`${product?.label} :`}</Text>
                              <CustomSelect
                                control={control}
                                placeholder={t("placeholders.select")}
                                name={`price_type_${item}`}
                                data={Object.entries(PriceTypes).map(
                                  ([key, value]) => ({
                                    value: value,
                                    label: t(
                                      `campaign.price-types.${key?.toLowerCase()}`
                                    ),
                                  })
                                )}
                                onChange={(value) => {
                                  setPriceProductList((prev) => [
                                    ...prev.filter(
                                      (item) => item.id !== product?.value
                                    ),
                                    { id: product?.value, price_type: value },
                                  ]);
                                }}
                                error={
                                  errors?.price_type?.message ? true : false
                                }
                                searchable
                                clearable
                                height={"20px"}
                              />

                              <NumberInput
                                name={`price_${item}`}
                                value={getValues(`price_${item}`)}
                                disabled={
                                  !priceProductList.find(
                                    (item) => item?.id === product?.value
                                  )
                                }
                                onChange={(event) => {
                                  if (priceProductList.length > 0) {
                                    if (
                                      priceProductList.find(
                                        (item) => item.id === product.value
                                      )
                                    ) {
                                      const newPriceProductList =
                                        priceProductList.map((item) =>
                                          item.id === product.value
                                            ? {
                                                ...item,
                                                price:
                                                  // calculate price - price * percent / 100
                                                  item.price_type == "PERCENT"
                                                    ? Number(
                                                        product.price -
                                                          (product.price *
                                                            Number(event)) /
                                                            100
                                                      ).toFixed(2)
                                                    : Number(event),
                                              }
                                            : item
                                        );

                                      setPriceProductList(newPriceProductList);
                                      setValue(`price_${item}`, Number(event));
                                    }
                                  }
                                }}
                                placeholder={t("campaign.price-type", {
                                  type: t(
                                    `campaign.price-types.${
                                      priceProductList
                                        .find(
                                          (item) => item.id === product.value
                                        )
                                        ?.price_type?.toLowerCase() || "price"
                                    }`
                                  ),
                                })}
                                hideControls
                                classNames={{
                                  input: ` dark:!bg-gray-800 !bg-gray-300/70   !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                                    errors.price?.message &&
                                    "!ring-red-500 dark:!ring-red-500"
                                  }`,
                                  root: "w-1/2",
                                  wrapper:
                                    "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                                  dropdown:
                                    "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                                  option:
                                    "hover:dark:bg-gray-700 hover:bg-gray-200/90/50",
                                }}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("campaign.end-type")}
                    </label>
                    <div className="mt-4">
                      <Group>
                        {endTypes.map((item) => (
                          <Radio
                            key={item.value}
                            value={item.value}
                            label={item.label}
                            checked={item.value === endType}
                            error={errors.end_type?.message ? true : false}
                            onChange={(event) =>
                              setValue("end_type", event.target.value)
                            }
                          />
                        ))}
                      </Group>

                      <Group>
                        {endType === "DATE" && (
                          <div className="w-full md:w-1/2  mt-2 justify-center items-start flex flex-col">
                            <CustomDatetimePicker
                              value={endDate}
                              locale={"tr"}
                              onChange={(value) => {
                                setValue("end_date", value);
                                setValue("end_count", 0);
                              }}
                              label={t("campaign.end-date")}
                              placeholder={t("placeholders.select")}
                            />
                            {errors.end_date && (
                              <p className="mt-2 ml-1 text-sm text-red-600">
                                {t(errors.end_date?.message)}
                              </p>
                            )}
                          </div>
                        )}
                        {endType === "COUNT" && (
                          <div className="w-full mt-2">
                            <label
                              htmlFor="first-name"
                              className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                            >
                              {t("campaign.end-count")}
                            </label>

                            <div className="w-full md:w-1/2  mt-2 justify-center items-start flex flex-col">
                              <NumberInput
                                value={endCount}
                                onChange={(event) => {
                                  setValue("end_count", Number(event));
                                  setValue(
                                    "end_date",
                                    new Date(endDate) == "Invalid Date"
                                      ? new Date()
                                      : new Date(endDate)
                                  );
                                }}
                                placeholder={t("campaign.end-count")}
                                hideControls
                                classNames={{
                                  input: ` dark:!bg-gray-800 !bg-gray-300/70   !ring-1 !border-none !rounded-md ring-inset !ring-white/5 dark:!text-white !text-slate-700 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6 ${
                                    errors.end_count?.message &&
                                    "!ring-red-500 dark:!ring-red-500"
                                  }}`,
                                  root: "w-full",
                                  wrapper:
                                    "dark:!bg-gray-800 !bg-gray-300/70 rounded-md dark:!text-white !text-slate-700",
                                  dropdown:
                                    "dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 !border-none !rounded-md ring-inset !ring-white/5 focus:!ring-2 focus:!ring-inset focus:!ring-orange-500 !text-base md:!text-sm sm:leading-6",
                                  option:
                                    "hover:dark:bg-gray-700 hover:bg-gray-200/90/50",
                                }}
                              />
                              {errors.end_count?.message && (
                                <p className="mt-2 text-sm text-red-600">
                                  {t(errors.end_count?.message)}
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      </Group>

                      {!errors?.end_date &&
                        !errors.end_count?.message &&
                        errors.end_type && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {t(errors.end_type?.message)}
                          </p>
                        )}
                    </div>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="description"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("campaign.description")}
                    </label>
                    <div className="mt-2">
                      <textarea
                        {...register("description")}
                        type="text"
                        autoComplete="description"
                        placeholder={t("campaign.description")}
                        className={`block w-full rounded-md border-0 dark:bg-gray-800 bg-gray-300/70 py-1.5 dark:text-white text-slate-700 shadow-sm ring-1 ring-inset dark:ring-white/10 ring-gray-400/20 focus:ring-2 focus:ring-inset focus:ring-orange-500 !text-base md:!text-sm sm:leading-6
                        ${
                          errors.description?.message &&
                          "!ring-red-500 dark:!ring-red-500"
                        }
                        `}
                      />
                      <p className="mt-2 text-sm text-red-600" id="email-error">
                        {t(errors.description?.message)}
                      </p>
                    </div>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium leading-6 dark:text-white text-slate-700"
                    >
                      {t("campaign.image")}
                    </label>
                    <Dropzone
                      onDrop={(files) => {
                        setValue("photo", files[0]);
                        setPhotoVal(files[0]);
                      }}
                      onReject={(files) => console.log("rejected files", files)}
                      maxSize={3 * 1024 ** 2}
                      multiple={false}
                      classNames={{
                        root: `dark:!bg-gray-800 !bg-gray-300/70 dark:!text-white !text-slate-700 mt-2 ${
                          errors.photo?.message
                            ? "!ring-1 !ring-red-500 dark:!ring-red-500"
                            : ""
                        }`,
                      }}
                      accept={IMAGE_MIME_TYPE}
                    >
                      <Group
                        justify="center"
                        gap="xl"
                        mih={100}
                        style={{ pointerEvents: "none" }}
                      >
                        <Dropzone.Accept>
                          <IconPhoto
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-green-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Accept>
                        <Dropzone.Reject>
                          <IconX
                            style={{
                              width: rem(52),
                              height: rem(52),
                              color: "var(--mantine-color-red-6)",
                            }}
                            stroke={1.5}
                          />
                        </Dropzone.Reject>
                        <Dropzone.Idle>
                          {photo ? (
                            <div className="w-full h-full flex justify-center items-center">
                              <Image
                                radius="md"
                                h={"auto"}
                                className="max-h-[200px] w-full"
                                w="100%"
                                fit="cover"
                                src={
                                  photo instanceof File
                                    ? URL.createObjectURL(photo)
                                    : `${config.CDN_URL}${photo}`
                                }
                              />
                            </div>
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <IconUpload
                                style={{
                                  width: rem(52),
                                  height: rem(52),
                                  color: "var(--mantine-color-gray-6)",
                                }}
                                stroke={1.5}
                              />
                              <Text
                                className="text-white"
                                style={{ fontSize: rem(12) }}
                              >
                                {t("placeholders.upload")}
                              </Text>
                            </div>
                          )}
                        </Dropzone.Idle>
                      </Group>
                    </Dropzone>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                type="button"
                onClick={handleCancel}
                className="text-sm font-semibold leading-6 dark:text-white text-slate-700"
              >
                {t("actions.cancel")}
              </button>
              <SaveButton
                disabled={id ? updateCampaign.isLoading : addCampaign.isLoading}
              />
            </div>
          </form>
        </div>
      ) : (
        <BarLoading />
      )}
    </>
  );
};

export default CampaignCreate;
